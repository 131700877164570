import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class SearchpackService {
    static async createSearchpack(payload: Partial<any>): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.CREATE_SEARCPACK,

            { ...payload },

            "POST"
        )

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }

    ///////////////////////// get Searchpack List //////////////////////////////

    static async getSearchpacksList(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductTrackpackList>(result)
    }

    static async addKeywordToSearchpack(id: string, payload: any): Promise<Response> {
        const url = `${API_ROUTES.ADD_KEYWORDS_TO_SEARCHPACK}/${id}/searchterms/active`

        const result = await HttpHelper.sendRequest(
            url,

            { ...payload },

            "POST"
        )

        if (result && !UtilHelper.isEmptyObject(result) && !result.error) {
            return await UtilHelper.getJsonResponse(result)
        }
        // @ts-ignore
        return undefined
    }

    static async getSearchpackWithLastDataTime(
        searchpackId: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ): Promise<any> {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}/${searchpackId}/with_last_timestamp${pathString}${queryString}`

        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }

    static async getRanksnapshotData(
        searchpackId: number,
        date?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ): Promise<any> {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACKS_LIST}/${searchpackId}/rank_snapshot/date/${date}${pathString}${queryString}`

        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }

    static async getSearchpacksWithTerm(
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACK_WITHSEARCHTERM}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IProductSearchpackWithTerm>(result)
    }

    static async getSearchpackTermTracker(
        packTermId: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string }
    ) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_SEARCHPACK_RANK_TRACKER}/${packTermId}/rank_tracker/data${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<any>(result)
    }
}
