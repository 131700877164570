import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { FilterIcon } from "../../../assets/svgs/filterIcon.svg"
import { ReactComponent as RatingIcon } from "../../../assets/svgs/rating-icon.svg"
import { img_url } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import FilterDropdown from "../../common/filterDrowpdown.component"
import { ProductCard } from "../../common/productCard.component"
import CardSkeletonLoader from "../../common/skeletonLoader/cardSkeleton.component"
import { PrimaryText } from "../../elements/primaryText.element"
import CardCustomTooltipContent from "../tooltip-alerts/cardTooltipAlerts.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

export interface SearchpackCardProps {
    asin: string
    title: string
    image_filename: string
    price: Price
    rating: number
    ratings_total: number
    is_tracked: boolean
    color?: TAsinProductColor
    is_own?: boolean
}

export interface Price {
    value: number
    currency: string
    raw: string
}

export const SearchpackAsinCard = (props: IAsinContrastCard) => {
    const navigate = useNavigate()
    const sidebarRef = useRef<HTMLDivElement>(null)
    const [showBorder, setShowBorder] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDropdownFilter, setSelectedDropdownFilter] = useState<string>("All")
    const { ranksnapshotData, selectedSearchpackLastDataTime } = useSelector(SearchpackStateSelector)

    const { loading } = useSelector(RunTimeStateSelector)
    const isGetRankSnapshotDataLoading: boolean = useMemo(() => RuntimeHelper.getRanksnapshotDataLoading(), [loading])

    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState<any[]>([])

    useEffect(() => {
        if (isGetRankSnapshotDataLoading) {
            dispatch(
                SearchpackActionCreator.selectedSearchpackProduct({
                    selectedProducts: [],
                })
            )
        }
        if (ranksnapshotData && ranksnapshotData.search_card_data && ranksnapshotData.search_card_data.length > 0) {
            const productsToSelect = ranksnapshotData?.search_card_data?.[0]
            setSelectedProduct([productsToSelect])
            dispatch(
                SearchpackActionCreator.selectedSearchpackProduct({
                    selectedProducts: [productsToSelect],
                })
            )
        }
    }, [ranksnapshotData, isGetRankSnapshotDataLoading])

    const SelectedSearchpackProductFunc = (item: any) => {
        const isSelectedIndex = selectedProduct.findIndex((e) => e?.asin === item?.asin)
        let updatedProducts: IGroupProductData[]

        updatedProducts =
            isSelectedIndex !== -1 ? selectedProduct.filter((e) => e?.asin !== item?.asin) : [...selectedProduct, item]

        setSelectedProduct(updatedProducts)

        dispatch(
            SearchpackActionCreator.selectedSearchpackProduct({
                selectedProducts: [...updatedProducts],
            })
        )
    }

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                setShowBorder(sidebarRef.current.scrollTop > 0)
            }
        }

        const currentSidebarRef = sidebarRef.current
        if (currentSidebarRef) {
            currentSidebarRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentSidebarRef) {
                currentSidebarRef.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    const filteredData = useMemo(() => {
        if (selectedDropdownFilter === "All") return ranksnapshotData?.search_card_data || []
        if (selectedDropdownFilter === "Tracked")
            return ranksnapshotData?.search_card_data?.filter((card: any) => card.is_tracked) || []
        if (selectedDropdownFilter === "Untracked")
            return ranksnapshotData?.search_card_data?.filter((card: any) => !card.is_tracked) || []
        return []
    }, [selectedDropdownFilter, ranksnapshotData?.search_card_data])

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedDropdownFilter(item)
        setIsOpen(false)
    }

    const dropdownOptions = [
        { label: "All", value: "All" },
        { label: "Tracked", value: "Tracked" },
        { label: "Untracked", value: "Untracked" },
    ]

    return (
        <div className="w-full">
            <div
                style={{
                    height: "calc(100vh - 298px)",
                }}
                className="relative bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col pb-0"
            >
                <div
                    className={`w-full flex justify-between gap-[4px] pb-[20px] items-center  py-[6px] pl-[8px] pr-[] mr-[2px]   ${
                        showBorder ? "border-b border-gray-200 " : ""
                    }`}
                >
                    <PrimaryText size="md" weight={"medium"} className={"text-[#088AB2]"}>
                        ASINs
                    </PrimaryText>
                    <div className=" relative" onClick={toggleDropdown}>
                        <div
                            className="cursor-pointer outline-none"
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={-3}
                            data-tooltip-class-name={"!ml-[28px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="Sort ASINs" />
                            )}
                        >
                            <FilterIcon />
                        </div>
                        {isOpen && (
                            <FilterDropdown
                                options={dropdownOptions}
                                selectedItem={selectedDropdownFilter}
                                handleItemClick={handleItemClick}
                                className="!w-[120px]"
                            />
                        )}
                    </div>
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isGetRankSnapshotDataLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full" style={{ height: "calc(100vh - 346px)" }}>
                        {isGetRankSnapshotDataLoading || ranksnapshotData?.search_card_data?.length === 0 ? (
                            <CardSkeletonLoader length={12} isTrackpack={false} />
                        ) : filteredData?.length > 0 ? (
                            filteredData?.map(
                                (trackingStatus: SearchpackCardProps, index: number | null | undefined) => {
                                    const amazonTld = selectedSearchpackLastDataTime?.amazon_tld ?? "com"

                                    return (
                                        <div
                                            key={index}
                                            onClick={() => SelectedSearchpackProductFunc(trackingStatus)}
                                            className="last:pb-[12px]"
                                        >
                                            <ProductCard
                                                amazon_tld={amazonTld}
                                                id={trackingStatus?.asin}
                                                src={
                                                    trackingStatus.image_filename &&
                                                    `${img_url}${trackingStatus.image_filename}`
                                                }
                                                altText={"Asin dress"}
                                                title={trackingStatus.title ?? ""}
                                                price={trackingStatus.price.value ?? 0}
                                                rating={trackingStatus.rating ?? 0}
                                                ratingIcon={<RatingIcon />}
                                                ratingsCount={trackingStatus.ratings_total ?? 0}
                                                color={trackingStatus?.color}
                                                currency={trackingStatus.price.currency ?? "USD"}
                                                selectedProduct={selectedProduct}
                                                // @ts-ignore
                                                // warnings={isWarnings}
                                                ownProduct={trackingStatus.is_own}
                                                isSearchrankCard={true}
                                                searchpackData={selectedSearchpackLastDataTime}
                                                // currentIssue={trackingStatus.currentIssue}
                                            />
                                        </div>
                                    )
                                }
                            )
                        ) : null}
                    </div>

                    <Tooltip
                        id="tracking-off-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-off-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-error-card-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEE4E2",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow red"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-error-card-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-success-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#DCFAE6",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow green"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content)
                            return <CardCustomTooltipContent id="tracking-success-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="status-warning-tooltip"
                        style={{
                            padding: "8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            display: "inline-block",
                            zIndex: 10000,
                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                        }}
                        clickable
                        place="top"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            return <CardCustomTooltipContent id="status-warning-tooltip" dataWarnings={content} />
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
