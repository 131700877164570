import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import SearchPhraseTagInput from "../../../elements/searchPhraseTagInput.element"

const SearchpackStepOneView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    isAddtionalSteps,
    asinHeaderDescription,
    isError,
    isSuccess,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const keywords = watch("keywords")
    const data = useSelector(SearchpackStateSelector)

    const { selectedSearchpackName } = useSelector(SearchpackStateSelector)

    const onSubmit = () => {
        if (isAddtionalSteps) {
            const payload = {
                id: data?.selectedSearchpackValues?.selectedSearchpackID,
                keywords: keywords?.map((val: string) => val) ?? [],
            }

            dispatch(
                SearchpackActionCreator.addKeywordsToSearchpacks(
                    { ...payload },
                    () => {
                        isSuccess && isSuccess(keywords?.length)
                    },
                    (res: { message: string }) => {
                        if (isError) {
                            isError()
                        }
                    }
                )
            )
        } else {
            dispatch(SearchpackActionCreator.selectedSearchpackPayload({ keywords }))
            handleNext && handleNext()
        }
    }

    useEffect(() => {
        if (data?.selectedSearchpackPayload?.keywords) {
            const flatKeywords = data.selectedSearchpackPayload.keywords.flat()
            setValue("keywords", flatKeywords)
        }
    }, [data, setValue])

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[8px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            {asinHeaderDescription ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[4px] mx-[6px]">
                    To add keywords to your Searchpack called&nbsp;
                    <PrimaryText weight="medium" size="sm-medium" className="text-gray-700 inline-block">
                        {selectedSearchpackName},&nbsp;
                    </PrimaryText>
                    simply give me one keyword at a time and hit Tab to enter each one.
                </PrimaryText>
            ) : (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[4px] ml-[6px]">
                    Let’s start building your new Searchpack together. Simply give me
                    <br /> one keyword at a time and hit Tab to enter each one.
                </PrimaryText>
            )}

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <SearchPhraseTagInput
                    id="country-input"
                    name="keywords"
                    className=" border-[#D0D5DD] rounded-[12px]  placeholder:italic placeholder:text-gray-300 tag-input"
                    placeholder="E.g. Men’s Suit Jackets or Volumising Hair Iron"
                    reactHookControl={control}
                />

                <div className="flex justify-center  items-center mt-[48px]">
                    <ButtonElement
                        size="large"
                        textClass="text-[18px] leading-[28px]"
                        className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                        disabled={!keywords}
                    >
                        {buttonText}
                    </ButtonElement>
                </div>
            </form>
        </>
    )
}

export default SearchpackStepOneView
