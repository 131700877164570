export const NewPlusIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-disabled={props.disabled}
            >
                <path
                    d="M8.00016 3.33301V12.6663M3.3335 7.99967H12.6668"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
