import { all } from "redux-saga/effects"

// SAGAS
import accountsSaga from "./account.saga"
import authSagas from "./auth.saga"
import listingAnalyzerSagas from "./listingAnalyzer.saga"
import notificationSagas from "./notifications.saga"
import onBoardingSagas from "./onBoarding.saga"
import productSagas from "./products.saga"
import searchpackSagas from "./searchpack.saga"
import settingSagas from "./settings.saga"

export function* rootSagas() {
    yield all([
        authSagas(),
        productSagas(),
        onBoardingSagas(),
        accountsSaga(),
        settingSagas(),
        notificationSagas(),
        listingAnalyzerSagas(),
        searchpackSagas(),
    ])
}
