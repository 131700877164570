import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import ArchiveRestoreSearchpackStepOne from "./archiveRestoreSearchpackStepOneView.component"

const ArchiveRestoreSearchpackOutlet = () => {
    const { archiveRestoreSearchpackModal } = useSelector(RunTimeStateSelector)

    const getModalBody = () => {
        switch (archiveRestoreSearchpackModal?.stepNumber) {
            case "one":
                return <ArchiveRestoreSearchpackStepOne />
            default:
                return <></>
        }
    }

    return <>{archiveRestoreSearchpackModal?.open ? <>{getModalBody()}</> : null}</>
}

export default ArchiveRestoreSearchpackOutlet
