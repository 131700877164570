import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import ClaimOwnershipStepOneView from "./claimOwnershipStepOneView.component"

const ClaimOwnershipOutlet = () => {
    const { claimOwnershipModal } = useSelector(RunTimeStateSelector)

    const getModalBody = () => {
        switch (claimOwnershipModal?.stepNumber) {
            case "one":
                return <ClaimOwnershipStepOneView />

            default:
                return <></>
        }
    }

    return <>{claimOwnershipModal?.open ? <>{getModalBody()}</> : null}</>
}

export default ClaimOwnershipOutlet
