import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import AddAsinToTrackpackOutlet from "./addAsinToTrackpackOutlet.component"
import { AddAsinToTrackpackWrapperModal } from "./addAsinToTrackpackWrapperModal.component"

const AddAsinToTrackpackMainModal = () => {
    const { addAsinToTrackpackModal } = useSelector(RunTimeStateSelector)

    const isStepOne = addAsinToTrackpackModal?.stepNumber === "one"
    return (
        <AddAsinToTrackpackWrapperModal
            style={{
                maxHeight: "calc(100% - 24px)",
                overflowY: "auto",
            }}
            className={`p-[48px] pb-[36px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px]
            shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all ${
                isStepOne ? "max-w-[960px]" : "max-w-[600px]"
            }   w-full`}
        >
            <AddAsinToTrackpackOutlet />
        </AddAsinToTrackpackWrapperModal>
    )
}

export default AddAsinToTrackpackMainModal
