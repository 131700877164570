import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { AddIcon } from "../../../assets/svgs/addIcon.svg"
import { RightChevronIcon } from "../../../assets/svgs/RightChevronIcon.svg"
import { img_url, rankSnapshotAsinCard } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { NotificationStateSelector } from "../../../selectors/notificationStateSelector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { KeywordPerformanceSubCards } from "../../common/keywordPerformanceSubcards.component"
import CardSkeletonLoader from "../../common/skeletonLoader/cardSkeleton.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import CardCustomTooltipContent from "../tooltip-alerts/cardTooltipAlerts.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

const KeywordPerformnceAsinCard = () => {
    const navigate = useNavigate()
    const sidebarRef = useRef<HTMLDivElement>(null)
    const [showBorder, setShowBorder] = useState(false)
    const [isPanelOpen, setPanelOpen] = useState(false)

    // const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading = useMemo(() => RuntimeHelper.productLoading(), [loading])
    const isTrackPackLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])

    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState<IGroupProductData[]>([])
    const { notifiSelection } = useSelector(NotificationStateSelector)

    const [selectedAsins, setSelectedAsins] = useState(() => {
        const item = localStorage.getItem("notifiAsin")
        return item ? JSON.parse(item) : []
    })

    const SelectedProductFunc = (item: any) => {}

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                setShowBorder(sidebarRef.current.scrollTop > 0)
            }
        }

        const currentSidebarRef = sidebarRef.current
        if (currentSidebarRef) {
            currentSidebarRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentSidebarRef) {
                currentSidebarRef.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])
    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleSidePanelOpen = () => {
        setPanelOpen(!isPanelOpen)
    }

    return (
        <div className="w-full">
            <div className="relative bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col">
                <div className={`w-full flex justify-between gap-[4px] items-center mb-[8px] mt-[4px]`}>
                    <div className=" flex gap-[6px] items-center">
                        <PrimaryText size="md" weight={"medium"} className={"text-[#088AB2] pl-[8px]"}>
                            Active ASIN
                        </PrimaryText>
                    </div>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-20}
                        data-tooltip-class-name={"!ml-[37px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Select ASIN" />
                        )}
                    >
                        <RightChevronIcon
                            onClick={handleSidePanelOpen}
                            className="cursor-pointer"
                            color={"#088AB2"}
                            width={"21"}
                            height={"21"}
                        />
                    </div>
                    {isPanelOpen && (
                        <div
                            className="absolute top-[36px] left-[312px] transition-all w-[316px] mr-[20px]  bg-gray-50 border border-gray-200 z-[11111] rounded-[16px] p-[12px]"
                            style={{
                                boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                height: "calc(100vh - 390px)",
                            }}
                        >
                            <div
                                className="mr-[-1px] h-full overflow-y-scroll"
                                style={
                                    {
                                        // minHeight: "calc(100vh - 400px)",
                                        // width: "calc(100% - 20px)",
                                    }
                                }
                            >
                                <div className="flex flex-col gap-y-[8px] max-h-full">
                                    <CardSkeletonLoader length={10} isTrackpack={false} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isTrackPackLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full">
                        <CardSkeletonLoader length={1} isTrackpack={false} />
                    </div>

                    <Tooltip
                        id="tracking-off-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-off-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-error-card-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEE4E2",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow red"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-error-card-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-success-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#DCFAE6",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow green"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content)
                            return <CardCustomTooltipContent id="tracking-success-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="status-warning-tooltip"
                        style={{
                            padding: "8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            display: "inline-block",
                            zIndex: 10000,
                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                        }}
                        clickable
                        place="top"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            return <CardCustomTooltipContent id="status-warning-tooltip" dataWarnings={content} />
                        }}
                    />
                </div>
            </div>
            <div className="relative mt-[22px] bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col pb-0">
                <div className={`w-full flex justify-between gap-[4px] items-center pb-[12px] `}>
                    <div className=" flex gap-[6px] items-center">
                        <PrimaryText
                            size="md"
                            weight={"medium"}
                            className={"text-[#088AB2] pl-[8px] pt-[7px] pb-[3px]"}
                        >
                            Keywords
                        </PrimaryText>
                    </div>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[38px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Add Searchpack" />
                        )}
                    >
                        <ButtonElement
                            onClick={handleOpen}
                            viewType="only-icon"
                            className="p-[10px] bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                        >
                            <AddIcon strokeColor="#0E7090" />
                        </ButtonElement>
                    </div>
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isTrackPackLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full" style={{ height: "calc(100vh - 524px)" }}>
                        {rankSnapshotAsinCard?.map((trackingStatus: any, index: number) => {
                            const status = trackingStatus.status
                            if (status !== "ACTIVE") return null

                            const amazon_tld = trackingStatus?.metadata?.amazon_tld ?? ""
                            const mainImageUrl = trackingStatus?.latest_data?.main_image_filename
                                ? `${img_url}${trackingStatus.latest_data.main_image_filename}`
                                : ""

                            return (
                                <div
                                    key={index}
                                    onClick={() => SelectedProductFunc(trackingStatus)}
                                    className="last:pb-[12px]"
                                >
                                    <KeywordPerformanceSubCards
                                        amazon_tld={amazon_tld}
                                        id={trackingStatus.asin}
                                        src={mainImageUrl}
                                        altText="Asin dress"
                                        title={trackingStatus.latest_data.title ?? ""}
                                        price={trackingStatus.latest_data.price ?? 0}
                                        rating={trackingStatus.latest_data.rating ?? 0}
                                        ratingsCount={trackingStatus.latest_data.ratings_total ?? 0}
                                        color={trackingStatus.color}
                                        currency={trackingStatus.latest_data.currency ?? "USD"}
                                        selectedProduct={selectedProduct}
                                        ownProduct={trackingStatus.own_product}
                                        metadata={trackingStatus.metadata}
                                        currentIssue={trackingStatus.currentIssue}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default KeywordPerformnceAsinCard
