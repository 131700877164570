import { SearchpackAction } from "../actions/searchpack.action"
import { SearchpackStateClass } from "../classes/searchpackState.class"

export const SearchpackReducer = (
    state: ISearchpackState = new SearchpackStateClass(),
    action: ISagaAction
): Partial<ISearchpackState> => {
    switch (action.type) {
        case SearchpackAction.CREATE_SEARCHPACK:
            return {
                ...state,
                selectedSearchpackPayload: {
                    ...state.selectedSearchpackPayload,
                    ...action.payload,
                },
            }
        case SearchpackAction.ACTIVE_TAB:
            return {
                ...state,
                activeRankingTab: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACKS_LIST:
            return {
                ...state,
                searchpackDropdownList: action.payload,
            }
        case SearchpackAction.ADD_KEYWORDS_TO_SEARCHPACK:
            return {
                ...state,
            }
        case SearchpackAction.GET_SEARCHPACK_WITH_LAST_DATA_TIME:
            return {
                ...state,
            }
        case SearchpackAction.SET_SELECTED_SEARCHPACK_VALUES:
            return {
                ...state,
                selectedSearchpackValues: action.payload,
            }
        case SearchpackAction.SET_SELECTED_SEARCHPACK_NAME:
            return {
                ...state,
                selectedSearchpackName: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACKS_LAST_DATA_TIME:
            return {
                ...state,
                selectedSearchpackLastDataTime: action.payload,
            }
        case SearchpackAction.GET_RANKSNAPSHOT_DATA:
            return {
                ...state,
            }
        case SearchpackAction.SET_RANKSNAPSHOT_DATA:
            return {
                ...state,
                ranksnapshotData: action.payload,
            }
        case SearchpackAction.SELECT_SEARCHPACK_PRODUCT:
            return {
                ...state,
                selectedSearchpackProducts: action.payload,
            }
        case SearchpackAction.STORE_SEARCHPACK_PRODUCTS:
            return {
                ...state,
                selectedSearchpackCardProdcuts: action.payload,
            }
        case SearchpackAction.GET_SEARCHPACK_WITHSEARCHTERM:
            return {
                ...state,
            }
        case SearchpackAction.SET_SEARCHPACK_WITHSEARCHTERM:
            return {
                ...state,
                selectedSearchpackWithTerm: action.payload,
            }
        case SearchpackAction.SET_KEYWORD_PAYLOAD:
            return {
                ...state,
                selectedKeywordPayload: action.payload,
            }
        case SearchpackAction.GET_SEARCHPACK_RANK_TRACKER:
            return {
                ...state,
                searchpackRankTracker: action.payload,
            }
        case SearchpackAction.SET_SEARCHPACK_RANK_TRACKER:
            return {
                ...state,
                searchpackRankTracker: action.payload,
            }

        default:
            return state
    }
}
