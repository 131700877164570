import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { AnimatedAmaizingIcon } from "../../../../assets/svgs/amaizing-bubble.svg"
import { TourCloseIcon } from "../../../../assets/svgs/tourCloseIcon.svg"
import { ROUTES_CONFIG } from "../../../../config/routes.config"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { PrimaryText } from "../../../elements/primaryText.element"
import PerformanceTrackerQuickTourContent from "./performanceTracketQuickTour.component"

export const QuickTourModal = ({ onClose }: any) => {
    const location = useLocation()
    const { pathname } = location

    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { trackpackDropdownList } = useSelector(ProductStateSelector)

    const displayCloseIcon =
        getAccessLevel && getAccessLevel?.user_settings?.initial_qt_completed === true
            ? true
            : trackpackDropdownList && trackpackDropdownList?.trackpacks?.length > 0
            ? true
            : false

    return (
        <>
            {pathname === ROUTES_CONFIG.dashboard && (
                <div className="">
                    <div className="flex justify-between w-full mb-[26px]">
                        <div className="flex items-center space-x-2">
                            <AnimatedAmaizingIcon className={"h-[26px] w-[28px]"} />
                            <PrimaryText size="small" weight="medium" className="text-white">
                                Quick Tour
                            </PrimaryText>
                        </div>
                        {displayCloseIcon && (
                            <button className=" text-gray-400 hover:text-gray-600" onClick={onClose}>
                                <TourCloseIcon />
                            </button>
                        )}
                    </div>
                    <PerformanceTrackerQuickTourContent displayCloseIcon={displayCloseIcon} onClose={onClose} />
                </div>
            )}
        </>
    )
}

export default QuickTourModal
