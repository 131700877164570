export const SearchPackIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                width={props.width ?? "20"}
                height={props.height ?? "20"}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.8334 5.83333L9.90374 3.9741C9.63619 3.439 9.50241 3.17144 9.30283 2.97597C9.12634 2.80311 8.91362 2.67164 8.68008 2.59109C8.41599 2.5 8.11686 2.5 7.5186 2.5H4.33335C3.39993 2.5 2.93322 2.5 2.5767 2.68166C2.2631 2.84144 2.00813 3.09641 1.84834 3.41002C1.66669 3.76654 1.66669 4.23325 1.66669 5.16667V5.83333M1.66669 5.83333H14.3334C15.7335 5.83333 16.4336 5.83333 16.9683 6.10582C17.4387 6.3455 17.8212 6.72795 18.0609 7.19836C18.3334 7.73314 18.3334 8.4332 18.3334 9.83333V13.5C18.3334 14.9001 18.3334 15.6002 18.0609 16.135C17.8212 16.6054 17.4387 16.9878 16.9683 17.2275C16.4336 17.5 15.7335 17.5 14.3334 17.5H5.66669C4.26656 17.5 3.56649 17.5 3.03171 17.2275C2.56131 16.9878 2.17885 16.6054 1.93917 16.135C1.66669 15.6002 1.66669 14.9001 1.66669 13.5V5.83333ZM12.9167 14.5833L11.6667 13.3333M12.5 11.25C12.5 12.8608 11.1942 14.1667 9.58335 14.1667C7.97252 14.1667 6.66669 12.8608 6.66669 11.25C6.66669 9.63917 7.97252 8.33333 9.58335 8.33333C11.1942 8.33333 12.5 9.63917 12.5 11.25Z"
                    stroke={props.color}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
