import { all, fork, put } from "redux-saga/effects"
import { call, takeLatest } from "typed-redux-saga"

import { ProductsAction, ProductsActionCreator } from "../actions/products.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { assignColorsToTrackings, assignColorsToTrackingsUpdated } from "../helpers/util.helper"
import { ProductService } from "../services/product.service"

/////////////////////////// -- Get Guest Account -- ///////////////////////////
function* getProductsByGroup() {
    yield takeLatest(ProductsAction.GET_PRODUCTS_BY_GROUP, getProductsByGroupHandler)
}

function* getProductsByGroupHandler<T extends ISagaAction & { payload: { trackpackId: string; amazaon_tld: string } }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getProductsByGroupHandler", !action.ignorePreloader))
        const { pathParams, queryParams, embedData } = action.payload
        const result = yield* call(
            ProductService.getProductByGroupId,
            action.payload.trackpackId,
            action.payload.amazaon_tld,
            pathParams,
            queryParams,
            embedData
        )
        if (result) {
            yield put(ProductsActionCreator.storeProducts(result))
            yield put(ProductsActionCreator.storeRawData(result.rawData))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getProductsByGroupHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getDropdownList() {
    yield takeLatest(ProductsAction.GET_TRACKPACKS_DROPDOWN_LIST, getDropdownHandler)
}

function* getDropdownHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getDropdownHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(ProductService.getAllDrowndownList, pathParams, queryParams)

        if (result) {
            const sortByCreatedAt = (data: any) => {
                const { trackpacks } = data
                return trackpacks?.sort(
                    // @ts-ignore
                    (a: any, b: any) => new Date(b.metadata.created_at) - new Date(a.metadata.created_at)
                )
            }
            const sortedResult = sortByCreatedAt(result)
            action.onSuccess && action.onSuccess({ trackpacks: sortedResult })
            const updatedTrackpacks = assignColorsToTrackings({ trackpacks: sortedResult } as IProductDropdownList)
            yield put(ProductsActionCreator.setDropdownList({ trackpacks: updatedTrackpacks } as IProductDropdownList))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getDropdownHandler"))
        action.onFinally && action.onFinally()
    }
}

function* createAccount() {
    yield takeLatest(ProductsAction.CREATE_TRACKPACK_ACCOUNT, createAccountSagaHandler)
}

function* createAccountSagaHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.TrackpackaccountCreation, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

function* createAddtionalTrackpackAccount() {
    yield takeLatest(ProductsAction.CREATE_ADDITIONAL_TRACKPACK_ACCOUNT, createAdditionalAccountSagaHandler)
}

function* createAdditionalAccountSagaHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createAdditionalAccountSagaHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.additionalTrackpackaccountCreation, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createAdditionalAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Get Guest Account -- ///////////////////////////

function* updateName() {
    yield takeLatest(ProductsAction.UPDATE_TRACK_NAME, updateSelectedTrackpackName)
}

function* updateSelectedTrackpackName<
    T extends ISagaAction & {
        payload: { id: string; name: string }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        // yield put(RuntimeActionCreators.startLoading("updateGuestAccountSagaHandler", !action.ignorePreloader))

        const { id, name } = action.payload
        const result = yield* call(ProductService.updateTrackpackName, id, { name })

        if (result) {
            // yield put(AuthActionCreator.storeGuest(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        // yield put(RuntimeActionCreators.stopLoading("patchGuestAccountSagaHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Archive trackpack /////////////////////////////

function* archiveTrackpack() {
    yield takeLatest(ProductsAction.ARCHIVE_TRACKPACK, archiveSelectedTrackpackHandler)
}

function* archiveSelectedTrackpackHandler<
    T extends ISagaAction & {
        payload: { id: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("archiveSelectedTrackpackHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.archiveTrakpack, action.payload)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("archiveSelectedTrackpackHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Archive asins /////////////////////////////

function* archiveAsins() {
    yield takeLatest(ProductsAction.ARCHIVE_ASINS, archiveSelectedAsinsHandler)
}

function* archiveSelectedAsinsHandler<
    T extends ISagaAction & {
        payload: { id: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("archiveSelectedAsinsHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.archiveAsins, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("archiveSelectedAsinsHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Restore asins /////////////////////////////

function* restoreAsins() {
    yield takeLatest(ProductsAction.RESTORE_ASINS, restoreSelectedAsinsHandler)
}

function* restoreSelectedAsinsHandler<
    T extends ISagaAction & {
        payload: { id: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("restoreSelectedAsinsHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.restoreAsins, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("restoreSelectedAsinsHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Restore trackpack /////////////////////////////

function* restoreTrackpack() {
    yield takeLatest(ProductsAction.RESTORE_TRACKPACK, restoreSelectedTrackpackHandler)
}

function* restoreSelectedTrackpackHandler<
    T extends ISagaAction & {
        payload: { id: number }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("restoreSelectedTrackpackHandler", !action.ignorePreloader))

        const result = yield* call(ProductService.restoreTackpack, action.payload)

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("restoreSelectedTrackpackHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Get New Data /////////////////////////////

function* newProductDataAvailable() {
    yield takeLatest(ProductsAction.NEW_PRODUCT_DATA_AVAILABLE, checkNewDataAvailableHandler)
}

function* checkNewDataAvailableHandler<T extends ISagaAction & { payload: { trackpackId: string } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("checkNewDataAvailableHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(
            ProductService.checkNewDataAvailable,
            action.payload.trackpackId,
            pathParams,
            queryParams
        )

        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("checkNewDataAvailableHandler"))

        action.onFinally && action.onFinally()
    }
}

//////////////////////////////   Get Competitors Data //////////////////////////

function* getCompetitorsDataList() {
    yield takeLatest(ProductsAction.GET_COMPETITORS_LIST, getCompetitorsDataListHandler)
}

function* getCompetitorsDataListHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getCompetitorsDataListHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(ProductService.getCompetitorsData, pathParams, queryParams)

        if (result?.bestsellers) {
            action.onSuccess && action.onSuccess(result)
            yield put(ProductsActionCreator.setCompetitorsDataList(result))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getCompetitorsDataListHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////////// Get Trackpack List ///////////////////////////

function* getTrackpackList() {
    yield takeLatest(ProductsAction.GET_TRACKPACKS_LIST, getTrackpackListHandler)
}

function* getTrackpackListHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getTrackpackListHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(ProductService.getTrackpackList, pathParams, queryParams)

        if (result) {
            yield put(ProductsActionCreator.setTrackpackList(result as IProductTrackpackList))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getTrackpackListHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////// Get First Trackpack Data ///////////////////////////

function* getFirstTrackpackData() {
    yield takeLatest(ProductsAction.GET_FIRST_TRACKPACK_DATA, getFirstTrackpackDataHandler)
}

function* getFirstTrackpackDataHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getFirstTrackpackDataHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(ProductService.getFirstTrackpackData, pathParams, queryParams)

        if (result) {
            const updatedTrackpacks = assignColorsToTrackingsUpdated(result as TrackpackDatum)
            yield put(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: updatedTrackpacks?.metadata?.id,
                })
            )
            yield put(ProductsActionCreator.setTrackpackData(updatedTrackpacks as TrackpackDatum))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getFirstTrackpackDataHandler"))
        action.onFinally && action.onFinally()
    }
}

///////////////////////////////// GET EXTENDED TRACKING DATA /////////////////////////////////

function* getExtendedTrackingData() {
    yield takeLatest(ProductsAction.GET_EXTENDED_TRACKING_DATA, getExtendedTrackingDataHandler)
}

function* getExtendedTrackingDataHandler<T extends ISagaAction & { payload: { trackpackId: string } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getExtendedTrackingDataHandler", !action.ignorePreloader))
        const { trackpackId, pathParams, queryParams } = action.payload
        const result = yield* call(ProductService.getExtendedTrackingData, trackpackId, pathParams, queryParams)

        if (result) {
            const updatedTrackpacks = assignColorsToTrackingsUpdated(result as TrackpackDatum)
            yield put(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: updatedTrackpacks?.metadata?.id,
                })
            )
            action.onSuccess && action.onSuccess(updatedTrackpacks)
            yield put(ProductsActionCreator.setTrackpackData(updatedTrackpacks as TrackpackDatum))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getExtendedTrackingDataHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////////  Archive asins /////////////////////////////

function* claimOwnership() {
    yield takeLatest(ProductsAction.CLAIM_OWNERSHIP_OF_ASIN, claimOwnershipOfAsinHandler)
}

function* claimOwnershipOfAsinHandler<
    T extends ISagaAction & {
        payload: { trackpack_id: number; asin?: string; payload: any }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("claimOwnershipOfAsinHandler", !action.ignorePreloader))
        const { trackpack_id, asin, payload } = action.payload

        const result = yield* call(ProductService.claimownership, trackpack_id, asin, payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("claimOwnershipOfAsinHandler"))

        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([
        fork(getProductsByGroup),
        fork(createAccount),
        fork(createAddtionalTrackpackAccount),
        fork(getDropdownList),
        fork(updateName),
        fork(archiveAsins),
        fork(archiveTrackpack),
        fork(restoreAsins),
        fork(restoreTrackpack),
        fork(newProductDataAvailable),
        fork(getCompetitorsDataList),
        fork(getTrackpackList),
        fork(getFirstTrackpackData),
        fork(getExtendedTrackingData),
        fork(claimOwnership),
    ])
}
