export const FilterIcon = (props: IArrowProps) => {
    return (
        <svg
            className="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 12H16M6 8H18M10 16H14"
                stroke={props.color ?? "#667085"}
                stroke-width="0.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
