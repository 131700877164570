import Skeleton from "react-loading-skeleton"
import { PrimaryText } from "../../elements/primaryText.element"

const ReportSummarySkeleton = () => {
    return (
        <>
            <div className="mt-[20px] overflow-y-auto -mr-[30px] w-full">
                <div className="mt-[28px] bg-gray-100 border rounded-[12px] p-6">
                    <div
                        className="p-[8px] inline-flex items-center justify-center"
                        style={{
                            boxShadow: "0px 1px 2px 0px #1018280D",
                        }}
                    >
                        <Skeleton baseColor="#EAECF0" count={1} width={48} height={48} className="rounded-[8px]" />
                    </div>
                    <PrimaryText weight="medium" className="text-gray-700 text-[24px] my-[26px] ml-[6px]">
                        <Skeleton baseColor="#EAECF0" count={1} width={300} height={24} className="rounded-[8px]" />
                    </PrimaryText>

                    {/* Overview */}
                    <div className=" p-[24px] rounded-[8px] bg-white min-h-[128px] mb-[24px]"></div>
                    <div className=" p-[24px] rounded-[8px] bg-white ">
                        <div className="grid grid-cols-3 gap-[12px]">
                            <div className="cursor-pointer">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={385}
                                    height={72}
                                    className="rounded-[4px]"
                                />
                            </div>

                            {/* Display strengths and weaknesses */}
                            <div className="cursor-pointer">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={385}
                                    height={72}
                                    className="rounded-[4px]"
                                />
                            </div>
                            <div className="cursor-pointer">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={385}
                                    height={72}
                                    className="rounded-[4px]"
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-[12px] mt-[12px]">
                            <div className="cursor-pointer ">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={585}
                                    height={72}
                                    className="rounded-[4px]"
                                />
                            </div>

                            {/* Display strengths and weaknesses */}
                            <div className="cursor-pointer ">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={585}
                                    height={72}
                                    className="rounded-[4px]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" p-[24px] rounded-[8px] bg-white my-[24px] min-h-[148px]"></div>
                    <div className=" p-[24px] my-[24px] rounded-[8px] bg-white">
                        <div className="grid grid-cols-2 gap-3">
                            <div className="cursor-pointer">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={584}
                                    height={222}
                                    className="rounded-[4px]"
                                />
                            </div>

                            {/* Display strengths and weaknesses */}
                            <div className="cursor-pointer">
                                <Skeleton
                                    baseColor="#EAECF0"
                                    count={1}
                                    width={584}
                                    height={222}
                                    className="rounded-[4px]"
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className=" p-[24px] rounded-[8px] bg-white min-h-[128px]"></div> */}
                </div>
            </div>
        </>
    )
}

export default ReportSummarySkeleton
