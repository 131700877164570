import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import icon from "../../../assets/gifs/new-blinking-logo.gif"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PrimaryText } from "../../elements/primaryText.element"
const SearchpackTrackerOnboarding = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <>
            <div className="border-2 border-[#088AB2] rounded-[12px] mb-[12px] bg-[#ECFDFF] p-[24px] flex flex-col items-center justify-center">
                <div className="w-[60px] h-[54px] mb-[12px]">
                    <img src={icon} alt="blinking logo" className="w-full h-full" />
                </div>
                <PrimaryText weight="medium" size="small" className="leading-[20px] text-gray-600 mb-[4px]">
                    I’m busy fetching data for your new Searchpack. I’ll alert you as soon as it’s ready.
                </PrimaryText>
                <PrimaryText className="text-gray-600" weight="light" size="xs">
                    <span onClick={handleOpen} className="text-[#088AB2] font-[500] cursor-pointer">
                        Add New
                    </span>{" "}
                    or{" "}
                    <span onClick={() => navigate(ROUTES_CONFIG.searchpackhub)} className="text-[#088AB2] font-[500] cursor-pointer">
                        Manage
                    </span>{" "}
                    Searchpacks now?
                </PrimaryText>
            </div>
        </>
    )
}

export default SearchpackTrackerOnboarding
