import { useMemo } from "react"
import { Root } from "react-dom/client"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { OwnerIcon } from "../../../../assets/svgs/ownerIcon.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import useGetExtendedTrackpacks from "../../../../hooks/reactQueryHooks.hook"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"

const ClaimOwnershipStepOneView: React.FC = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList } = useSelector(AccountStateSelector)
    const { claimOwnershipModal, loading } = useSelector(RunTimeStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const isClaimOwnershipLoading: boolean = useMemo(() => RuntimeHelper.claimOwnershipLoading(), [loading])

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeClaimOwnerShipModal())
    }

    const { refetch } = useGetExtendedTrackpacks({}, {})

    const handleSubmit = () => {
        if (claimOwnershipModal) {
            const payload = {
                own: claimOwnershipModal.isRevokeOwnership ? false : true,
            }
            dispatch(
                ProductsActionCreator.claimOwnershipOfAsin(
                    {
                        trackpack_id: claimOwnershipModal && claimOwnershipModal.id,
                        asin: claimOwnershipModal?.asin,
                        payload,
                    },
                    (data: Root) => {
                        refetch()
                        handleCloseDialog()
                    }
                )
            )
        }
    }

    const updateDropdownList = (updatedData: Root) => {
        const updatedDropdownList = selectedDropdownList?.trackpacks?.map((item: TrackpackDatum) =>
            item.metadata.id === selectedTrackpacksList.id ? updatedData : item
        )
        dispatch(ProductsActionCreator.setDropdownList(updatedDropdownList))
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[8px]">
                    <OwnerIcon />
                </div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            {!claimOwnershipModal?.isRevokeOwnership ? (
                <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[4px] ml-[6px]">
                    Your ASIN
                </PrimaryText>
            ) : (
                <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[4px] ml-[6px]">
                    Not your ASIN
                </PrimaryText>
            )}

            {!claimOwnershipModal?.isRevokeOwnership ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline ml-[6px]">
                    Does this ASIN belong to you? Click ‘Confirm’ to claim it now.
                </PrimaryText>
            ) : (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline ml-[6px]">
                    This ASIN does not belong to you? Click ‘Revoke’ to unclaim it now.
                </PrimaryText>
            )}

            <div className=" mt-[48px]">
                <>
                    <div className="flex gap-[12px] items-center justify-center mt-[48px]">
                        <div className="flex justify-center items-center ">
                            <ButtonElement
                                onClick={handleCloseDialog}
                                viewType="hover-state"
                                className={
                                    "w-[117px] bg-[#ECFDFF]  rounded-[12px] h-[44px] text-[#06AED4] text-[18px] font-[300] border border-[#67E3F9]"
                                }
                                disabled={isClaimOwnershipLoading}
                            >
                                Cancel
                            </ButtonElement>
                        </div>
                        <div className="flex justify-center items-center">
                            <ButtonElement
                                onClick={handleSubmit}
                                size="large"
                                className={
                                    "w-[126px] border  border-transparent bg-[#A5F0FC] rounded-[12px] h-[44px] text-[#0E7090] text-[14px] flex items-center justify-center "
                                }
                                disabled={isClaimOwnershipLoading}
                                loading={isClaimOwnershipLoading}
                                viewType={isClaimOwnershipLoading ? "loading-button" : "primary"}
                            >
                                {claimOwnershipModal?.isRevokeOwnership ? "Revoke" : "Confirm"}
                            </ButtonElement>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default ClaimOwnershipStepOneView
