export const ClaimOwnerShipIcon = (props: IArrowProps) => {
    return (
        <svg
            width={props.width ?? "16"}
            height={props.height ?? "16"}
            className={` ${props.className}`}
            onClick={props.onClick}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.91663 9.88954C5.9252 9.88954 4.15424 10.8533 3.02675 12.3489C2.78408 12.6707 2.66275 12.8317 2.66672 13.0492C2.66979 13.2173 2.77507 13.4293 2.907 13.533C3.07775 13.6673 3.31438 13.6673 3.78764 13.6673H12.0456C12.5189 13.6673 12.7555 13.6673 12.9263 13.533C13.0582 13.4293 13.1635 13.2173 13.1665 13.0492C13.1705 12.8317 13.0492 12.6707 12.8065 12.3489C11.679 10.8533 9.90805 9.88954 7.91663 9.88954Z"
                className="stroke-[#D0D5DD] group-hover:stroke-[#98A2B3] transition-colors"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.91663 8.00065C9.47787 8.00065 10.7435 6.73212 10.7435 5.16732C10.7435 3.60251 9.47787 2.33398 7.91663 2.33398C6.35538 2.33398 5.08975 3.60251 5.08975 5.16732C5.08975 6.73212 6.35538 8.00065 7.91663 8.00065Z"
                className="stroke-[#D0D5DD] group-hover:stroke-[#98A2B3] transition-colors"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
