import Skeleton from "react-loading-skeleton"

const ListingAnalyzerSkeletonComponent = () => {
    return (
        <>
            <div className="mt-[12px]">
                <div
                    className={`overflow-y-scroll -mr-[30px] transition-padding duration-300`}
                    style={{
                        height: "calc(-235px + 100vh)",
                    }}
                >
                    <div
                        className="border border-gray-200 rounded-lg"
                        style={{
                            width: "calc(100% - 24px)",
                        }}
                    >
                        <table className="w-full bg-white overflow-hidden rounded-[7px]">
                            <thead className="bg-gray-100 border-b border-[#eee] h-[44px]">
                                <tr className="">
                                    <th>
                                        <td className="w-[258px] pl-[16px] text-left">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={152}
                                                height={12}
                                            />
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[322px] text-left">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={152}
                                                height={12}
                                            />
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[108px] text-left">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={54}
                                                height={12}
                                            />
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[142px] text-left">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={54}
                                                height={12}
                                            />
                                        </td>
                                    </th>
                                    <th className="text-left">
                                        <td className="w-[350px] ">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={96}
                                                height={12}
                                            />
                                        </td>
                                    </th>
                                    <th className="flex items-center justify-center py-[12px]">
                                        <td className="mr-[27px]">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={54}
                                                height={12}
                                            />
                                        </td>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: 10 })?.map((report: any, index: number) => (
                                    <tr key={index} className="border-b last:border-b-0 border-[#EEEEEE]  group">
                                        <td className="p-4">
                                            <div>
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={128}
                                                    height={12}
                                                    className="rounded-[6px]"
                                                />
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={184}
                                                    height={12}
                                                    className="rounded-[6px]"
                                                />
                                            </div>
                                        </td>

                                        <td className="p-4 pl-0">
                                            <div className="flex items-center gap-[12px]">
                                                <div className="p-[6px] inline-flex justify-center items-center rounded-[7px] gap-[2px]">
                                                    <Skeleton
                                                        className="rounded-[8px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={40}
                                                        height={40}
                                                    />
                                                </div>
                                                <div className="max-w-[144px] w-full">
                                                    <Skeleton
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={128}
                                                        height={12}
                                                        className="rounded-[6px]"
                                                    />

                                                    <Skeleton
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={184}
                                                        height={12}
                                                        className="rounded-[6px]"
                                                    />
                                                </div>
                                            </div>
                                        </td>

                                        {/* Main Image */}
                                        <td className="p-4 pl-0">
                                            <Skeleton
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={40}
                                                height={40}
                                                className="rounded-[8px]"
                                            />
                                        </td>

                                        <td className="p-4 pl-0">
                                            <div className="flex items-center gap-[4px]">
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={40}
                                                    height={40}
                                                    className="rounded-[8px]"
                                                />
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={40}
                                                    height={40}
                                                    className="rounded-[8px]"
                                                />
                                            </div>
                                        </td>

                                        <td className="p-4 pl-0">
                                            <div className="flex gap-x-[10px] items-center">
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={24}
                                                    height={24}
                                                    className="rounded-full"
                                                />
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={96}
                                                    height={12}
                                                    className="mt-[12px] rounded-[6px]"
                                                />
                                                <Skeleton
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={54}
                                                    height={12}
                                                    className="mt-[12px] rounded-[6px]"
                                                />
                                            </div>
                                        </td>

                                        {/* Date */}
                                        <td className="p-4 pl-0 pr-[42px]">
                                            <Skeleton
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={54}
                                                height={12}
                                                className="rounded-[6px]"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListingAnalyzerSkeletonComponent
