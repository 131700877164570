import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { SearchpackActionCreator } from "../../../../actions/searchpack.action"
import { MarketIcon } from "../../../../assets/svgs/marketIcon.svg"
import { SearchPackName } from "../../../../assets/svgs/searchPackName.svg"
import { SearchTrackerIcon } from "../../../../assets/svgs/searchTracker.svg"
import { ThumbIcon } from "../../../../assets/svgs/thumbIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import SearchpackStepFourView from "./addSearchpackStepFourView.component"
import AddSearchpackStepInputView from "./addSearchpackStepInputView.component"
import SearchpackStepOneView from "./addSearchpackStepOneView.component"
import SearchpackStepThreeView from "./addSearchpackStepThreeView.component"
import SearchpackStepTwoView from "./addSearchpackStepTwoView.component"

const SearchpackFlowOutlet = () => {
    const { addSearchpackModal } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const [hasError, setHasError] = useState(false)
    const [isSearchpackSuccess, setIsSearchpackSuccess] = useState(false)
    const [searchpackName, setSearchpackName] = useState("")

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeAddSearchpackModal())
        dispatch(
            SearchpackActionCreator.selectedSearchpackPayload({
                name: "",
                amazon_tld: "",
                keywords: [],
            })
        )
        setHasError(false)
        setIsSearchpackSuccess(false)
    }

    const handleErrorModal = () => {
        setHasError(true)
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "five",
            })
        )
    }

    const handleSuccessModal = (name: string) => {
        setIsSearchpackSuccess(true)
        setSearchpackName(name)
        dispatch(
            RuntimeActionCreators.openAddSearchpackModal({
                open: true,
                stepNumber: "four",
            })
        )
    }

    const getModalBody = () => {
        if (isSearchpackSuccess && addSearchpackModal?.stepNumber === "four") {
            return (
                <SearchpackStepThreeView
                    icon={<ThumbIcon />}
                    handleCloseDialog={handleCloseDialog}
                    asinHeaderText="Wahoo"
                    buttonText="Done"
                    searchpackName={searchpackName}
                />
            )
        }
        if (hasError && addSearchpackModal?.stepNumber === "five") {
            return (
                <SearchpackStepFourView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openAddSearchpackModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Error adding new Searchpack"
                    asinHeaderDescription="I couldn’t complete the setup of your new Searchpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        switch (addSearchpackModal?.stepNumber) {
            case "one":
                return (
                    <AddSearchpackStepInputView
                        icon={<MarketIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Identify Market"
                        asinHeaderDescription="Please tell me which Amazon market this Searchpack is for."
                        buttonText="Next"
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openAddSearchpackModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                    />
                )
            case "two":
                return (
                    <SearchpackStepOneView
                        icon={<SearchTrackerIcon width={32} height={32} color={"#088AB2"} />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="New Searchpack"
                        buttonText="Next"
                        // isModalOpen={addSearchpackModal?.stepNumber === "one"}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openAddSearchpackModal({
                                    open: true,
                                    stepNumber: "three",
                                })
                            )
                        }
                    />
                )
            case "three":
                return (
                    <SearchpackStepTwoView
                        icon={<SearchPackName />}
                        handleCloseDialog={handleCloseDialog}
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openAddSearchpackModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                        asinHeaderText="Searchpack Name"
                        asinHeaderDescription="Let’s give your new Searchpack a name."
                        buttonText="Next"
                        isError={handleErrorModal}
                        isAddtionalSteps={false}
                        isSuccess={(name) => handleSuccessModal(name)}
                    />
                )

            default:
                return <></>
        }
    }
    return <>{addSearchpackModal?.open ? <>{getModalBody()}</> : null}</>
}

export default SearchpackFlowOutlet
