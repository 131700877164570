import { useEffect, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ListingAnalyzerActionCreator } from "../../../actions/listingAnalyzer.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { EditIcon } from "../../../assets/svgs/editIcon.svg"
import { EyeSvg } from "../../../assets/svgs/eye.svg"
import MainImageIcon from "../../../assets/svgs/filters/MainImageFilterIcon.svg"
import { ImageUnavailableThumbnail } from "../../../assets/svgs/imageUnavailableThumbnail.svg"
import { InCompletedSummaryIcon } from "../../../assets/svgs/inCompleteSummaryIcon.svg"
import { SearchIcon } from "../../../assets/svgs/searchIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { useInputValidations } from "../../../hooks/inputValidations.hook"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { ListingAnalyzerStateSelector } from "../../../selectors/listingAnalyzerState.selector"
import { PrimaryText } from "../../elements/primaryText.element"
import useDebouncedInput from "../../elements/useDebouncedInput.element"
import { UserAccountsInput } from "../../elements/userAccountsInput.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

const ListingAnalyzerComponent = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const validationConfig = useInputValidations()
    const { control, watch } = useForm()

    const searchInput = watch("search")
    const { debouncedValue: debouncedSearchInput, handleChange } = useDebouncedInput(searchInput, 500)

    const { reportsListData } = useSelector(ListingAnalyzerStateSelector)
    const { refetch } = useGetExtendedTrackpacks({}, {}, false)
    const [filteredData, setFilteredData] = useState(reportsListData?.reports)

    useEffect(() => {
        handleChange(searchInput)
    }, [searchInput])

    useEffect(() => {
        if (debouncedSearchInput) {
            const searchTerms = debouncedSearchInput.toLowerCase().split(" ")
            setFilteredData(
                reportsListData?.reports.filter((data: any) => {
                    const fullString = `${data.trackpack?.name} `.toLowerCase()
                    return searchTerms.some((term) => fullString.includes(term))
                })
            )
        } else {
            setFilteredData(reportsListData?.reports)
        }
    }, [debouncedSearchInput, reportsListData])

    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 5
            setIsScrolledToBottom(isAtBottom)
        }
    }

    useEffect(() => {
        const container = containerRef.current
        if (container) {
            container.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    const handleViewReport = (report_id: number) => {
        navigate(ROUTES_CONFIG.reportSummary, { state: { reportId: report_id } })
    }

    const handleEditReportClick = (report_id: number) => {
        dispatch(ListingAnalyzerActionCreator.setReportData({ isEditMode: true }))
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "loading",
            })
        )
        dispatch(
            ListingAnalyzerActionCreator.getReportDataByID(report_id, {}, {}, () => {
                refetch()
            })
        )
    }

    return (
        <>
            <div>
                <div className="ml-auto mt-[44px] flex justify-end mb-[10px]">
                    <UserAccountsInput
                        name="search"
                        type="text"
                        defaultValue=""
                        reactHookControl={control}
                        reactHookValidations={{
                            pattern: validationConfig.alphanumericUppercaseOnlyTenChars,
                            required: validationConfig.required,
                        }}
                        placeholder="Search"
                        className=" w-[320px] "
                        prefix={<SearchIcon />}
                    />
                </div>
                <div
                    ref={containerRef}
                    className={`overflow-y-scroll -mr-[30px] transition-padding duration-300 ${
                        isScrolledToBottom ? "pb-[12px]" : ""
                    }`}
                    style={{
                        height: "calc(-306px + 100vh)",
                    }}
                >
                    <div
                        className="border border-gray-200 rounded-lg"
                        style={{
                            width: "calc(100% - 24px)",
                        }}
                    >
                        <table className="w-full bg-white overflow-hidden rounded-[7px]">
                            <thead className="bg-gray-200 border-b border-[#eee] h-[44px]">
                                <tr className="">
                                    <th>
                                        <td className="w-[258px] pl-[16px]">
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 text-left">
                                                Trackpack
                                            </PrimaryText>
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[322px]">
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 text-left">
                                                Reports
                                            </PrimaryText>
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[108px]">
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 text-left">
                                                Target
                                            </PrimaryText>
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[142px] ">
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 text-left">
                                                Competitors
                                            </PrimaryText>
                                        </td>
                                    </th>
                                    <th className="">
                                        <td className="w-[350px] ">
                                            <PrimaryText size="xs" weight="medium" className="text-gray-600 text-left">
                                                Files
                                            </PrimaryText>
                                        </td>
                                    </th>
                                    <th className="flex items-center justify-center py-[12px]">
                                        <td className="w-[68px]">
                                            <PrimaryText
                                                size="xs"
                                                weight="medium"
                                                className="text-gray-600 text-center"
                                            >
                                                Created
                                            </PrimaryText>
                                        </td>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData?.map((report: any, index: number) => (
                                    <tr
                                        key={index}
                                        className="border-b last:border-b-0 border-[#EEEEEE] hover:bg-gray-100 group"
                                    >
                                        <td className="p-4">
                                            <div>
                                                <PrimaryText
                                                    className="text-gray-600 mb-[6px]"
                                                    weight="medium"
                                                    size="xs"
                                                >
                                                    {report.trackpack.name}
                                                </PrimaryText>
                                                <PrimaryText className="flex items-center gap-[4px]">
                                                    <span className="text-[8.5px] leading-[9px] font-medium text-[#088AB2]">
                                                        ASINs:
                                                    </span>
                                                    <span className="text-[8.5px] leading-[9px] font-[300] text-gray-700">
                                                        {report.trackpack.asin_counts.own} User
                                                    </span>
                                                    <span className="text-[8.5px] leading-[9px] font-medium text-[#088AB2]">
                                                        •
                                                    </span>
                                                    <span className="text-[8.5px] leading-[9px] font-[300] text-gray-700">
                                                        {report.trackpack.asin_counts.competitor} Competitor
                                                    </span>
                                                    <span className="text-[8.5px] leading-[9px] font-medium text-[#088AB2]">
                                                        •
                                                    </span>
                                                    <span className="text-[8.5px] leading-[9px] font-[300] text-gray-700">
                                                        {report.trackpack.asin_counts.archived} Archived
                                                    </span>
                                                </PrimaryText>
                                            </div>
                                        </td>

                                        <td className="p-4 pl-0">
                                            <div className="flex items-center gap-[12px]">
                                                <div
                                                    className="p-[6px] inline-flex justify-center items-center rounded-[7px] gap-[2px]"
                                                    style={{ backgroundColor: "#67E3F9" }}
                                                >
                                                    <MainImageIcon width="28" height="28" strokeColor={"#088AB2"} />
                                                </div>
                                                <div className="max-w-[144px] w-full">
                                                    <PrimaryText
                                                        className="text-gray-600 my-[2px]"
                                                        weight="medium"
                                                        size="xs"
                                                    >
                                                        {report.report_type === "MAIN_IMAGE_ANALYSIS"
                                                            ? "MAIN IMAGE ANALYSIS"
                                                            : ""}
                                                    </PrimaryText>

                                                    {/* Show Progress Bar Only if Completion is not 100 */}
                                                    {report.completion_percentage !== 100 && (
                                                        <div className="w-full max-w-lg mx-auto">
                                                            <div className="relative flex items-center gap-[8px]">
                                                                <div className="w-[47px] h-[4px] bg-gray-200 rounded-full my-[2px] mt-[3px]">
                                                                    <div
                                                                        className="h-[4px] bg-[#06AED4] rounded-full relative"
                                                                        style={{
                                                                            width: `${
                                                                                report.completion_percentage || 0
                                                                            }%`,
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                                <PrimaryText
                                                                    weight="book"
                                                                    size="xs-small"
                                                                    className="text-gray-500 leading-[9px]"
                                                                >
                                                                    {report.completion_percentage || 0}% Completed
                                                                </PrimaryText>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="group-hover:opacity-100 transition-opacity duration-300 opacity-0 flex items-center gap-[8px] group:opacity-1">
                                                    <div
                                                        data-tooltip-id="dashboard-tooltip"
                                                        data-tooltip-place="bottom-end"
                                                        data-tooltip-class-name={"!ml-[48px]"}
                                                        data-tooltip-offset={-7}
                                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                            <CommonTooltip
                                                                className="w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                                label="View Report"
                                                            />
                                                        )}
                                                        className={`p-[4px] rounded-full inline-flex items-center justify-center bg-white ${
                                                            report.completion_percentage === 100
                                                                ? "cursor-pointer"
                                                                : "cursor-not-allowed"
                                                        }`}
                                                        onClick={
                                                            report.completion_percentage === 100
                                                                ? () => handleViewReport(report?.report_id)
                                                                : undefined
                                                        }
                                                    >
                                                        <EyeSvg
                                                            width="12"
                                                            height="12"
                                                            strokeColor={
                                                                report.completion_percentage === 100
                                                                    ? "#98A2B3"
                                                                    : "#EAECF0"
                                                            }
                                                        />
                                                    </div>
                                                    {/* Edit Icon */}
                                                    <div
                                                        data-tooltip-id="dashboard-tooltip"
                                                        data-tooltip-place="bottom-end"
                                                        data-tooltip-class-name={"!ml-[48px]"}
                                                        data-tooltip-offset={-8}
                                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                            <CommonTooltip
                                                                className="w-auto  mb-[10px] h-3.5 shadow flex-col justify-start items-start inline-flex"
                                                                label="Edit Report"
                                                            />
                                                        )}
                                                        onClick={() => handleEditReportClick(report?.report_id)}
                                                        className="cursor-pointer p-[4px] bg-white rounded-full inline-flex items-center justify-center"
                                                    >
                                                        <EditIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        {/* Main Image */}
                                        <td className="p-4 pl-0">
                                            <div className="w-[52px] h-[52px] bg-white inline-flex items-center justify-center border border-gray-400 rounded-[4px] overflow-hidden">
                                                {report.products.find((product: any) => product.is_target)
                                                    ?.main_image_filename ? (
                                                    <img
                                                        src={`${img_url}${
                                                            report.products.find((product: any) => product.is_target)
                                                                .main_image_filename
                                                        }`}
                                                        alt="Main Image"
                                                        className="object-cover w-full h-full"
                                                    />
                                                ) : (
                                                    <ImageUnavailableThumbnail />
                                                )}
                                            </div>
                                        </td>

                                        {/* Additional Images */}
                                        <td className="p-4 pl-0">
                                            <div className="flex gap-[4px] items-center">
                                                {report.products
                                                    .filter((product: any) => !product.is_target)
                                                    .map((product: any, imgIndex: any) => (
                                                        <div
                                                            key={imgIndex}
                                                            className="w-[40px] h-[40px] bg-white inline-flex items-center justify-center border border-gray-400 rounded-[4px] overflow-hidden"
                                                        >
                                                            {product.main_image_filename ? (
                                                                <img
                                                                    src={`${img_url}${product.main_image_filename}`}
                                                                    alt={`Additional Image ${imgIndex + 1}`}
                                                                    className="object-contain w-full h-full"
                                                                />
                                                            ) : (
                                                                <ImageUnavailableThumbnail />
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                        </td>

                                        <td className="p-4 pl-0">
                                            <div className="flex items-center gap-[16px]">
                                                <div className="flex gap-[4px] items-center">
                                                    {/* {report.completion_percentage === 100 ? ( */}
                                                    {/* <CompletedSummaryIcon /> */}
                                                    {/* ) : ( */}
                                                    <InCompletedSummaryIcon />
                                                    {/* // )} */}
                                                    <PrimaryText
                                                        className={` ${
                                                            report.completion_percentage === 100
                                                                ? "text-gray-500"
                                                                : "text-gray-300"
                                                        } inline-flex items-center gap-[4px] my-[3px]`}
                                                        size="xs"
                                                        weight="medium"
                                                    >
                                                        {report.report_type === "MAIN_IMAGE_ANALYSIS" ? (
                                                            <>
                                                                <PrimaryText
                                                                    size="xs"
                                                                    weight="medium"
                                                                    className={`"min-w-[96px] text-gray-300`}
                                                                >
                                                                    Production Brief
                                                                </PrimaryText>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <PrimaryText className="min-w-[96px] text-gray-300">
                                                                    Copy Brief
                                                                </PrimaryText>
                                                            </>
                                                        )}
                                                        <span className="text-[8px] font-[400] leading-[18px] pt-[3px] text-gray-300">
                                                            {report.completion_percentage === 100 ? (
                                                                <>
                                                                    {
                                                                        new Date(report.created_at)
                                                                            .toLocaleDateString("en-US", {
                                                                                month: "short",
                                                                                day: "2-digit",
                                                                                year: "numeric",
                                                                            })
                                                                            .replace(/,/g, "") // Remove the comma
                                                                            .replace(/ /g, "-") // Replace spaces with dashes
                                                                            .toUpperCase() // Convert to uppercase
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>UNAVAILABLE</>
                                                            )}
                                                        </span>
                                                    </PrimaryText>
                                                </div>
                                                {/* <div className="group-hover:opacity-100 transition-opacity duration-300 opacity-0 flex items-center gap-[8px] group:opacity-1">
                                                    <div className="cursor-pointer p-[4px] bg-white rounded-full inline-flex items-center justify-center ">
                                                        <AddIcon width={"12"} height={"12"} strokeColor={"#98A2B3"} />
                                                    </div>
                                                    <div className="cursor-pointer p-[4px] bg-white rounded-full inline-flex items-center justify-center ">
                                                        <EyeSvg width={"12"} height={"12"} strokeColor={"#98A2B3"} />
                                                    </div>
                                                    <div className="cursor-pointer p-[4px] bg-white rounded-full inline-flex items-center justify-center ">
                                                        <EditIcon />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </td>

                                        {/* Date */}
                                        <td className="p-4 pl-0 pr-[16px]">
                                            <PrimaryText
                                                size="xs"
                                                weight="light"
                                                className="text-gray-600 uppercase text-center"
                                            >
                                                {
                                                    new Date(report.created_at)
                                                        .toLocaleDateString("en-US", {
                                                            month: "short",
                                                            day: "2-digit",
                                                            year: "numeric",
                                                        })
                                                        .replace(/,/g, "") // Remove the comma
                                                        .replace(/ /g, "-") // Replace spaces with dashes
                                                        .toUpperCase() // Convert to uppercase
                                                }
                                            </PrimaryText>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListingAnalyzerComponent
