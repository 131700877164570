import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { PresentInTrackPackIcon } from "../../../../assets/svgs/presentInTrackPackIcon.svg"
import { ThumbIcon } from "../../../../assets/svgs/thumbIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import useGetExtendedTrackpacks from "../../../../hooks/reactQueryHooks.hook"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import AddAsinToTrackpackModalStepOneView from "./addAsinToTrackpackStepOneView.component"
import AddAsinToTrackpackStepThreeView from "./addAsinToTrackpackStepThreeView.component"
import AddAsinToTrackpackStepTwoView from "./addAsinToTrackpackStepTwoView.component"

const AddAsinToTrackpackOutlet = () => {
    const { addAsinToTrackpackModal } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const [hasError, setHasError] = useState(false)
    const [isAddAsinSuccess, setIsAddAsinSuccess] = useState(false)
    const [trackpackName, setTrackpackName] = useState("")
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { data, isLoading, refetch } = useGetExtendedTrackpacks({}, {}, false)

    const [filteredTrackpacks, setFilteredTrackpacks] = useState([])

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (data?.trackpacks && getAccessLevel?.id) {
            const result = data.trackpacks.filter((trackpack: any) => trackpack.metadata.user === getAccessLevel.id)
            setFilteredTrackpacks(result)
        }
    }, [data?.trackpacks, getAccessLevel?.id])

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeAddAsinToTrackpackModal())
        dispatch(ProductsActionCreator.removeAddAsintoTrackpackData())
        setHasError(false)
        setIsAddAsinSuccess(false)
    }

    const handleErrorModal = () => {
        setHasError(true)
        dispatch(
            RuntimeActionCreators.openAddAsinToTrackpackModal({
                open: true,
                stepNumber: "three",
            })
        )
    }

    const handleSuccessModal = (name: string) => {
        setIsAddAsinSuccess(true)
        setTrackpackName(name)
        dispatch(
            RuntimeActionCreators.openAddAsinToTrackpackModal({
                open: true,
                stepNumber: "two",
            })
        )
    }

    const handlePrevStep = () => {
        dispatch(
            RuntimeActionCreators.openAddAsinToTrackpackFlowModal({
                open: true,
            })
        )
        handleCloseDialog()
    }

    const getModalBody = () => {
        if (isAddAsinSuccess && addAsinToTrackpackModal?.stepNumber === "two") {
            return (
                <AddAsinToTrackpackStepTwoView
                    icon={<ThumbIcon />}
                    handleCloseDialog={handleCloseDialog}
                    asinHeaderText="Bravo"
                    buttonText="Done"
                    trackpackName={trackpackName}
                />
            )
        }
        if (hasError && addAsinToTrackpackModal?.stepNumber === "three") {
            return (
                <AddAsinToTrackpackStepThreeView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openAddAsinToTrackpackModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Error adding new Searchpack"
                    asinHeaderDescription="I couldn’t complete the setup of your new Searchpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        switch (addAsinToTrackpackModal?.stepNumber) {
            case "one":
                return (
                    <AddAsinToTrackpackModalStepOneView
                        icon={<PresentInTrackPackIcon width={28} height={28} color={"#088AB2"} />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Select Trackpack"
                        asinHeaderDescription="Choose which Trackpack you want to add the ASIN to."
                        buttonText="Next"
                        extendedTrackpacks={filteredTrackpacks}
                        isLoading={isLoading}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openAddAsinToTrackpackModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                        handlePrev={handlePrevStep}
                        isError={handleErrorModal}
                        isSuccess={(name) => handleSuccessModal(name)}
                    />
                )

            default:
                return <></>
        }
    }
    return <>{addAsinToTrackpackModal?.open ? <>{getModalBody()}</> : null}</>
}

export default AddAsinToTrackpackOutlet
