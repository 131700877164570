import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { AlertDotIconSvg } from "../../../assets/svgs/alertDotIcon.svg"
import { CrossNewIcon } from "../../../assets/svgs/xIcon.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const ErrorAlertComponent = (props: any) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
            handleCloseDialog()
        }, props.duration)

        return () => clearTimeout(timer)
    }, [dispatch, props.duration])

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeNotificaitonAlert())
    }

    return (
        <div
            className={`py-[4px] pl-[4px] pr-[8px] m-auto max-w-[496px] rounded-[12px] border z-10 absolute left-[50%] top-[38px] translate-x-[-50%] border-[#F04438] bg-[#FEF3F2] `}
            style={{ boxShadow: "0px 4px 8px 4px #1018281A", zIndex: 9999999999999 }}
        >
            <div className="flex items-center gap-[12px]">
                <div
                    className={`flex items-center gap-[4px] rounded-[8px] border py-[2px] px-[8px] pl-[4px] border-[#F04438] bg-white `}
                >
                    <AlertDotIconSvg fillColor="#F04438" color="#FECDCA" />

                    <PrimaryText weight="light" size="small" className={`text-[#F04438] pt-[2px] whitespace-nowrap`}>
                        Error
                    </PrimaryText>
                </div>
                <div>
                    <PrimaryText weight="light" size="small" className={`text-[#F04438] pt-[2px] whitespace-nowrap`}>
                        There was a problem with that action
                    </PrimaryText>
                </div>
                <CrossNewIcon
                    className="cursor-pointer"
                    color="#F04438"
                    width="16px"
                    height="16px"
                    onClick={handleCloseDialog}
                />

                {/* <div className="flex items-center gap-[4px] py-[2px]">
                    <PrimaryText
                        weight="light"
                        size="small"
                        className={`text-[#067647] whitespace-nowrap cursor-pointer`}
                        onClick={handleActionClick}
                    >
                        View now
                    </PrimaryText>
                    <TrackpackArrowIcon />
                </div> */}
            </div>
        </div>
    )
}

export default ErrorAlertComponent
