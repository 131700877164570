import { DashboardBoardConfig } from "../../../../config/dashboard.config"
import { PrimaryText } from "../../../elements/primaryText.element"

export const Header = () => {
    const { graphConfig } = DashboardBoardConfig

    return (
        <div
            className={"bg-[#EAECF0] w-full sticky top-0 z-10 rounded-t-[10px] border-b border-[#EEE] border-t"}
            style={{ width: "calc(100% - 30px)" }}
        >
            <div
                className={
                    "w-full justify-start items-center flex py-[7px]                                                                                                                                                                                                                                                                   "
                }
            >
                <div className="w-[140px]  flex-col justify-center items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"medium"} className="self-stretch text-gray-700 pl-[24px]">
                        ASINs
                    </PrimaryText>
                </div>
                {/* <div className={"w-[72px]"}></div> */}
                <div className="w-[72px] flex-col justify-center items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"medium"} className="text-slate-700">
                        Changes
                    </PrimaryText>
                </div>
                <div className="w-[376px] grow justify-self-start items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"medium"} className="text-slate-700">
                        Listing Data
                    </PrimaryText>
                </div>
                {graphConfig.productResults.map((product, index) => (
                    <div key={index}>
                        <div className="w-[72px] h-10 flex-col justify-center items-center inline-flex">
                            <PrimaryText size={"xs"} weight={"medium"} className="text-center text-slate-700">
                                <span>{product.label.split(" ")[0]}</span>
                                <p>
                                    {product.label?.split(" ")?.[1]} {product.label?.split(" ")?.[2]}
                                </p>
                            </PrimaryText>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
