import { useEffect, useMemo, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { img_url } from "../../../../config/dashboard.config"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { reportModalTrackpacks } from "../../../../helpers/util.helper"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import { AMAZON_DOMAINS } from "../../../marketing/domains.config"

const AddAsinToTrackpackModalStepOneView: React.FC<ReportModalFlowProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handleNext,
    handlePrev,
    asinHeaderDescription,
    previousStep,
    isLoading,
    extendedTrackpacks,
    buttonText,
    isError,
    isSuccess,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const [hasScroll, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)
    const [showTopBorder, setShowTopBorder] = useState(false)
    const [showBottomBorder, setShowBottomBorder] = useState(false)
    const [localData, setLocalData] = useState()
    const { loading } = useSelector(RunTimeStateSelector)
    const isAddAsinLoading: boolean = useMemo(() => RuntimeHelper.addAdditionalAsinLoading(), [loading])

    const { addAsinToTrackpack } = useSelector(ProductStateSelector)

    useEffect(() => {
        const divElement = scrollableDivRef.current
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
        const checkScrollPosition = () => {
            if (divElement) {
                const isAtTop = divElement.scrollTop === 0
                const isAtBottom = divElement.scrollTop + divElement.clientHeight === divElement.scrollHeight

                if (isAtTop || isAtBottom) {
                    setShowTopBorder(false)
                    setShowBottomBorder(false)
                } else {
                    setShowTopBorder(true)
                    setShowBottomBorder(true)
                }
            }
        }

        // Initial check in case the content is already scrolled on mount
        checkScrollPosition()

        window.addEventListener("resize", checkScrollPosition)
        divElement?.addEventListener("scroll", checkScrollPosition)

        return () => {
            window.removeEventListener("resize", checkScrollPosition)
            divElement?.removeEventListener("scroll", checkScrollPosition)
        }
    }, [extendedTrackpacks])

    const onSubmit = () => {
        let competitor: string[] = []
        let own_asins: string[] = []
        const payload = {
            own_asins: own_asins ?? [],
            // @ts-ignore
            trackpack_id: localData?.selectedProduct?.id,
            competitor_asins: addAsinToTrackpack?.asin ? [addAsinToTrackpack.asin.toUpperCase()] : [],
        }

        dispatch(
            ProductsActionCreator.addAsinToTrackpack(
                { ...payload },
                () => {
                    // @ts-ignore
                    isSuccess && isSuccess(localData?.selectedProduct?.title)
                },
                (res: { message: string }) => {
                    if (isError) {
                        isError()
                    }
                }
            )
        )
    }

    const analyzeReportTrackpacks = useMemo(() => {
        if (!extendedTrackpacks) return []

        return reportModalTrackpacks(extendedTrackpacks)
    }, [extendedTrackpacks])

    const handleCardSelect = (product: any) => {
        setLocalData((prev: any) => ({ ...prev, selectedProduct: product }))
    }

    return (
        <>
            <div className="flex justify-between">
                <div className=" rounded-[12px] bg-[#A5F0FC] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-gray-700 text-[24px] font-medium leading-[32px] mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>

            <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[8px] ml-[6px]">
                {asinHeaderDescription}
            </PrimaryText>

            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    {isLoading ? (
                        <div className="flex justify-center">
                            <div className="w-16 h-16 rounded-full animate-spin border  border-[#0E7090] border-t-transparent"></div>
                        </div>
                    ) : (
                        <>
                            <div className={`${showTopBorder ? "border-t border-gray-200" : ""}  mr-[17px]`} />
                            <div
                                ref={scrollableDivRef}
                                className={`grid grid-cols-3 gap-[12px] overflow-y-auto semi-automated`}
                                style={{
                                    paddingRight: hasScroll ? "12px" : "0",
                                    maxHeight: "305px",
                                }}
                            >
                                {analyzeReportTrackpacks &&
                                    analyzeReportTrackpacks?.map((product: any, index: number) => {
                                        const selected_country = AMAZON_DOMAINS?.find((val) => {
                                            return val?.value === product.amazonDomain
                                        })
                                        // @ts-ignore
                                        const isSelected = localData?.selectedProduct?.id === product.id

                                        return (
                                            <div
                                                key={index}
                                                onClick={() => handleCardSelect(product)}
                                                style={{
                                                    outline: isSelected ? `2px solid #088AB2` : "1px solid #EAECF0",
                                                    outlineOffset: isSelected ? `-2px` : `-1px`,
                                                    backgroundColor: isSelected ? ` #ECFDFF` : "#F2F4F7",
                                                }}
                                                className={`border border-gray-200 p-[10px] bg-gray-100 rounded-[10px] cursor-pointer -outline-offset-1
                                                `}
                                            >
                                                <div className="justify-between flex w-full">
                                                    <div>
                                                        <PrimaryText
                                                            size="xs"
                                                            weight="medium"
                                                            className="text-gray-600 leading-[18px] line-clamp-1 max-w-[28ch]"
                                                        >
                                                            {product.title}
                                                        </PrimaryText>
                                                        <div className="flex gap-[5px]">
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="medium"
                                                                className={` !text-[9px] text-[#06AED4]`}
                                                            >
                                                                ASINs:
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="light"
                                                                className="text-gray-700 !text-[9px] "
                                                            >
                                                                {product.userASINs} Users
                                                            </PrimaryText>
                                                            <PrimaryText className={` text-[9px] text-[#06AED4]`}>
                                                                •
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="light"
                                                                className="text-slate-700 !text-[9px] "
                                                            >
                                                                {product.competitorASINs} Competitors
                                                            </PrimaryText>
                                                            <PrimaryText className={` text-[9px] text-[#06AED4] `}>
                                                                •
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                size="xs-small"
                                                                weight="light"
                                                                className="text-slate-700 !text-[9px]"
                                                            >
                                                                {product.archived} Archived
                                                            </PrimaryText>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`w-auto h-[16px] pl-[6px] pr-[3px]  bg-white rounded-[16px] outline outline-1 outline-[#A5F0FC] inline-flex items-center gap-[4px]`}
                                                    >
                                                        <PrimaryText
                                                            weight="book"
                                                            size="xs-medium"
                                                            className={` leading-[13px] ml-[2px] text-[#088AB2]`}
                                                        >
                                                            {product.actionCount}
                                                        </PrimaryText>
                                                        <div className="tp-icon">{selected_country?.icon}</div>
                                                    </div>
                                                </div>
                                                <div className="mt-[10px] flex items-start justify-between w-full">
                                                    <div className=" gap-[4px] items-center inline-flex">
                                                        {product?.imageSources
                                                            ?.flat()
                                                            .slice(0, 5)
                                                            ?.map((el: any, index: number) => (
                                                                <div
                                                                    key={index}
                                                                    className="w-[30px] h-[30px] rounded-[4px] border border-gray-300 overflow-hidden bg-white flex items-center justify-center"
                                                                >
                                                                    {el ? (
                                                                        <img
                                                                            alt={el}
                                                                            className="w-full h-full object-contain"
                                                                            src={`${img_url}${el}`}
                                                                        />
                                                                    ) : (
                                                                        <Skeleton
                                                                            baseColor="#D0D5DD"
                                                                            count={1}
                                                                            width={28}
                                                                            height={28}
                                                                            style={{
                                                                                top: "-3px",
                                                                                lineHeight: "initial",
                                                                                objectFit: "contain",
                                                                                borderRadius: "initial",
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            ))}

                                                        {product?.imageSources?.length > 5 && (
                                                            <PrimaryText
                                                                weight="light"
                                                                size="xs"
                                                                className="w-[30px] h-[30px] text-[#344054]  rounded-full border border-dashed border-gray-300 flex justify-center items-center"
                                                            >
                                                                +{product.imageSources.length - 5}
                                                            </PrimaryText>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                            <div className={`${showBottomBorder ? "border-t border-gray-200" : ""}  mr-[17px]`} />
                        </>
                    )}

                    <div className={`flex justify-between items-center mt-[48px]`}>
                        <div className="flex items-center">
                            <BackChevronIcon />
                            <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                                Back
                            </PrimaryText>
                        </div>

                        <ButtonElement
                            type="submit"
                            size="large"
                            textClass="text-[18px] leading-[28px]"
                            className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                            loading={isAddAsinLoading}
                            disabled={isAddAsinLoading}
                            viewType={isAddAsinLoading ? "loading-button" : "primary"}
                        >
                            {buttonText}
                        </ButtonElement>
                    </div>
                </>
            </form>
        </>
    )
}

export default AddAsinToTrackpackModalStepOneView
