export const RankTrackerIcon = (props: IPropsIcon) => {
    return (
        <>
            <svg
                width={props.width ?? 32}
                height={props.height ?? 32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16 29.3334C23.3638 29.3334 29.3333 23.3639 29.3333 16.0001C29.3333 8.63628 23.3638 2.66675 16 2.66675C8.63616 2.66675 2.66663 8.63628 2.66663 16.0001C2.66663 23.3639 8.63616 29.3334 16 29.3334Z"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.6294 11.0214C20.2808 10.8042 20.6065 10.6957 20.8231 10.7729C21.0116 10.8401 21.1599 10.9884 21.2271 11.1769C21.3044 11.3935 21.1958 11.7192 20.9787 12.3706L18.9953 18.3208C18.9334 18.5063 18.9025 18.5991 18.8498 18.6761C18.8032 18.7443 18.7442 18.8033 18.676 18.8499C18.5989 18.9026 18.5062 18.9336 18.3207 18.9954L12.3705 20.9788C11.7191 21.1959 11.3934 21.3045 11.1768 21.2273C10.9883 21.16 10.84 21.0117 10.7728 20.8232C10.6955 20.6067 10.8041 20.281 11.0212 19.6296L13.0046 13.6794C13.0665 13.4939 13.0974 13.4011 13.1501 13.3241C13.1968 13.2558 13.2557 13.1969 13.3239 13.1502C13.401 13.0975 13.4937 13.0666 13.6793 13.0048L19.6294 11.0214Z"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
