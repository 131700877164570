import Skeleton from "react-loading-skeleton"

const ReportTableSkeleton = () => {
    return (
        <>
            {/* <div className="flex items-center justify-between mt-[46px] mb-[12px]">
                <div className="flex items-center gap-[12px] mt-[12px]">
                    <div className="bg-[#67E3F9] p-[6px] inline-flex items-center justify-center rounded-[8px]">
                        <MainImageIcon width={"28"} height={"28"} strokeColor={"#088AB2"} />
                    </div>
                    <PrimaryText weight="medium" size="lg" className="text-[#088AB2]">
                        Main Image Analysis Report
                    </PrimaryText>
                </div>
                <div className="flex items-center gap-[8px] mt-[16px]">
                    <div
                        // onClick={handleEditReportRedirect}
                        className="bg-white border cursor-pointer border-gray-200 p-[8px] rounded-[10px] inline-flex items-center justify-center"
                    >
                        <EditIcons />
                    </div>

                    <div className="bg-white hidden border cursor-pointer border-gray-200 p-[8px] rounded-[10px] inline-flex items-center justify-center">
                        <DownloadIcon />
                    </div>
                    <div className="bg-[#ECFDFF] hidden cursor-pointer border border-[#67E3F9] rounded-[10px] inline-flex items-center gap-[4px] justify-center px-[12px] py-[8px]">
                        <SummaryIcon />
                        <PrimaryText weight="medium" size="small" className="text-[#06AED4]">
                            Generate Brief
                        </PrimaryText>
                    </div>
                </div>
            </div> */}
            <>
                {/* <div className={"border-t-[1px] border-t-gray-200 mr-[1px]"} /> */}
                <div className="mr-[-26px] scrollable-container relative">
                    <>
                        <div
                            style={{
                                width: "calc(100% - 22px)",
                            }}
                        >
                            <div>
                                {/* Table Section */}
                                <div className="border border-gray-200 rounded-[10px]">
                                    {/* Images Row */}
                                    <div className="grid grid-cols-4 items-end justify-end bg-gray-100 py-[16px] pl-[16px] pr-[20px] rounded-tl-[11px] rounded-tr-[11px]">
                                        <div className="col-span-1 flex items-end text-gray-600">
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={200}
                                                height={12}
                                            />
                                        </div>
                                        <div className="col-span-3 flex justify-end">
                                            <div className="flex flex-row">
                                                <Skeleton
                                                    className="rounded-[10px] mx-[38px] mt-[2px]"
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={144}
                                                    height={144}
                                                />
                                                <Skeleton
                                                    className="rounded-[10px] mx-[38px] mt-[2px]"
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={144}
                                                    height={144}
                                                />
                                                <Skeleton
                                                    className="rounded-[10px] mx-[38px] mt-[2px]"
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={144}
                                                    height={144}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Metrics Table */}
                                    <div className="border-t">
                                        <div
                                            className={`grid grid-cols-4 gap-4 items-center bg-white py-[12px] pl-[32px] pr-[20px] border-b border-b-[#EEE]`}
                                        >
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={152}
                                                height={12}
                                            />
                                            <div className="col-span-3 flex items-center justify-end">
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`grid grid-cols-4 gap-4 items-center bg-white py-[12px] pl-[32px] pr-[20px] border-b border-b-[#EEE]`}
                                        >
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={152}
                                                height={12}
                                            />
                                            <div className="col-span-3 flex items-center justify-end">
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`grid grid-cols-4 gap-4 items-center bg-white py-[12px] pl-[32px] pr-[20px] border-b border-b-[#EEE]`}
                                        >
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={152}
                                                height={12}
                                            />
                                            <div className="col-span-3 flex items-center justify-end">
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`grid grid-cols-4 gap-4 items-center bg-white py-[12px] pl-[32px] pr-[20px] border-b border-b-[#EEE]`}
                                        >
                                            <Skeleton
                                                className="rounded-[6px]"
                                                baseColor="#EAECF0"
                                                count={1}
                                                width={152}
                                                height={12}
                                            />
                                            <div className="col-span-3 flex items-center justify-end">
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                                <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                    <Skeleton
                                                        className="rounded-[6px]"
                                                        baseColor="#EAECF0"
                                                        count={1}
                                                        width={32}
                                                        height={12}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Totals Row */}
                                    <div className="grid grid-cols-4 gap-4 items-center py-[12px] pl-[16px] pr-[20px] bg-gray-100 rounded-bl-[11px] rounded-br-[11px]">
                                        <Skeleton
                                            className="rounded-[6px]"
                                            baseColor="#EAECF0"
                                            count={1}
                                            width={72}
                                            height={12}
                                        />
                                        <div className="col-span-3 flex justify-end">
                                            <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                <Skeleton
                                                    className="rounded-[6px]"
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={32}
                                                    height={12}
                                                />
                                            </div>
                                            <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                <Skeleton
                                                    className="rounded-[6px]"
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={32}
                                                    height={12}
                                                />
                                            </div>
                                            <div className="flex items-center justify-center  max-w-[220px] w-full">
                                                <Skeleton
                                                    className="rounded-[6px]"
                                                    baseColor="#EAECF0"
                                                    count={1}
                                                    width={32}
                                                    height={12}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </>
        </>
    )
}

export default ReportTableSkeleton
