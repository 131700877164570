export const LeftChevron = (props: IArrowProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? "10"}
            height={props.height ?? "10"}
            viewBox="0 0 10 10"
            fill="none"
        >
            <path
                d="M6.25 7.5L3.75 5L6.25 2.5"
                stroke={`${props.color}`}
                stroke-width="0.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
