import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { SearchPackHubIcon } from "../../../assets/svgs/searchpackHub.svg"
import { TrackpackTrashIcon } from "../../../assets/svgs/trashIcon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { calculateDays, getASINCount } from "../../../helpers/util.helper"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import SearchpackHubCardSkeletonLoader from "../../common/skeletonLoader/searchpackHubCardSkeletonLoader.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { AMAZON_DOMAINS } from "../../marketing/domains.config"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

interface TrackpackProps {
    setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>
}

export interface Root {
    ASINs: Asins
    Status: string[]
    Owner: string
    "Date Added": string
    time_deletion: number
    amazon_tld: string
    currentIssue: any
}

export interface Asins {
    asinNo: string
    asinText: string
    imgUrl: string
}

export const SearchpackHubOptions = ({ setSelectedRows }: TrackpackProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userActionData, selectedTrackpacksList } = useSelector(AccountStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [isScrollable, setIsScrollable] = useState(false)
    const containerRef = useRef(null)
    const [trackpackType, setTrackpackType] = useState<string>("tracking")
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<string>("Newest")

    const pathID = localStorage.getItem("trackpackHubId")
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")

    const pathParams = {}

    const queryParams = userActionData ? { as_user: userActionData.id.toString() } : {}

    const { data, isLoading, refetch } = useGetExtendedTrackpacks(pathParams, queryParams)

    useEffect(() => {
        if (id) {
            localStorage.setItem("trackpackHubId", id)
            navigate(ROUTES_CONFIG.trackpackCentral)
        }
    }, [])

    const convertedTrackpacks = useMemo(() => {
        let trackpacks = []

        if (Array.isArray(data)) {
            trackpacks = data
        } else if (data?.trackpacks) {
            trackpacks = data.trackpacks
        } else {
            return []
        }

        return trackpacks.map((trackpack: any) => {
            const id = trackpack.metadata.id
            const title = trackpack.metadata.name
            const status = trackpack.metadata.status
            const imageSources = trackpack.trackings
                .filter((tracking: any) => tracking.status !== "ARCHIVED")
                .map((tracking: any) => tracking?.latest_data?.main_image_filename)
            const amazonDomain = trackpack.metadata.amazon_tld

            let userASINs = 0
            let competitorASINs = 0
            let archived = 0
            if (trackpackType === "archived") {
                userASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL" && tracking.own_product === true
                ).length

                competitorASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL" && tracking.own_product === false
                ).length

                archived = trackpack.trackings.filter((tracking: any) => tracking.status === "ARCHIVED").length
            } else {
                userASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        (tracking.status === "ACTIVE" || tracking.status === "AWAITING_INITIAL_DATA") &&
                        tracking.own_product === true
                ).length

                competitorASINs = trackpack.trackings.filter(
                    (tracking: any) =>
                        (tracking.status === "ACTIVE" || tracking.status === "AWAITING_INITIAL_DATA") &&
                        tracking.own_product === false
                ).length
                archived = trackpack.trackings.filter(
                    (tracking: any) =>
                        tracking.status === "ARCHIVED" || tracking.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                ).length
            }

            const trackpackChildrenList = trackpack.trackings.map((tracking: any) => {
                const ASINs = {
                    asinNo: tracking.asin,
                    asinText: tracking?.latest_data?.title,
                    imgUrl: tracking?.latest_data?.main_image_filename,
                }

                const Status = [tracking.status]
                const currentIssue = tracking.current_issue

                let Owner = ""

                if (tracking.own_product) {
                    const firstName = trackpack?.metadata?.user_first_name
                    const initials = `${firstName.charAt(0)}`
                    Owner = initials
                }

                const DateAdded = tracking.datetime_from.substring(0, 10)
                const timeDeletion = calculateDays(tracking.datetime_from)
                const amazon_tld = trackpack?.metadata?.amazon_tld

                return {
                    ASINs,
                    Status,
                    Owner,
                    "Date Added": DateAdded,
                    time_deletion: timeDeletion,
                    amazon_tld,
                    currentIssue,
                }
            })

            const actionCount = getASINCount(trackpackChildrenList?.length)

            return {
                title,
                userASINs,
                competitorASINs,
                actionCount,
                trackpackChildrenList,
                id,
                status,
                archived,
                imageSources,
                amazonDomain,
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, trackpackType])

    const trackpackTypeListView =
        trackpackType === "tracking"
            ? convertedTrackpacks &&
              convertedTrackpacks?.filter((val: { status: string | string[] }) => {
                  return val?.status?.includes("ACTIVE") || val?.status?.includes("AWAITING_INITIAL_DATA")
              })
            : convertedTrackpacks &&
              convertedTrackpacks?.filter((val: { status: string | string[] }) => {
                  return val?.status?.includes("ARCHIVED") || val?.status?.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
              })

    useEffect(() => {
        if (trackpackTypeListView?.length > 0) {
            let indexToSelect = 0

            if (pathID) {
                const foundIndex = trackpackTypeListView.findIndex((trackpack: any) => trackpack.id == pathID)
                indexToSelect = foundIndex !== -1 ? foundIndex : 0
            } else {
                indexToSelect =
                    selectedIndex !== undefined && selectedIndex < trackpackTypeListView.length ? selectedIndex : 0
            }

            dispatch(AccountActionsCreater.setSelectedTrackpackList(trackpackTypeListView[indexToSelect]))
            setSelectedIndex(indexToSelect)
        }
        if (pathID && convertedTrackpacks && convertedTrackpacks.length > 0) {
            const matchingTrackpack = convertedTrackpacks.find((val: { id: string }) => val.id == pathID)
            if (matchingTrackpack) {
                const trackpackStatus = matchingTrackpack.status

                if (trackpackStatus === "ACTIVE" || trackpackStatus === "AWAITING_INITIAL_DATA") {
                    setTrackpackType("tracking")
                } else if (trackpackStatus === "ARCHIVED" || trackpackStatus === "ARCHIVED_DURING_PACK_ARCHIVAL") {
                    setTrackpackType("archived")
                }
            }
            if (!isLoading) {
                setTimeout(() => {
                    localStorage.removeItem("trackpackHubId")
                }, 6000)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, convertedTrackpacks, trackpackType, pathID])

    useEffect(() => {
        const checkScrollability = () => {
            if (containerRef.current) {
                const { scrollHeight, clientHeight } = containerRef.current
                setIsScrollable(scrollHeight > clientHeight)
            }
        }

        checkScrollability()
        const currentContainer = containerRef.current
        const resizeObserver = new ResizeObserver(checkScrollability)
        if (currentContainer) {
            resizeObserver.observe(currentContainer)
        }

        return () => {
            if (currentContainer) {
                resizeObserver.unobserve(currentContainer)
            }
        }
    }, [])

    const handleSelectedOptions = (selectedOptions: any) => {
        dispatch(AccountActionsCreater.setSelectedTrackpackList(selectedOptions))
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedItem(item)
        setIsOpen(false)
    }

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openTrackPackFlowModal({
                open: true,
            })
        )
    }

    const handleOpenArchiveSearchpack = () => {
        dispatch(
            RuntimeActionCreators.openArchiveRestoreSearchpackModal({
                open: true,
                stepNumber: "one",
                isSearchpack: true,
            })
        )
    }

    const hasOnlyActiveOrAwaiting = (trackpacks: any[]) => {
        return trackpacks.every((val) => val.status.includes("ACTIVE") || val.status.includes("AWAITING_INITIAL_DATA"))
    }

    const isArchiveDisabled = useMemo(() => {
        return hasOnlyActiveOrAwaiting(convertedTrackpacks)
    }, [convertedTrackpacks])

    const hasOnlyArchivedOrAwaiting = (trackpacks: any[]) => {
        return trackpacks.every(
            (val) => val.status.includes("ARCHIVED") || val.status.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
        )
    }

    const isTrackingDisabled = useMemo(() => {
        return hasOnlyArchivedOrAwaiting(convertedTrackpacks)
    }, [convertedTrackpacks])

    useEffect(() => {
        if (isArchiveDisabled) {
            setTrackpackType("tracking")
        } else if (isTrackingDisabled) {
            setTrackpackType("archived")
        }
    }, [isArchiveDisabled, isTrackingDisabled])

    const trackpackRefs = useRef<(HTMLDivElement | null)[]>([])

    useEffect(() => {
        if (pathID && data && trackpackRefs.current[selectedIndex]) {
            trackpackRefs.current[selectedIndex]?.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }, [selectedIndex, pathID, data])

    const handleRestoreTrackpack = (product: any) => {
        dispatch(
            ProductsActionCreator.restoreTrackpack(
                {
                    trackpack_id: product.id,
                },
                (data: Root) => {
                    refetch()
                    dispatch(
                        RuntimeActionCreators.openNotificaitonAlert({
                            open: true,
                            notificationType: "restore",
                            trackpackName: product?.title,
                            duration: 30000,
                            id: product.id,
                            isAsinRestore: false,
                        })
                    )
                }
            )
        )
    }

    return (
        <>
            <div
                className="w-[304px]  p-[12px] pb-0 rounded-[16px] bg-gray-50 h-calc-163"
                style={{
                    border: "1px solid",
                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                }}
            >
                <div>
                    <div className="flex justify-between ">
                        <div className="flex gap-[4px] items-center ml-[8px]">
                            <PrimaryText weight="medium" size="sm-medium" className="text-[#088AB2] leading-normal">
                                Searchpacks
                            </PrimaryText>
                            {/* <InfoLabel /> */}
                        </div>
                        <div
                            className="cursor-pointer outline-none"
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={-10}
                            data-tooltip-class-name={"!ml-[38px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="Add Trackpack" />
                            )}
                        >
                            <ButtonElement
                                onClick={handleOpen}
                                viewType="only-icon"
                                className="p-[10px] cursor-pointer bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                            >
                                <AddIcon />
                            </ButtonElement>
                        </div>
                    </div>
                    <div className="w-full mt-[20px] mb-[12px] relative">
                        <div className="flex ml-[8px] border border-[#A5F0FC] rounded-[4px] w-[94px] h-[16px]">
                            <div
                                className={`overflow-hidden rounded-tl-[3px] rounded-bl-[3px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] border-r border-[#A5F0FC] h-full ${
                                    trackpackType === "tracking"
                                        ? "bg-[#CFF9FE]"
                                        : isTrackingDisabled
                                        ? "bg-gray-100"
                                        : "bg-white"
                                }`}
                            >
                                <PrimaryText
                                    onClick={() => {
                                        !isTrackingDisabled && setTrackpackType("tracking")
                                        // dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                                        dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                        setSelectedRows([])
                                    }}
                                    weight={trackpackType === "tracking" ? "medium" : "light"}
                                    size="xs-small"
                                    className={`${isTrackingDisabled ? "cursor-not-allowed" : "cursor-pointer"} ${
                                        trackpackType === "tracking" ? "text-[#0E7090] " : "text-gray-700"
                                    }  leading-[12px] h-full align-middle`}
                                >
                                    ACTIVE
                                </PrimaryText>
                            </div>
                            <div
                                className={`overflow-hidden rounded-tr-[8px] rounded-br-[8px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] ${
                                    trackpackType === "archived"
                                        ? "bg-[#FEE4E2]"
                                        : isArchiveDisabled
                                        ? "bg-gray-100"
                                        : "bg-white"
                                }`}
                            >
                                <PrimaryText
                                    onClick={() => {
                                        !isArchiveDisabled && setTrackpackType("archived")
                                        // dispatch(AccountActionsCreater.setTrackpackType("archived"))
                                        dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                        setSelectedRows([])
                                    }}
                                    weight={trackpackType === "archived" ? "medium" : "light"}
                                    size="xs-small"
                                    className={` ${
                                        trackpackType === "archived" ? "text-[#B42318]" : "text-[#344054]"
                                    } ${
                                        isArchiveDisabled ? "cursor-not-allowed" : "cursor-pointer"
                                    }  leading-[12px] h-full align-middle`}
                                >
                                    ARCHIVED
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                    <div ref={containerRef} className={`overflow-y-scroll -mr-[25px] min-h-calc-271 h-calc-271`}>
                        {false ? (
                            <SearchpackHubCardSkeletonLoader length={6} isTrackpack={true} />
                        ) : (
                            <div
                                className="flex-col gap-[8px] inline-flex w-full"
                                style={{
                                    width: "calc(100% - 20px)",
                                }}
                            >
                                {trackpackTypeListView &&
                                    trackpackTypeListView?.map((product: any, index: number) => {
                                        const hasCurrentIssue = product.trackpackChildrenList?.some(
                                            (child: Root) => child.currentIssue !== null
                                        )
                                        const selected_country = AMAZON_DOMAINS?.find((val) => {
                                            return val?.value === product.amazonDomain
                                        })
                                        return (
                                            <div
                                                key={index}
                                                ref={(el) => (trackpackRefs.current[index] = el)}
                                                onClick={() => {
                                                    setSelectedIndex(index)
                                                    handleSelectedOptions(product)
                                                    setSelectedRows([])
                                                }}
                                                className={`
                                                    
                                        ${
                                            index === selectedIndex
                                                ? "!border-[2px] !border-[#22CCEE] bg-[#ECFDFF]"
                                                : "bg-white border-2 border-gray-300"
                                        }
                                        last-child p-[8px] mb-0  rounded-[12px] cursor-pointer max-w-[280px] w-auto -outline-offset-2
                                    `}
                                            >
                                                <div className="justify-between flex w-full">
                                                    <div className="flex gap-[8px]">
                                                        <div
                                                            className={`w-[40px] h-[40px]  ${
                                                                index === selectedIndex ? "bg-[#22CCEE]" : "bg-gray-400"
                                                            }  rounded-[6px] flex items-center justify-center`}
                                                        >
                                                            <SearchPackHubIcon
                                                                color={index === selectedIndex ? "#A5F0FC" : "#D0D5DD"}
                                                            />
                                                        </div>
                                                        <div className="">
                                                            <PrimaryText
                                                                className="text-[#344054] leading-[20px] my-[2px]"
                                                                weight="medium"
                                                                size="small"
                                                            >
                                                                Heated Hair Irons
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                className="text-[#667085] leading-[12px] mb-[4px]"
                                                                weight="light"
                                                                size="xs"
                                                            >
                                                                32 Keywords
                                                            </PrimaryText>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="cursor-pointer outline-none"
                                                        data-tooltip-id="dashboard-tooltip"
                                                        data-tooltip-place="bottom"
                                                        data-tooltip-offset={-14}
                                                        data-tooltip-class-name={"!ml-[45px]"}
                                                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                            <CommonTooltip className="" label="Archive Searchpack" />
                                                        )}
                                                    >
                                                        <div
                                                            className={`cursor-pointer w-[30px] h-[30px] ${
                                                                index === selectedIndex ? "bg-[#CFF9FE]" : "bg-gray-200"
                                                            }   rounded-[15px] justify-center items-center flex`}
                                                            onClick={handleOpenArchiveSearchpack}
                                                        >
                                                            <TrackpackTrashIcon
                                                                color={index === selectedIndex ? "#06AED4" : "#667085"}
                                                            />
                                                        </div>
                                                        {/* <ArchiveIcon color={"#667085"} width={12} height={12} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
