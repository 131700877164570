import AddKeywordOutlet from "./addKeywordOutlet.component"
import { AddKeywordsWrapperModal } from "./addKeywordsWrapperModal.component"

const AddKeywordMainModal = () => {
    return (
        <AddKeywordsWrapperModal
            className={`p-[48px] !z-[11111] pb-[36px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] w-[600px]  shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <AddKeywordOutlet />
        </AddKeywordsWrapperModal>
    )
}

export default AddKeywordMainModal
