export const BsrRankingIcon = () => {
    return (
        <svg width="42" height="12" viewBox="0 0 42 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 6H41"
                stroke="#088AB2"
                stroke-width="2"
                stroke-miterlimit="2"
                stroke-linecap="round"
                stroke-dasharray="0.1 4.1"
            />
            <path
                d="M21.004 1.4L21.0046 1.4C22.2244 1.40122 23.3938 1.88685 24.2555 2.75014C25.1173 3.61342 25.6009 4.78367 25.6 6.00346C25.5991 7.22326 25.1137 8.39274 24.2507 9.25473C23.3876 10.1167 22.2175 10.6006 20.9977 10.6C19.7779 10.5994 18.6083 10.1143 17.7461 9.25146C16.8839 8.38861 16.3997 7.21863 16.4 5.99883C16.4003 4.77903 16.8851 3.60932 17.7477 2.74691L18.1719 3.17122L17.7477 2.74691C18.6103 1.88449 19.7802 1.4 21 1.4H21.004Z"
                fill="#088AB2"
                stroke="#ECFDFF"
                stroke-width="1.2"
            />
        </svg>
    )
}
