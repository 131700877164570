import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { memo, useEffect, useRef, useState } from "react"
import { Controller } from "react-hook-form"
import TDatePicker from "tailwind-datepicker-react"
import { IOptions } from "tailwind-datepicker-react/types/Options"
import { twMerge } from "tailwind-merge"
import { CalendarIcon } from "../../assets/svgs/calendarIcon.svg"
import { ChevronDownIcon } from "../../assets/svgs/chevrondownIcon.svg"
import { LeftChevron } from "../../assets/svgs/leftChevron.svg"
import { RightChevron } from "../../assets/svgs/rightChevron.svg"

dayjs.extend(utc)
dayjs.extend(timezone)

const DatePicker = ({
    name,
    control,
    disabled = false,
    setLocalValue,
    selectedSearchpackLastDataTime,
    onDateChange,
    className,
}: DatePickerProps) => {
    const [show, setShow] = useState(false)
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const datePickerRef = useRef<HTMLDivElement>(null)

    const seattleTimeZone = "America/Los_Angeles"

    const createdAt = dayjs(selectedSearchpackLastDataTime?.created_at)
        .tz(seattleTimeZone)
        .toDate()
    const searchDataLastAvailableAt = dayjs(selectedSearchpackLastDataTime?.search_data_last_available_at)
        .tz(seattleTimeZone)
        .toDate()

    const datePickerOptions: IOptions = {
        autoHide: true,
        todayBtn: false,
        clearBtn: false,
        minDate: createdAt,
        maxDate: searchDataLastAvailableAt,
        theme: {
            background: "bg-white pl-2 min-w-[296px] shadow-calendar-shadow",
            todayBtn: "",
            clearBtn: "",
            icons: "",
            text: "",
            input: "",
            inputIcon: "",
            selected: "bg-[#cff9fe] text-white !rounded-full ",
            disabledText: "",
        },
        icons: {
            prev: () => (
                <div>
                    <LeftChevron width={20} height={20} color="#344054" />
                </div>
            ),
            next: () => (
                <div>
                    <RightChevron color="#344054" width={20} height={20} />
                </div>
            ),
        },
        datepickerClassNames: "date_header top-[35px] left-[0px] grid grid-cols-7 gap-2",
        defaultDate: searchDataLastAvailableAt || selectedDate,
        language: "en",
        weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
        inputNameProp: "date",
        inputIdProp: "date",
        inputPlaceholderProp: "Select Date",
        inputDateFormatProp: { day: "numeric", month: "short", year: "numeric" },
    }

    useEffect(() => {
        if (!selectedDate && searchDataLastAvailableAt) {
            setSelectedDate(searchDataLastAvailableAt)
            onDateChange?.(dayjs(searchDataLastAvailableAt).toDate())
        }
    }, [selectedDate])

    const handleClose = (state: boolean) => {
        setShow(state)
    }

    const handleDateChange = (date: Date) => {
        setSelectedDate(date)
        setLocalValue && setLocalValue(date)
        onDateChange?.(date)
    }
    return (
        <div className={twMerge(`relative date-picker`, className)}>
            <Controller
                control={control}
                name={name}
                render={({ fieldState: { error }, field: { onChange } }) => (
                    <>
                        <div ref={datePickerRef}>
                            <TDatePicker
                                options={datePickerOptions}
                                show={show}
                                setShow={handleClose}
                                onChange={(date) => {
                                    handleDateChange(date)
                                    onChange(date)
                                }}
                                value={selectedDate || searchDataLastAvailableAt}
                                classNames={`relative shadow-date-picker-shadow text-gray-700 border rounded-[10px] h-[36px] bg-white cursor-pointer text-[14px] font-[300] leading-[20px] border-gray-200 py-[8px] px-[12px] w-[167px]`}
                            />
                        </div>
                        <div
                            className="absolute left-[12px] top-[50%] transform translate-y-[-50%] cursor-pointer z-50"
                            onClick={() => {
                                setShow(true)
                                datePickerRef.current?.click()
                            }}
                        >
                            <CalendarIcon />
                        </div>
                        <div
                            className="absolute right-[12px] top-[50%] transform translate-y-[-50%] cursor-pointer z-50"
                            onClick={() => {
                                setShow(true)
                                datePickerRef.current?.click()
                            }}
                        >
                            <ChevronDownIcon color="#344054" />
                        </div>
                    </>
                )}
            />
        </div>
    )
}

export default memo(DatePicker)

interface DatePickerProps {
    name: string
    control?: any
    className?: string
    disabled: boolean
    setLocalValue?: any
    selectedSearchpackLastDataTime?: ISearchpackLastDataTime
    onDateChange?: (date: Date) => void
}
