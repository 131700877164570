export enum SearchpackAction {
    CREATE_SEARCHPACK = "CREATE_SEARCHPACK",
    ACTIVE_TAB = "ACTIVE_TAB",
    CREATE_SEARCHPACK_ACCOUNT = "CREATE_SEARCHPACK_ACCOUNT",
    GET_SEARCHPACKS_LIST = "GET_SEARCHPACKS_LIST",
    SET_SEARCHPACKS_LIST = "SET_SEARCHPACKS_LIST",
    ADD_KEYWORDS_TO_SEARCHPACK = "ADD_KEYWORDS_TO_SEARCHPACK",
    GET_SEARCHPACK_WITH_LAST_DATA_TIME = "GET_SEARCHPACK_WITH_LAST_DATA_TIME",
    SET_SELECTED_SEARCHPACK_VALUES = "SET_SELECTED_SEARCHPACK_VALUES",
    SET_SELECTED_SEARCHPACK_NAME = "SET_SELECTED_SEARCHPACK_NAME",
    SET_SEARCHPACKS_LAST_DATA_TIME = "SET_SEARCHPACKS_LAST_DATA_TIME",
    GET_RANKSNAPSHOT_DATA = "GET_RANKSNAPSHOT_DATA",
    SET_RANKSNAPSHOT_DATA = "SET_RANKSNAPSHOT_DATA",
    SELECT_SEARCHPACK_PRODUCT = "SELECT_SEARCHPACK_PRODUCT",
    STORE_SEARCHPACK_PRODUCTS = "STORE_SEARCHPACK_PRODUCTS",
    SET_SEARCHPACK_WITHSEARCHTERM = "SET_SEARCHPACK_WITHTERM",
    GET_SEARCHPACK_WITHSEARCHTERM = "GET_SEARCHPACK_WITHTERM",
    SET_KEYWORD_PAYLOAD = "SET_KEYWORD_PAYLOAD",
    GET_SEARCHPACK_RANK_TRACKER = "GET_SEARCHPACK_RANK_TRACKER",
    SET_SEARCHPACK_RANK_TRACKER = "SET_SEARCHPACK_RANK_TRACKER",
}

export const SearchpackActionCreator = {
    selectedSearchpackPayload: (payload: Partial<ISelectedSearchpackPayload>): ISagaAction => {
        return {
            type: SearchpackAction.CREATE_SEARCHPACK,
            payload,
        }
    },
    setActiveTab: (payload: string): ISagaAction => {
        return {
            type: SearchpackAction.ACTIVE_TAB,
            payload,
        }
    },

    createSearchpack: (
        payload: Partial<ISelectedSearchpackPayload>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SearchpackAction.CREATE_SEARCHPACK_ACCOUNT,
            payload,
            onSuccess,
            onError,
        }
    },
    getSearchpackList: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACKS_LIST,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSearchpacksList: (data: IProductTrackpackList) => ({
        type: SearchpackAction.SET_SEARCHPACKS_LIST,
        payload: data,
    }),
    addKeywordsToSearchpacks: (
        payload: Partial<ISelectedSearchpackPayload>,
        onSuccess?: (data?: any) => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SearchpackAction.ADD_KEYWORDS_TO_SEARCHPACK,
            payload,
            onSuccess,
            onError,
        }
    },
    getSearchpackWithLastDataTime: (
        searchpackId?: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_WITH_LAST_DATA_TIME,
            payload: { searchpackId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSelectedSearchPackValues: (payload: any) => ({
        type: SearchpackAction.SET_SELECTED_SEARCHPACK_VALUES,
        payload,
    }),
    setSelectedSearchPackName: (payload: any) => ({
        type: SearchpackAction.SET_SELECTED_SEARCHPACK_NAME,
        payload,
    }),
    setSearchpackLastDataTime: (data: ISearchpackLastDataTime) => ({
        type: SearchpackAction.SET_SEARCHPACKS_LAST_DATA_TIME,
        payload: data,
    }),
    getRanksnapshotData: (
        searchpackId?: number,
        date?: string,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_RANKSNAPSHOT_DATA,
            payload: { searchpackId, date, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setRanksnapshotData: (data: any) => ({
        type: SearchpackAction.SET_RANKSNAPSHOT_DATA,
        payload: data,
    }),
    selectedSearchpackProduct: (payload: any): ISagaAction => {
        return {
            type: SearchpackAction.SELECT_SEARCHPACK_PRODUCT,
            payload,
        }
    },
    getSearchPackTermData: (
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_WITHSEARCHTERM,
            payload: { pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSelectedSearchPackTermData: (data: IProductSearchpackWithTerm) => ({
        type: SearchpackAction.SET_SEARCHPACK_WITHSEARCHTERM,
        payload: data,
    }),
    setSelectedKeywordPayload: (data: ISearchpackKeywordPayload) => ({
        type: SearchpackAction.SET_KEYWORD_PAYLOAD,
        payload: data,
    }),

    getSearchpackRankTracker: (
        packTermId?: number,
        pathParams?: { [key: string]: string },
        queryParams?: { [key: string]: string },
        onSuccess?: (data?: any) => void,
        onError?: () => void
    ) => {
        return {
            type: SearchpackAction.GET_SEARCHPACK_RANK_TRACKER,
            payload: { packTermId, pathParams, queryParams },
            onSuccess,
            onError,
        }
    },
    setSearchpackRankTracker: (data: any) => ({
        type: SearchpackAction.SET_SEARCHPACK_RANK_TRACKER,
        payload: data,
    }),
}
