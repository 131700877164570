import { RuntimeActions } from "../actions/runTime.action"
import { RuntimeStateClass } from "../classes/RuntimeStateClass"

export const RunTimeReducer = (
    state: IAppRuntimeState = new RuntimeStateClass(), //initial state set through class
    action: ISagaAction
): Partial<IAppRuntimeState> => {
    let loading
    switch (action.type) {
        case RuntimeActions.APP_LOADING_START:
            loading = [...state.loading]

            if (action.payload && action.payload.loadingTarget) {
                loading.push({
                    [action.payload.loadingTarget]: action.payload.isLoading,
                })
            }

            return {
                ...state,
                loading,
            }
        case RuntimeActions.APP_LOADING_STOP:
            loading = [...state.loading]

            if (action.payload && action.payload.loadingTarget) {
                loading = loading.filter((item) => !Object.keys(item).includes(action.payload.loadingTarget))
            }

            return {
                ...state,
                loading,
            }
        case RuntimeActions.OPEN_LIGHTBOX:
            return {
                ...state,
                openLightBox: action.payload,
            }
        case RuntimeActions.CLOSE_LIGHTBOX:
            return {
                ...state,
                openLightBox: undefined,
            }
        case RuntimeActions.OPEN_MODAL:
            return {
                ...state,
                modal: action.payload,
            }
        case RuntimeActions.CLOSE_MODAL:
            return {
                ...state,
                modal: undefined,
            }

        /** //TODO: We need to update the data type to array
         * so that we can manage to open multiple popups
         * That's why we're having three reducers here to open specific, close specific
         * and close all popups
         *  */
        case RuntimeActions.OPEN_POPUP:
            return {
                ...state,
                popups: action.payload.name,
            }

        case RuntimeActions.CLOSE_POPUP:
            // state.popups = state.popups.filter((item) => item !== action.payload.name)

            return {
                ...state,
                popups: "",
            }

        case RuntimeActions.CLOSE_All_POPUP:
            return {
                ...state,
                popups: "",
            }
        case RuntimeActions.OPEN_TRACKPACK_MODAL:
            return {
                ...state,
                trackPackModal: action.payload,
            }
        case RuntimeActions.CLOSE_TRACKPACK_MODAL:
            return {
                ...state,
                trackPackModal: undefined,
            }
        case RuntimeActions.ASIN_TRACKPACK_NEW_MODAL_OPEN:
            return {
                ...state,
                newAsinTrackPack: action.payload,
            }
        case RuntimeActions.ASIN_TRACKPACK_NEW_MODAL_CLOSE:
            return {
                ...state,
                newAsinTrackPack: undefined,
            }
        case RuntimeActions.ADDITIONAL_MODAL_OPEN:
            return {
                ...state,
                newAsinAdditional: action.payload,
            }
        case RuntimeActions.ADDITIONAL_MODAL_CLOSE:
            return {
                ...state,
                newAsinAdditional: undefined,
            }
        case RuntimeActions.OPEN_ARCHIVE_MODAL:
            return {
                ...state,
                archiveState: action.payload,
            }
        case RuntimeActions.CLOSE_ARCHIVE_MODAL:
            return {
                ...state,
                archiveState: undefined,
            }
        case RuntimeActions.OPEN_PERFORMANCE_TOUR_MODAL:
            return {
                ...state,
                performanceTrackerQuickTour: action.payload,
            }
        case RuntimeActions.CLOSE_PERFORMANCE_TOUR_MODAL:
            return {
                ...state,
                performanceTrackerQuickTour: undefined,
            }
        case RuntimeActions.OPEN_TRACKPACK_TOUR_MODAL:
            return {
                ...state,
                trackpackHubQuickTour: action.payload,
            }
        case RuntimeActions.CLOSE_TRACKPACK_TOUR_MODAL:
            return {
                ...state,
                trackpackHubQuickTour: undefined,
            }
        case RuntimeActions.OPEN_NOTIFICATION_ALERT:
            return {
                ...state,
                notificationAlerts: action.payload,
            }
        case RuntimeActions.CLOSE_NOTIFICATION_ALERT:
            return {
                ...state,
                notificationAlerts: undefined,
            }
        case RuntimeActions.ADD_TRACKPACK_FLOW_MODAL_OPEN:
            return {
                ...state,
                addTrackPackFlow: action.payload,
            }
        case RuntimeActions.CLOSE_ADD_TRACKPACK_FLOW_MODAL_OPEN:
            return {
                ...state,
                addTrackPackFlow: undefined,
            }
        case RuntimeActions.SEMI_AUTOMATED_MODAL_OPEN:
            return {
                ...state,
                semiAutomatedAddTrackPack: action.payload,
            }
        case RuntimeActions.CLOSE_SEMI_AUTOMATED_MODAL_OPEN:
            return {
                ...state,
                semiAutomatedAddTrackPack: undefined,
            }
        case RuntimeActions.REPORT_MODAL_OPEN:
            return {
                ...state,
                listingReportModal: action.payload,
            }
        case RuntimeActions.CLOSE_REPORT_MODAL_OPEN:
            return {
                ...state,
                listingReportModal: undefined,
            }
        case RuntimeActions.ADD_SEARCHPACK_MODAL_OPEN:
            return {
                ...state,
                addSearchpackModal: action.payload,
            }
        case RuntimeActions.CLOSE_ADD_SEARCHPACK_MODAL:
            return {
                ...state,
                addSearchpackModal: undefined,
            }
        case RuntimeActions.ADD_KEYWORDS_MODAL_OPEN:
            return {
                ...state,
                addKeywordsModal: action.payload,
            }
        case RuntimeActions.CLOSE_ADD_KEYWORDS_MODAL:
            return {
                ...state,
                addKeywordsModal: undefined,
            }
        case RuntimeActions.ADD_ASIN_TRACKPACK_MODAL_FLOW_OPEN:
            return {
                ...state,
                addAsinToTrackpackFlow: action.payload,
            }
        case RuntimeActions.CLOSE_ADD_ASIN_TRACKPACK_MODAL_FLOW_OPEN:
            return {
                ...state,
                addAsinToTrackpackFlow: undefined,
            }
        case RuntimeActions.ADD_ASIN_TRACKPACK_MODAL_OPEN:
            return {
                ...state,
                addAsinToTrackpackModal: action.payload,
            }
        case RuntimeActions.CLOSE_ADD_ASIN_TRACKPACK_MODAL_OPEN:
            return {
                ...state,
                addAsinToTrackpackModal: undefined,
            }
        case RuntimeActions.OPEN_ARCHIVE_RESOTRE_SEARCHPACK_MODAL:
            return {
                ...state,
                archiveRestoreSearchpackModal: action.payload,
            }
        case RuntimeActions.CLOSE_ARCHIVE_RESOTRE_SEARCHPACK_MODAL:
            return {
                ...state,
                archiveRestoreSearchpackModal: undefined,
            }
        case RuntimeActions.OPEN_CLAIM_OWNERSHIP_MODAL:
            return {
                ...state,
                claimOwnershipModal: action.payload,
            }
        case RuntimeActions.CLOSE_CLAIM_OWNERSHIP_MODAL:
            return {
                ...state,
                claimOwnershipModal: undefined,
            }
        default:
            return state
    }
}
