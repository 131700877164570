import dayjs from "dayjs"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CartesianGrid, DotProps, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { CustomDotSvg } from "../../../assets/svgs/customDot.svg"
import { OrganicRankingIcon } from "../../../assets/svgs/organicRanking.svg"
import { PaidRankingIcon } from "../../../assets/svgs/paidRankingIcon.svg"
import { ASIN_PRODUCTS_COLOR } from "../../../config/colors.config"
import { selectedProducts } from "../../../config/dashboard.config"
import { useGraphData } from "../../../hooks/graphData.hook"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { PrimaryText } from "../../elements/primaryText.element"
import { GraphTooltip } from "../performance-tracker/graph/graphTooltip.component"

export const RankTrackerGraphView = ({ infoVisible, selectedRange }: IGraphViewProps) => {
    const tooltip = useRef<HTMLDivElement>(null)

    const [value, setValue] = useState<ISelectedProduct>()
    const [tooltipVisible, setTooltipVisible] = useState(false)

    const { selectedProductResult, selectedProductView, selectedDropdownList } = useSelector(ProductStateSelector)

    const { rangeDataWithFilledGaps, rangeData, selectedResults, graphData } = useGraphData()
    const dispatch = useDispatch()

    useEffect(() => {
        over()
    }, [rangeDataWithFilledGaps, selectedProductView])

    const customMouseClick = useCallback(
        (e: IDotProps) => {
            setTooltipVisible(false)
            dispatch(RuntimeActionCreators.closeAllPopup())
            setTimeout(() => {
                if (tooltip.current) {
                    setTooltipVisible(true)

                    if (e.payload.from === "error") {
                        tooltip.current.style.top = `${e.cy! - 15}px`
                        tooltip.current.style.left = `${e.cx! - 0}px`
                    } else if (e.payload.from === "alert") {
                        tooltip.current.style.top = `${e.cy! - 15}px`
                        tooltip.current.style.left = `${e.cx! - 1}px`
                    } else {
                        tooltip.current.style.top = `${e.cy! - 12}px`
                        tooltip.current.style.left = `${e.cx! - 0.85}px`
                    }

                    try {
                        let currencyData
                        for (let data of graphData) {
                            const currencyData = data[e.payload.id!]?.currency?.filter(
                                (item: { value: string }) =>
                                    item.value !== "" && item.value !== null && item.value !== undefined
                            )

                            if (currencyData && currencyData[0] && currencyData[0].value) {
                                // @ts-ignore
                                e.payload.product.currency = currencyData
                                break
                            }
                        }
                        // @ts-ignore
                        if (!currencyData || !currencyData[0] || !currencyData[0].value) {
                            const fallbackCurrency =
                                selectedProducts && selectedProducts?.selectedProducts[0]?.main_product_data?.currency
                            if (fallbackCurrency) {
                                // @ts-ignore
                                e.payload.product.currency = fallbackCurrency
                            } else {
                                console.info("No currency data found in graphData or selectedProducts.")
                            }
                        }
                    } catch (error) {}
                    setValue(e.payload)
                }
            }, 300)
        },

        [graphData]
    )

    const DotView = (props: any & { product: IGroupProductData }) => {
        const product = props.product
        const productData: IProductData = props.payload[product?.actual_asin]

        return (
            <CustomDotSvg
                customMoveClick={customMouseClick}
                color={product?.color}
                id={product?.actual_asin}
                initialTimestamp={product ? product?.product_data?.[0]?.timestamp : ""}
                {...props}
            />
        )
    }

    const over = () => {
        setTooltipVisible(false)
    }

    const getView = () => {
        return (
            <div
                className={`rounded-[10px] border border-gray-200 bg-white mb-[12px]`}
                style={{ height: infoVisible ? "calc(100vh - 372px)" : "calc(100vh - 243px)" }}
            >
                <div className={"py-[24px] px-[24px] h-full"}>
                    <div className={"relative h-full"}>
                        <div
                            className={
                                "h-[full] absolute top-1/2 left-0/4 -translate-x-1/2 -translate-y-1/2 -rotate-90 pt-[22px]"
                            }
                        >
                            <PrimaryText
                                size={"xs-medium"}
                                weight={"book"}
                                className={" flex tracking-[3px] h-full text-[#344054]"}
                                uppercase
                            >
                                SEARCH RANKING
                            </PrimaryText>
                        </div>
                        <div className={"relative pb-[21px] graph"} style={{ height: "calc(100% - 20px)" }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={400}
                                    height={200}
                                    data={rangeDataWithFilledGaps}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="0 0" vertical={false} />

                                    <XAxis
                                        dataKey="timestamp"
                                        tickLine={{ display: "none" }}
                                        tick={{ fill: "#98A2B3" }}
                                        axisLine={{ stroke: "#EAECF0" }}
                                        className={
                                            "text-[6px] mt-[2px] tracking-[1px] text-[#98A2B3] font-normal uppercase"
                                        }
                                        dy={11.6}
                                        angle={-45}
                                        tickFormatter={(tickValue) => {
                                            if (tickValue) {
                                                tickValue = dayjs(tickValue).format("MMM-DD")
                                            }

                                            return tickValue
                                        }}
                                    />

                                    <YAxis
                                        tickCount={8}
                                        domain={["dataMin", "dataMax"]}
                                        tickLine={{ display: "none" }}
                                        className={"text-[10px] tracking-[1px] font-normal"}
                                        tick={{ fill: "#98A2B3" }}
                                        axisLine={{ stroke: "#EAECF0" }}
                                        interval={0}
                                        tickFormatter={(tickValue) => {
                                            return tickValue
                                        }}
                                    />

                                    {selectedProducts &&
                                        selectedProducts.selectedProducts.map((product: any, index) => {
                                            const isPaid = product?.type === "paid" // Assuming `type` specifies if it's "paid" or "organic"
                                            const isOrganic = product?.type === "organic"

                                            return (
                                                <>
                                                    <Line
                                                        key={`line-${index}-${product?.actual_asin}`}
                                                        type="monotone"
                                                        dataKey={(data) => {
                                                            try {
                                                                if (
                                                                    data[product?.actual_asin].hasOwnProperty(
                                                                        "was_filled"
                                                                    ) &&
                                                                    data[product?.actual_asin]["was_filled"] === true
                                                                ) {
                                                                    return null
                                                                }
                                                                return data[product?.actual_asin][
                                                                    selectedProductResult?.selectedResults?.value ||
                                                                        "bsr_large"
                                                                ]
                                                            } catch (error) {
                                                                return null
                                                            }
                                                        }}
                                                        stroke={product?.color?.default}
                                                        activeDot={<></>}
                                                        dot={<></>}
                                                        strokeWidth={1.6}
                                                        isAnimationActive={true}
                                                        strokeDasharray={isPaid ? "4 4" : "0"} // Dotted line for "paid", solid for "organic"
                                                    />

                                                    {/* Dashed Line (if needed) */}
                                                    <Line
                                                        key={`dashed-line-${index}-${product?.actual_asin}`}
                                                        type="monotone"
                                                        strokeWidth={1.6}
                                                        connectNulls
                                                        strokeDasharray={isPaid ? "4 4" : "0"} // Dotted line for "paid", solid for "organic"
                                                        dataKey={
                                                            product?.actual_asin +
                                                            `[${
                                                                selectedProductResult?.selectedResults?.value ||
                                                                "bsr_large"
                                                            }]`
                                                        }
                                                        dot={(props: DotProps) => {
                                                            return <DotView {...props} product={product} />
                                                        }}
                                                        activeDot={(props: DotProps) => {
                                                            return <DotView {...props} product={product} />
                                                        }}
                                                        stroke={product?.color?.default}
                                                    />
                                                </>
                                            )
                                        })}

                                    <Tooltip cursor={false} wrapperStyle={{ display: "none" }} />
                                    {/* <Brush /> */}
                                </LineChart>
                            </ResponsiveContainer>

                            <GraphTooltip
                                id={value?.id!}
                                over={over}
                                tooltipVisible={tooltipVisible}
                                tooltip={tooltip}
                                product={value?.product}
                                isFirst={!!value?.product.isFirst}
                                color={value?.color || ASIN_PRODUCTS_COLOR[0]}
                            />
                        </div>

                        <div className="flex justify-center">
                            <div className="flex p-2 gap-3 bg-[#ECFDFF]">
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    uppercase
                                    className={
                                        "flex items-center justify-center text-gray-700 tracking-[5px] leading-[10px]"
                                    }
                                >
                                    PAID <PaidRankingIcon />
                                </PrimaryText>
                                <PrimaryText
                                    size={"xs-medium"}
                                    weight={"book"}
                                    uppercase
                                    className={
                                        "flex items-center justify-center text-gray-700 tracking-[5px] leading-[10px]"
                                    }
                                >
                                    ORGANIC <OrganicRankingIcon />
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return <>{getView()}</>
}
