import parse from "html-react-parser"
import React, { FunctionComponent, SVGProps, useState } from "react"
import CommonTooltip from "../dashboard/tooltipItems/commonTooltip.component"
import { PrimaryText } from "./primaryText.element"

const ButtonGroups: React.FC<IButtonGroupElementsProps> = ({
    onClickHandler,
    defaultValue,
    options,
    tooltipLabel,
    disabled,
}) => {
    const [activeItem, setActiveItem] = useState<number | string | null>(null)
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null)

    const handleButtonClick = (option: {
        value: number | string
        label: string
        labelName?: string
        component?: FunctionComponent<
            SVGProps<SVGSVGElement> & {
                title?: string | undefined
            }
        >
    }) => {
        if (disabled) return
        onClickHandler(option)
    }

    const checkActive = (value: string | number) => {
        if (Array.isArray(defaultValue)) {
            return defaultValue.includes(value)
        } else {
            return defaultValue === value
        }
    }

    const handleMouseEnter = (option: { label: string; labelName?: string; value: number | string }) => {
        if (disabled) return
        setActiveItem(option.value)
        const timeout = setTimeout(() => {
            setShowDropdown(true)
        }, 300)
        setHoverTimeout(timeout)
    }

    const handleMouseLeave = () => {
        if (disabled) return
        if (hoverTimeout) {
            clearTimeout(hoverTimeout)
        }
        setShowDropdown(false)
        setActiveItem(null)
    }

    function convertTextLabel(option: { value: number | string; label: string }) {
        const valueToLabelMap: { [key: number]: string } = {
            0: "All",
            180: "6 Months",
            30: "1 Month",
            14: "2 Weeks",
            7: "1 Week",
        }
        return valueToLabelMap[option.value as number] || option.label
    }

    return (
        <div
            className={`flex items-center gap-[4px] h-[36px] px-[8px] py-[8px] bg-white rounded-[10px] border border-gray-200 ${
                disabled ? " cursor-not-allowed" : ""
            }`}
            // style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
        >
            {options.map((option) => (
                <div key={option.value} className="relative flex items-center">
                    <div className="flex items-center">
                        <button
                            onMouseEnter={() => handleMouseEnter(option)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleButtonClick(option)}
                            className={` ${
                                disabled ? "pointer-events-none cursor-not-allowed" : "cursor-pointer"
                            }  p-[2px] min-w-[20px] min-h-[20px] bg-white aspect-square ${
                                disabled ? "text-gray-200" : ""
                            }`}
                        >
                            {option.component ? (
                                <option.component
                                    strokeColor={
                                        activeItem === option.value
                                            ? "#98A2B3"
                                            : !disabled && checkActive(option.value)
                                            ? "#667085"
                                            : "#EAECF0"
                                    }
                                />
                            ) : (
                                <PrimaryText
                                    className={`${
                                        checkActive(option.value) ? "text-[#667085]" : "text-[#EAECF0]"
                                    } hover:text-[#98A2B3] text-center leading-class text-[12px] font-[400] leading-[16px] tracking-[0.18px]`}
                                >
                                    {parse(option.label)}
                                </PrimaryText>
                            )}
                        </button>
                    </div>
                    {activeItem === option.value && showDropdown && (
                        <CommonTooltip
                            width={option.width}
                            className={`absolute bg-transparent rounded-[4px] top-[20px] left-[10px] z-10`}
                            label={convertTextLabel(option)}
                        />
                    )}
                </div>
            ))}
        </div>
    )
}

export default ButtonGroups
