import { useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"

import { PrimaryText } from "../../elements/primaryText.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

import { ProductStateSelector } from "../../../selectors/product.selector"

import { ProductsActionCreator } from "../../../actions/products.action"
import { GraphIcon } from "../../../assets/svgs/graphIcon.svg"
import { TableIcon } from "../../../assets/svgs/tableIcon.svg"
import { DashboardBoardConfig } from "../../../config/dashboard.config"
import SearchpackTrackerOnboarding from "../../common/skeletonLoader/searchpackTrackerOnboarding.component"
import ButtonGroups from "../../elements/buttonGroup.element"
import { KeywordPerformanceGraphView } from "./keywordPerformanceGraphView.component"
import KeywordPerformanceTable from "./keywordPerformanceTable.component"

const KeywordPerformance = (props: ITableData) => {
    const dispatch = useDispatch()
    const options = ["Paid", "Organic"]
    const [selectOption, setSelectOption] = useState(options)
    const { selectedOption } = useSelector(ProductStateSelector)
    const { control } = useForm()

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleOptionChange = (value: any) => {
        setSelectOption(value)
        dispatch(ProductsActionCreator.selectedOptions(value))
    }

    const [activeIcon, setActiveIcon] = useState<"table" | "graph">("table")

    const optionData: any = [
        {
            label: "test 1",
            value: "test1",
        },
        {
            label: "test 2",
            value: "test2",
        },
        {
            label: "test 3",
            value: "test3",
        },
    ]

    const Options = [<TableIcon />, <GraphIcon />]

    const [selectedRange, setSelectedRange] = useState<number>(30)
    const { keywordPerfromanceConfig } = DashboardBoardConfig

    return (
        <div>
            <SearchpackTrackerOnboarding />

            <div className={"flex justify-between flex-wrap mb-[12px] w-full h-[36px]"}>
                <div className={"flex gap-x-[12px]  items-center h-[36px]"}>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-11}
                        data-tooltip-class-name={"!ml-[75px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter Keywords" />
                        )}
                        className="cursor-pointer outline-none px-[16px] h-[36px] py-[8px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px]"
                    >
                        <PrimaryText
                            onClick={() => console.log("Paid clicked")}
                            className="text-gray-700 leading-[18px] cursor-pointer mt-[2px]"
                            size="small"
                            weight="light"
                        >
                            Paid
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => console.log("Organic clicked")}
                            className="text-gray-700 leading-[18px] cursor-pointer mt-[2px]"
                            size="small"
                            weight="light"
                        >
                            Organic
                        </PrimaryText>
                    </div>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[45px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter Keywords" />
                        )}
                        className="cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px]"
                    >
                        <PrimaryText
                            onClick={() => console.log("Paid clicked")}
                            className="text-gray-200 leading-[18px] cursor-pointer mt-[2px]"
                            size="small"
                            weight="light"
                        >
                            BSR L
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => console.log("Organic clicked")}
                            className="text-gray-200 leading-[18px] cursor-pointer mt-[2px]"
                            size="small"
                            weight="light"
                        >
                            BSR S
                        </PrimaryText>
                    </div>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[48px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Set Time Period" />
                        )}
                    >
                        <ButtonGroups
                            onClickHandler={(option) => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                                return setSelectedRange(option.value as number)
                            }}
                            defaultValue={selectedRange}
                            options={keywordPerfromanceConfig.rankingRange}
                            tooltipLabel="Set Time Period"
                        />
                    </div>
                </div>
                <div
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-place="bottom"
                    data-tooltip-offset={-10}
                    data-tooltip-class-name={"!ml-[38px]"}
                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        <CommonTooltip className="" label="Filter Keywords" />
                    )}
                    className="cursor-pointer outline-none "
                >
                    <div className="inline-flex items-center bg-white border overflow-hidden border-gray-200 rounded-[10px]">
                        {/* Table Icon */}
                        <div
                            onClick={() => setActiveIcon("table")}
                            className={`p-[8px] border-r border-gray-200 ${
                                activeIcon === "table" ? "bg-active-buttons-bg" : "bg-white"
                            }`}
                        >
                            <TableIcon color={activeIcon === "table" ? "#0E7090" : "#667085"} />
                        </div>

                        {/* Graph Icon */}
                        <div
                            onClick={() => setActiveIcon("graph")}
                            className={`p-[8px] ${activeIcon === "graph" ? "bg-active-buttons-bg" : "bg-white"}`}
                        >
                            <GraphIcon color={activeIcon === "graph" ? "#0E7090" : "#667085"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-1">
                {activeIcon === "table" && <KeywordPerformanceTable />}
                {activeIcon === "graph" && <KeywordPerformanceGraphView infoVisible={true} selectedRange={30} />}
            </div>
        </div>
    )
}

export default KeywordPerformance
