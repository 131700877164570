import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { ProductsActionCreator } from "../../../actions/products.action"
import { DashboardBoardConfig } from "../../../config/dashboard.config"
import { ProductStateSelector } from "../../../selectors/product.selector"
import ButtonGroups from "../../elements/buttonGroup.element"
import { RadioGroupButton } from "../../elements/radioGroupButton.element"
import { SelectElement } from "../../elements/select.element"
import { LightBoxGallery } from "../../lightBox.component"
import { LogView } from "./log/logView.component"
import { TableView } from "./table/tableView.component"

import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as ChiveronRight } from "../../../assets/svgs/chiveron-right.svg"
import { ReactComponent as CrossIcon } from "../../../assets/svgs/cross-icon.svg"
import { ReactComponent as FlashIcon } from "../../../assets/svgs/flash-icon.svg"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { PrimaryText } from "../../elements/primaryText.element"
import ChartModal from "../modals/chartModal.component"
import { TooltipGallery } from "../tooltipGallery.component"
import { GraphView } from "./graph/graph.component"

export const AsinSelectOptions = () => {
    const { selectedOption } = useSelector(ProductStateSelector)

    const dispatch = useDispatch()
    const { t } = useTranslation("input")

    const { t: ITooltips } = useTranslation("tooltip")
    const { t: IValidations } = useTranslation("validations")

    // Temp issue fixed for tooltip need to be fixed in future and move all tooltip to main file
    const { graphConfig } = DashboardBoardConfig
    const options = ["Graph", "Table", "Log"]
    const { control, watch } = useForm()

    const selectedAsinValue = watch("asins-a")

    const [selectOption, setSelectOption] = useState(options[0])
    const { loading } = useSelector(RunTimeStateSelector)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isLoading: boolean = useMemo(() => RuntimeHelper.productLoading(), [loading])
    const { selectedProductView } = useSelector(ProductStateSelector)

    const [infoVisible, setInfoVisible] = useState(false)
    const [selectedViews, setSelectedViews] = useState<ProductViewType[]>([
        "actual_asin",
        "seller_info",
        "stock",
        "price",
        "title",
        "bullets",
        "description",
        "main_image",
        "carousel_images",
        "main_video",
        "videos",
        // "keywords",
    ])
    const [selectedRange, setSelectedRange] = useState<number>(30)

    useEffect(() => {
        if (selectedViews.length) {
            dispatch(ProductsActionCreator.selectedProductView({ selectedViews }))
        }
    }, [dispatch, selectedViews])

    useEffect(() => {
        if (selectedRange) {
            dispatch(ProductsActionCreator.selectedProductRange({ selectedRange: selectedRange }))
        }
    }, [dispatch, selectedRange])

    useEffect(() => {
        dispatch(ProductsActionCreator.selectedProductRange({ selectedRange: selectedRange }))

        dispatch(ProductsActionCreator.selectedProductView({ selectedViews: selectedViews }))

        const subscription = watch((value, { name, type }) => {
            const selectedResult = value["asins-a"][Object.keys(value["asins-a"])[0]]

            if (selectedResult) {
                dispatch(ProductsActionCreator.selectedProductResult({ selectedResults: selectedResult }))
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, dispatch, graphConfig.productRange, graphConfig.productDifferentiateItems, selectedViews, selectedRange])

    useEffect(() => {
        if (selectedProductView) {
            setSelectedViews(selectedProductView?.selectedViews)
        }
    }, [selectedProductView?.selectedViews])

    const handleViewClick = (value: ProductViewType) => {
        // Check if the item is already selected
        const isSelected = selectedViews.includes(value)

        // If the item is selected, remove it from the array
        // Otherwise, add it to the array
        if (isSelected) {
            setSelectedViews(selectedViews.filter((item) => item !== value))
        } else {
            setSelectedViews([...selectedViews, value])
        }
    }

    const handleOptionChange = (value: any) => {
        setSelectOption(value)
        dispatch(ProductsActionCreator.selectedOptions(value))
        dispatch(RuntimeActionCreators.closeAllPopup())
    }

    const filteredItems = graphConfig.productDifferentiateItems.filter((item) => item.value !== "actual_asin")

    return (
        <>
            <div
                className="ml-auto rounded-[16px]  border border-gray-50 bg-gray-50 px-[12px] py-[12px] w-full pb-0"
                style={{
                    width: "calc(100% - 324px)",
                    height: "calc(100vh - 165px)",
                }}
            >
                {infoVisible && (
                    <div className="w-full mb-[46px] h-[84px] px-3 pt-[15px] pb-5 bg-cyan-100 rounded-lg border border-cyan-100">
                        <div className="h-[49px] justify-between items-start gap-2 flex">
                            <div className=" relative">
                                <FlashIcon />
                            </div>
                            <div className="flex flex-col w-[95%]">
                                <PrimaryText className="text-cyan-700 leading-7" size="lg" weight="medium">
                                    Tracking takes time
                                </PrimaryText>

                                <div className="flex items-center ">
                                    <PrimaryText className="text-slate-700 leading-tight" size="small" weight="light">
                                        Data improves the more time you give it. I’ll track changes in real-time and
                                        alert you to your competitor’s performance.
                                    </PrimaryText>
                                    <PrimaryText
                                        className="text-cyan-700 ml-[8px] leading-5 font-medium pt-[2px]"
                                        size="small"
                                        weight="bold"
                                    >
                                        See Alerts
                                    </PrimaryText>

                                    <div className="relative">
                                        <ChiveronRight />
                                    </div>
                                </div>
                            </div>
                            <div
                                onClick={() => setInfoVisible(false)}
                                className=" cursor-pointer w-5 h-5 justify-start items-start gap-2.5 flex"
                            >
                                <div className="w-5 h-5 p-2 rounded-lg justify-center items-center flex">
                                    <div className="w-5 h-5 relative">
                                        <CrossIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className={"flex justify-between flex-wrap mb-[12px] w-full"}>
                    <div className={"flex gap-[12px] flex-col xl:flex-row lg:flex-row md:flex-col"}>
                        <SelectElement
                            disabled={selectOption === "Table" || selectOption === "Log"}
                            reactHookControl={control}
                            type="check"
                            name="asins-a"
                            placeholder={t("select.placeholder.results")}
                            className={`!w-[148px] active:outline-transparent active:border-transparent h-[36px] focus:outline-offset-0 focus:outline-transparent focus:border-transparent text-gray-300
                                ${
                                    selectOption === "Table" || selectOption === "Log"
                                        ? "cursor-not-allowed !text-gray-200"
                                        : ""
                                }`}
                            parentClass="relative border border-gray-200 rounded-[10px] h-[36px]"
                            optionsMenuClass="min-w-[148px]"
                            staticPlaceholder={false}
                            options={graphConfig.productResults}
                            defaultValue={"bsr_large"}
                            rightCheckBox
                            tooltipLabel="Toggle Results"
                            isToolitpShow
                            isGraphTooltipClose
                        />

                        <ButtonGroups
                            disabled={selectOption === "Log"}
                            onClickHandler={(option) => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                                handleViewClick(option.value as ProductViewType)
                            }}
                            defaultValue={selectedViews}
                            options={filteredItems}
                            tooltipLabel="Toggle Changes"
                        />

                        <ButtonGroups
                            onClickHandler={(option) => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                                return setSelectedRange(option.value as number)
                            }}
                            defaultValue={selectedRange}
                            options={graphConfig.productRangeUpdated}
                            tooltipLabel="Set Time Period"
                        />
                    </div>

                    <div>
                        <RadioGroupButton
                            options={options}
                            selectedOption={selectedOption}
                            onChange={handleOptionChange}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                <div className="flex-1">
                    {selectOption === "Graph" && (
                        <GraphView
                            selectedRange={selectedRange}
                            infoVisible={infoVisible}
                            selectedAsinValue={selectedAsinValue}
                        />
                    )}
                    {selectedOption === "Table" && <TableView infoVisible={infoVisible} />}
                    {selectedOption === "Log" && <LogView infoVisible={infoVisible} selectedRange={selectedRange} />}
                </div>

                <TooltipGallery selectedView={selectOption as "Graph" | "Table" | "Log"} />

                <LightBoxGallery />

                <ChartModal />
            </div>
        </>
    )
}
