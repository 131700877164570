import dayjs from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { FlipArrowIcon } from "../../../assets/svgs/flipBackwardIcon.svg"
import { InputArrow } from "../../../assets/svgs/inputArrow.svg"
import { NewEditIcon } from "../../../assets/svgs/newEditIcon.svg"
import { ReactComponent as Plus } from "../../../assets/svgs/plus-icon.svg"
import { TrackpackTrashIcon } from "../../../assets/svgs/trashIcon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { getNotificationMessage, handleAmazonProductRedirect } from "../../../helpers/util.helper"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import SearchpackHubOnboardingComponent from "../../common/skeletonLoader/searchpackHubCentralOnboarding.component"
import TrackpackSkeleton from "../../common/skeletonLoader/trackpackSkeleton.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { AMAZON_DOMAINS } from "../../marketing/domains.config"
import CommonTooltip from "../tooltipItems/commonTooltip.component"
import { Root, SearchpackHubOptions } from "./searchpackHubOptions.component"

interface ASINsValues {
    asinNo: string
    asinText: string
    imgUrl?: string
}
interface RowData {
    ASINs: ASINsValues
    Status: string[]
    Owner: string
    "Date Added": string
}

export const SearchpackHubCentral = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList, trackpackType, selectedAsinsLength } = useSelector(AccountStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const intermediateCheckboxRef = useRef<HTMLInputElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<string>("Newest")
    const containerRef = useRef<HTMLInputElement>(null)
    const [isEditing, setIsEditing] = useState(false)
    const [text, setText] = useState(selectedTrackpacksList?.title || "")
    const { loading } = useSelector(RunTimeStateSelector)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const pathID = localStorage.getItem("trackpackHubId")
    const navigate = useNavigate()
    const trackpackHubAsin = localStorage.getItem("trackpackHubAsin")
    const { refetch } = useGetExtendedTrackpacks({}, {})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])

    useEffect(() => {
        // @ts-ignore
        if (selectedAsinsLength?.data === 0 && selectedRows.length !== 0) {
            setSelectedRows([])
        }
    }, [selectedAsinsLength])

    useEffect(() => {
        selectedAsinsValues()
        if (intermediateCheckboxRef && intermediateCheckboxRef.current) {
            if (selectedRows.length > 0 && selectedRows.length < selectedTrackpacksListData?.length) {
                setTimeout(() => {
                    // @ts-ignore
                    if (intermediateCheckboxRef && intermediateCheckboxRef.current) {
                        intermediateCheckboxRef.current.indeterminate = true
                    }
                }, 1)
            } else {
                intermediateCheckboxRef.current.indeterminate = false
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows?.length, selectedTrackpacksList?.trackpackChildrenList?.length])

    const selectedAsinsValues = () => {
        dispatch(AccountActionsCreater.setSelectedAsinsLength(selectedRows.length, selectedRows))
    }
    const toggleAllCheckboxes = () => {
        if (trackpackType === "tracking") {
            const selectActiveAsins = selectedTrackpacksList?.trackpackChildrenList?.filter((row: RowData) =>
                row.Status.includes("ACTIVE")
            )
            setSelectedRows(selectActiveAsins.map((row: RowData) => row.ASINs.asinNo))
            if (selectedRows.length === selectActiveAsins.length) {
                setSelectedRows([])
            }
        } else if (trackpackType === "archived") {
            const selectArchivedAsins = selectedTrackpacksList?.trackpackChildrenList?.filter((row: RowData) =>
                row.Status.includes("ARCHIVED")
            )
            setSelectedRows(selectArchivedAsins.map((row: RowData) => row.ASINs.asinNo))
            if (selectedRows?.length === selectArchivedAsins.length) {
                setSelectedRows([])
            }
        }
    }

    const toggleCheckbox = (asinNo: string) => {
        setSelectedRows((prevSelectedRows: string[]) => {
            if (prevSelectedRows.includes(asinNo)) {
                return prevSelectedRows.filter((asin: string) => asin !== asinNo)
            } else {
                return [...prevSelectedRows, asinNo]
            }
        })
    }

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAsinAddtionalModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleOpenArchiveSearchpack = () => {
        dispatch(
            RuntimeActionCreators.openArchiveRestoreSearchpackModal({
                open: true,
                stepNumber: "one",
                isSearchpack: true,
                isKeyword: true,
            })
        )
    }

    const handleOpenRestoreSearchpack = () => {
        dispatch(
            RuntimeActionCreators.openArchiveRestoreSearchpackModal({
                open: true,
                stepNumber: "one",
                isRestore: true,
                isKeyword: true,
            })
        )
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedItem(item)
        setIsOpen(false)
    }

    const handleEditClick = () => {
        setText(selectedTrackpacksList?.title)
        setIsEditing(true)
    }

    const handleSaveClick = () => {
        setIsEditing(false)
        selectedTrackpacksList.title = text

        dispatch(
            ProductsActionCreator.updateTrackpackName({
                id: selectedTrackpacksList.id,
                name: text,
            })
        )
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSaveClick()
        }
    }

    const selectedTrackpacksListData =
        trackpackType === "tracking"
            ? selectedTrackpacksList?.trackpackChildrenList?.filter((val: any) => {
                  return val.Status.includes("ACTIVE") || val.Status.includes("AWAITING_INITIAL_DATA")
              })
            : selectedTrackpacksList?.trackpackChildrenList?.filter((val: any) => {
                  return val.Status.includes("ARCHIVED") || val.Status.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
              })

    const handleProductRedirect = (asinNo: string, amazon_tld: string) => {
        handleAmazonProductRedirect(amazon_tld, asinNo)
    }

    const isAllChecked = (): boolean => {
        return selectedTrackpacksListData?.length !== 0 && selectedRows?.length === selectedTrackpacksListData?.length
    }

    const selected_country = AMAZON_DOMAINS?.find((val) => {
        return val?.value === selectedTrackpacksList?.amazonDomain
    })

    const Table = () => {
        return (
            <table className="w-full bg-white rounded-[10px]">
                <thead className="bg-gray-200 border-b border-gray-200">
                    <tr>
                        <th className="pl-[20px] py-[12px] text-left text-gray-500 text-xs font-normal  leading-[18px]">
                            <td className="flex-1">
                                <div className="">
                                    <PrimaryText size="xs" weight="medium" className="text-gray-700">
                                        Keywords
                                    </PrimaryText>
                                </div>
                            </td>
                        </th>
                        <th className="w-[120px] text-center">
                            <td className="w-[120px] text-center">
                                <PrimaryText size="xs" weight="medium" className="text-gray-700">
                                    {trackpackType === "tracking" ? "Date Added" : "Time to Deletion"}
                                </PrimaryText>
                            </td>
                        </th>
                        <th className="w-[66px] text-center">
                            <td className="w-[66px] text-center"></td>
                        </th>
                    </tr>
                </thead>
                {isLoading ? (
                    <TrackpackSkeleton />
                ) : (
                    <tbody>
                        {selectedTrackpacksListData &&
                            selectedTrackpacksListData.length > 0 &&
                            selectedTrackpacksListData?.map((row: any, rowIndex: number) => (
                                <>
                                    <tr
                                        key={rowIndex}
                                        className="border-b last:border-b-0  border-gray-200 hover:bg-gray-100 group"
                                    >
                                        <td className="pl-[20px] py-[12px] text-left text-gray-700 text-[12px]  font-[300] leading-[18px]">
                                            <div className="flex gap-[30px] items-center mt-[2px]">
                                                KN95 Face Mask Black
                                            </div>
                                        </td>

                                        <td className="px-[20px] py-[12px] text-center text-gray-700 text-[12px] font-[300] leading-[18px]">
                                            {row.Status.includes("AWAITING_INITIAL_DATA") ? (
                                                <Skeleton baseColor="#D0D5DD" count={1} width={64} height={16} />
                                            ) : trackpackType === "tracking" ? (
                                                dayjs(row["Date Added"])
                                                    .format("MMM-DD-YYYY")
                                                    ?.replace(/^\w+/, (month) => month.toUpperCase())
                                            ) : (
                                                <>{`${row.time_deletion} days `}</>
                                            )}
                                        </td>
                                        <td className="  w-[66px] py-[12px] text-center text-gray-700 text-[12px] font-[300] leading-[18px] flex items-center justify-center">
                                            <div
                                                className="cursor-pointer outline-none"
                                                data-tooltip-id="dashboard-tooltip"
                                                data-tooltip-place="bottom"
                                                data-tooltip-offset={1}
                                                data-tooltip-class-name={"!ml-[35px]"}
                                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                                    <CommonTooltip className="" label="Restore Keyword" />
                                                )}
                                            >
                                                <div
                                                    onClick={handleOpenArchiveSearchpack}
                                                    className={`cursor-pointer w-[24px] opacity-0 group-hover:opacity-[1] transition-all h-[24px] bg-white rounded-[15px] justify-center items-center flex`}
                                                >
                                                    <TrackpackTrashIcon width={16} height={16} />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ))}
                    </tbody>
                )}
            </table>
        )
    }

    const hasOnlyActiveOrAwaiting = (trackpacks: any[]) => {
        return (
            trackpacks &&
            trackpacks.every((val) => val?.Status?.includes("ACTIVE") || val?.Status?.includes("AWAITING_INITIAL_DATA"))
        )
    }

    const isArchiveDisabled = useMemo(() => {
        return hasOnlyActiveOrAwaiting(selectedTrackpacksList?.trackpackChildrenList)
    }, [selectedTrackpacksList?.trackpackChildrenList])

    const hasOnlyArchivedOrAwaiting = (trackpacks: any[]) => {
        return (
            trackpacks &&
            trackpacks.every(
                (val) => val?.Status?.includes("ARCHIVED") || val?.Status?.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
            )
        )
    }

    const isTrackingDisabled = useMemo(() => {
        return hasOnlyArchivedOrAwaiting(selectedTrackpacksList?.trackpackChildrenList)
    }, [selectedTrackpacksList?.trackpackChildrenList])

    useEffect(() => {
        if (selectedTrackpacksListData) {
            if (isTrackingDisabled) {
                dispatch(AccountActionsCreater.setTrackpackType("archived"))
            } else if (isArchiveDisabled) {
                dispatch(AccountActionsCreater.setTrackpackType("tracking"))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArchiveDisabled, isTrackingDisabled])

    const handlePtRedirect = (trackpackList: any) => {
        dispatch(
            ProductsActionCreator.getExtendedTrackingData(
                String(trackpackList?.id),
                {},
                { only_active_trackings: true }
            )
        )
        // dispatch(
        //     ProductsActionCreator.setSelectedTrackPackValues({
        //         selectedTrackpackID: String(trackpackList?.id),
        //         defualtSelectedAsin: asinsArray,
        //     })
        // )
        dispatch(
            RuntimeActionCreators.openNotificaitonAlert({
                open: true,
                trackpackName: selectedTrackpacksList?.title,
                notificationType: "view_in_tp",
                id: trackpackList?.id,
            })
        )
        navigate(ROUTES_CONFIG.dashboard)
    }
    const handleRestoreAsin = () => {
        if (selectedAsinsLength && selectedAsinsLength?.data > 0) {
            dispatch(
                ProductsActionCreator.restoreAsins(
                    {
                        trackpack_id: selectedTrackpacksList.id,
                        asins: selectedAsinsLength?.selectedRows,
                    },
                    (data: any) => {
                        refetch()
                        dispatch(
                            RuntimeActionCreators.openNotificaitonAlert({
                                open: true,
                                notificationType: "restore",
                                trackpackName: selectedTrackpacksList?.title,
                                duration: 30000,
                                id: selectedTrackpacksList.id,
                                isAsinRestore: true,
                                asinsLength: selectedAsinsLength?.selectedRows?.length,
                                restoreMessage: getNotificationMessage(selectedTrackpacksList, data),
                            })
                        )
                        setSelectedRows([])
                    }
                )
            )
        }
    }
    const updateDropdownList = (updatedData: Root) => {
        const updatedDropdownList = selectedDropdownList?.trackpacks?.map((item: TrackpackDatum) =>
            item.metadata.id === selectedTrackpacksList.id ? updatedData : item
        )
        dispatch(ProductsActionCreator.setDropdownList(updatedDropdownList))
    }

    useEffect(() => {
        if (pathID && trackpackHubAsin) {
            const selectedObject = selectedDropdownList?.trackpacks?.find(
                (item: { metadata: { id: number | string } }) => item.metadata.id == pathID
            )

            const matchingTracking = selectedObject?.trackings.find((tracking: any) =>
                trackpackHubAsin.includes(tracking.asin)
            )

            if (matchingTracking && matchingTracking.status === "ARCHIVED") {
                dispatch(AccountActionsCreater.setTrackpackType("archived"))
            }
            setTimeout(() => {
                localStorage.removeItem("trackpackHubAsin")
            }, 6000)
        } else if (pathID && selectedTrackpacksList) {
            const matchingTrackpack = selectedTrackpacksList?.id == pathID
            if (matchingTrackpack) {
                if (
                    (selectedTrackpacksList.status === "ACTIVE" ||
                        selectedTrackpacksList.status === "AWAITING_INITIAL_DATA") &&
                    !isTrackingDisabled
                ) {
                    dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                } else if (
                    selectedTrackpacksList.status === "ARCHIVED" ||
                    selectedTrackpacksList.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                ) {
                    dispatch(AccountActionsCreater.setTrackpackType("archived"))
                }
            }
        }
    }, [pathID, selectedTrackpacksList])

    return (
        <>
            <div className={"flex gap-[20px] items-start w-full h-full"}>
                <SearchpackHubOptions setSelectedRows={setSelectedRows} />
                {false ? (
                    <SearchpackHubOnboardingComponent />
                ) : (
                    <div
                        className="p-[12px] rounded-[16px] bg-gray-50 pb-0 flex-1 pr-0"
                        style={{
                            border: "1px solid",
                            borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                        }}
                    >
                        <>
                            <div className="w-full mb-[20px] justify-between items-center inline-flex pr-[12px]">
                                <div className="w-full ml-[10px] py-[6px] justify-start items-center gap-1 flex">
                                    <div className="w-full flex items-center gap-4">
                                        {isEditing ? (
                                            <div className="flex items-center gap-2">
                                                <input
                                                    type="text"
                                                    className="min-w-full max-w-auto border-0 bg-[#F9FAFB] border-b border-[#088AB2]  text-[#088AB2] text-base font-medium leading-normal placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#06AED4]"
                                                    value={text}
                                                    placeholder="Insert name..."
                                                    onChange={(e) => setText(e.target.value)}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <span
                                                    className="cursor-pointer text-cyan-500 text-xs font-light leading-[18px]"
                                                    onClick={handleSaveClick}
                                                >
                                                    <InputArrow />
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                <span className="text-[#088AB2] text-base font-medium leading-normal">
                                                    {selectedTrackpacksList && selectedTrackpacksList?.title}
                                                </span>
                                                <span
                                                    className="cursor-pointer text-cyan-500 text-xs font-light leading-[18px]"
                                                    onClick={handleEditClick}
                                                >
                                                    <NewEditIcon />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <ButtonElement
                                        onClick={handleOpen}
                                        size="medium"
                                        // prefix={<Plus />}
                                        className="px-[12px] py-[8px] pt-[10px] rounded-[10px] w-[111px] h-[36px] flex items-center"
                                        parentClass="justify-start items-center gap-2 inline-flex  "
                                        textClass="text-[#088AB2] flex item-center gap-[8px]"
                                    >
                                        <Plus />
                                        Keywords
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className="ml-[14px] mb-[12px]">
                                <div className="flex gap-4 items-center">
                                    <div className="flex border border-[#A5F0FC] rounded-[4px] w-[108px] h-[16px]">
                                        <div
                                            className={`overflow-hidden rounded-tl-[3px] rounded-bl-[3px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] border-r border-[#A5F0FC] h-full ${
                                                trackpackType === "tracking"
                                                    ? "bg-[#CFF9FE]"
                                                    : isTrackingDisabled
                                                    ? "bg-white"
                                                    : "bg-white"
                                            }`}
                                        >
                                            <PrimaryText
                                                onClick={() => {
                                                    !isTrackingDisabled &&
                                                        dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                                                    dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                                    setSelectedRows([])
                                                }}
                                                weight={trackpackType === "tracking" ? "medium" : "light"}
                                                size="xs-small"
                                                className={`${
                                                    isTrackingDisabled
                                                        ? "cursor-not-allowed !text-gray-300"
                                                        : "cursor-pointer"
                                                } ${
                                                    trackpackType === "tracking" ? "text-[#0E7090] " : "text-gray-700"
                                                }  leading-[12px] h-full align-middle`}
                                            >
                                                TRACKING
                                            </PrimaryText>
                                        </div>
                                        <div
                                            className={`overflow-hidden rounded-tr-[8px] rounded-br-[8px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] ${
                                                trackpackType === "archived"
                                                    ? "bg-[#FEE4E2]"
                                                    : isArchiveDisabled
                                                    ? "bg-white "
                                                    : "bg-white"
                                            }`}
                                        >
                                            <PrimaryText
                                                onClick={() => {
                                                    !isArchiveDisabled &&
                                                        dispatch(AccountActionsCreater.setTrackpackType("archived"))
                                                    dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                                    setSelectedRows([])
                                                }}
                                                weight={trackpackType === "archived" ? "medium" : "light"}
                                                size="xs-small"
                                                className={` ${
                                                    trackpackType === "archived" ? "text-[#B42318]" : "text-[#344054]"
                                                } ${
                                                    isArchiveDisabled
                                                        ? "cursor-not-allowed text-gray-300"
                                                        : "cursor-pointer"
                                                } leading-[12px] h-full align-middle`}
                                            >
                                                ARCHIVED
                                            </PrimaryText>
                                        </div>
                                    </div>

                                    {selectedRows?.length > 0 && (
                                        <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
                                            {trackpackType === "tracking" ? (
                                                <div
                                                    onClick={(e: React.SyntheticEvent) => {
                                                        e.stopPropagation()
                                                        handleOpenArchiveSearchpack()
                                                    }}
                                                >
                                                    <TrackpackTrashIcon />
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={(e: React.SyntheticEvent) => {
                                                        e.stopPropagation()
                                                        handleOpenRestoreSearchpack()
                                                    }}
                                                >
                                                    <FlipArrowIcon />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                ref={containerRef}
                                className="overflow-y-scroll -mr-[17px]"
                                style={{ height: "calc(100vh - 271px)" }}
                            >
                                <div
                                    className="bg-white rounded-[16px] border border-gray-200 overflow-hidden"
                                    style={{ width: "calc(100% - 24px)" }}
                                >
                                    <Table />
                                </div>
                            </div>
                        </>
                    </div>
                )}
            </div>
        </>
    )
}
