import React from "react"
import Skeleton from "react-loading-skeleton"
import { PaginationLeftArrow } from "../../../assets/svgs/paginationLeftArrow.svg"
import { PaginationRightArrow } from "../../../assets/svgs/paginationRightArrow.svg"
import { CrossNewIcon } from "../../../assets/svgs/xIcon.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const ReportModalSkelton: React.FC<ReportModalFlowProps> = ({
    icon,
    handleCloseDialog,
    handlePrev,
    totalSteps,
    currentStep,
    previousStep,
}) => {
    return (
        <>
            <div className="flex justify-between">
                <div className=" rounded-[12px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>

            <>
                <div
                    className={`grid grid-cols-3 gap-[16px] mt-[36px] bg-gray-100 border border-gray-200 p-[28px] rounded-[16px] `}
                >
                    {[1, 2, 3].map(() => (
                        <div
                            className={`rounded-[10px] w-[200px] h-[200px] bg-[#EAECF0] flex items-center justify-center overflow-hidden `}
                            style={
                                {
                                    // outline: index === 0 ? " " : "1px solid #EAECF0 ",
                                    // boxShadow:
                                    //     index === 0 ? "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814" : "",
                                }
                            }
                        >
                            <Skeleton
                                baseColor="#EAECF0"
                                count={1}
                                width={200}
                                height={200}
                                className="rounded-[6px]"
                            />
                        </div>
                    ))}
                </div>
            </>
            <div className="  mt-[36px] text-center max-w-[685px] w-full mx-auto">
                <Skeleton baseColor="#EAECF0" count={1} width={540} height={16} className="rounded-[8px]" />
                <Skeleton baseColor="#EAECF0" count={1} width={400} height={16} className="rounded-[8px]" />
            </div>

            <div className="flex items-center max-w-[516px] w-full mx-auto mt-[20px] mb-[82px]">
                {[1, 2, 3, 4, 5].map((val, index) => (
                    <React.Fragment key={index}>
                        <div className="flex flex-col items-center w-[60px] relative">
                            <div
                                className={`w-[28px] h-[28px] pt-[2px] text-sm font-[400] flex justify-center items-center rounded-full border cursor-pointer bg-gray-200 `}
                            ></div>
                            {index === 0 && (
                                <span className="text-sm font-[400] text-gray-500 w-[40px] text-center absolute bottom-[-48px]">
                                    <Skeleton
                                        baseColor="#EAECF0"
                                        count={1}
                                        width={32}
                                        height={12}
                                        className="rounded-[8px]"
                                    />
                                    <Skeleton
                                        baseColor="#EAECF0"
                                        count={1}
                                        width={48}
                                        height={12}
                                        className="rounded-[8px]"
                                    />
                                </span>
                            )}
                            {index === 4 && (
                                <span className="text-sm font-[400] text-gray-500 w-[40px] text-center absolute bottom-[-48px]">
                                    <Skeleton
                                        baseColor="#EAECF0"
                                        count={1}
                                        width={32}
                                        height={12}
                                        className="rounded-[8px]"
                                    />
                                    <Skeleton
                                        baseColor="#EAECF0"
                                        count={1}
                                        width={48}
                                        height={12}
                                        className="rounded-[8px]"
                                    />
                                </span>
                            )}
                        </div>
                        {/* Line */}
                        {index !== 4 && <div className="h-px bg-gray-200 w-[54px]"></div>}
                    </React.Fragment>
                ))}
            </div>

            <div className="flex space-x-[12px]">
                <div className="flex-1">
                    <Skeleton baseColor="#EAECF0" count={1} width={334} height={92} className="rounded-[12px]" />
                </div>

                <div className="flex-1">
                    <Skeleton baseColor="#EAECF0" count={1} width={334} height={92} className="rounded-[12px]" />
                </div>
            </div>

            <div className=" flex justify-center gap-[12px] items-center mt-[36px]">
                <button disabled={true} className="flex items-center">
                    <PrimaryText
                        onClick={handlePrev}
                        className={`bg-gray-100 border border-[#F2F4F7] rounded-[6px] p-[5px] cursor-not-allowed`}
                    >
                        <PaginationLeftArrow stroke={"#D0D5DD"} />
                    </PrimaryText>
                </button>

                <button type="submit" className="flex items-center">
                    <PrimaryText className={`bg-gray-100 border border-[#F2F4F7] rounded-[6px] p-[5px] cursor-pointer`}>
                        <PaginationRightArrow stroke={"#D0D5DD"} />
                    </PrimaryText>
                </button>
            </div>
            <div className="mt-10">
                <div className="flex items-center justify-center space-y-4">
                    <div className="flex items-center justify-center space-x-[4px]">
                        {Array.from({ length: 18 }).map((_, index) => {
                            let bgColor = "bg-gray-200"
                            return <div key={index} className={`h-[4px] w-[34px] rounded-[2px] ${bgColor}`}></div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportModalSkelton
