import { Fragment, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { ProductStateSelector } from "../../../../selectors/product.selector"
import { PrimaryText } from "../../../elements/primaryText.element"

import { ReactComponent as AlertIcon } from "../../../../assets/svgs/alert.svg"
import { ReactComponent as SmallPlayVideo } from "../../../../assets/svgs/small-play-icon.svg"

import dayjs from "dayjs"
import "yet-another-react-lightbox/styles.css"
import { AsinLinkExternalSvg } from "../../../../assets/svgs/AsinExternalLink.svg"
import { CurrencyDollarIcon } from "../../../../assets/svgs/currencyDollar.svg"
import { LogAsinChangeIcon } from "../../../../assets/svgs/logAsinChange.svg"
import { LogBulletsIcon } from "../../../../assets/svgs/logBulletsIcon.svg"
import { LogCarusolIcon } from "../../../../assets/svgs/logCarusolIcon.svg"
import { LogDescriptionIcon } from "../../../../assets/svgs/logDescriptionIcon.svg"
import { LogSellerInfoIcon } from "../../../../assets/svgs/logSellerIcon.svg"
import { LogTitleIcon } from "../../../../assets/svgs/logTitleIcon.svg"
import { LogVideoIcon } from "../../../../assets/svgs/LogVideoIcon.svg"
import { LogMainImageIcon } from "../../../../assets/svgs/mainImageIcon.svg"
import { ProductVideosIcon } from "../../../../assets/svgs/productVideoIcon.svg"
import { TooltipStockIcon } from "../../../../assets/svgs/tooltipStockIcon.svg"
import { VideoUnavailable } from "../../../../assets/svgs/videoUnavailable.svg"
import { handleAmazonProductRedirect, transformString } from "../../../../helpers/util.helper"

export const ProductListingData = (props: IProductListingData, { image }: { image: string }) => {
    const { selectedProductView, selectedDropdownList } = useSelector(ProductStateSelector)
    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")
    const [isSquare, setIsSquare] = useState<boolean>(true)

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == selectedTrackpackID
    )
    const dispatch = useDispatch()

    const handleProductRedirect = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
        e.stopPropagation()
        handleAmazonProductRedirect(selectedObject?.metadata?.amazon_tld, id)
    }

    useEffect(() => {
        const img = new Image()
        img.src = image

        img.onload = () => {
            // Check if the image is square or not
            setIsSquare(img.naturalWidth === img.naturalHeight)
        }
    }, [image])
    const noData = useMemo(() => {
        return props.product.success.every((v) => v.value === false)
    }, [props.product])

    const items = useMemo(() => {
        const views: {
            build: React.ReactElement
            icon: React.ReactElement
            type: string
            time?: String
        }[] = []
        const pd = props.product

        function getUniqueValues(pastArray1: IProductDataVideo[], presentArray2: IProductDataVideo[]) {
            let pastArray = pastArray1 ?? []
            let presentArray = presentArray2 ?? []
            if (!pastArray || !presentArray) {
                return { uniqueInPast: [], uniqueInPresent: [] }
            }
            const uniqueInPast =
                pastArray &&
                pastArray?.filter(
                    (pastItem) => !presentArray.some((presentItem) => presentItem?.video_url === pastItem?.video_url)
                )

            const uniqueInPresent =
                presentArray &&
                presentArray?.filter(
                    (presentItem) => !pastArray.some((pastItem) => pastItem.video_url === presentItem.video_url)
                )
            const maxLength = Math.max(uniqueInPast.length, uniqueInPresent.length)
            const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(null))
            const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(null))
            return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
        }

        function getUniqueValuesUrl(
            pastObject: Record<string, { url: string; thumbnail_filename: string; type?: string; title?: string }>,
            presentObject: Record<string, { url: string; thumbnail_filename: string; type?: string; title?: string }>
        ) {
            let uniqueInPresent: Record<string, (typeof presentObject)[keyof typeof presentObject]> = {}

            for (const key in presentObject) {
                if (presentObject.hasOwnProperty(key)) {
                    const presentItem = presentObject?.[key]
                    const pastItem = pastObject?.[key]

                    if (!pastItem) {
                        uniqueInPresent = { ...presentObject }
                        break
                    } else {
                        const isEqual =
                            pastItem?.url === presentItem?.url &&
                            pastItem?.thumbnail_filename === presentItem?.thumbnail_filename &&
                            pastItem?.type === presentItem?.type &&
                            pastItem?.title === presentItem?.title

                        if (!isEqual) {
                            uniqueInPresent = { ...presentObject }
                            break
                        }
                    }
                }
            }

            return { uniqueInPresent }
        }

        function getUniqueValuesCarousel(pastArray: string[] | undefined, presentArray: string[] | undefined) {
            if (!pastArray || !presentArray) {
                return { uniqueInPast: [], uniqueInPresent: [] }
            }

            const uniqueInPast = pastArray.filter((pastItem, index) => pastItem !== presentArray[index])
            const uniqueInPresent = presentArray.filter((presentItem, index) => presentItem !== pastArray[index])

            const maxLength = Math.max(uniqueInPast.length, uniqueInPresent.length)
            const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(null))
            const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(null))
            return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
        }

        const getView = (past: IProductHistoryBase<any>, present: IProductHistoryBase<any>, view: ProductViewType) => {
            let uniqueInPast: any = []
            let uniqueInPresent: any = []

            if (view === "videos") {
                ;({ uniqueInPast, uniqueInPresent } = getUniqueValues(past?.value, present?.value))
            } else if (view === "main_video") {
                ;({ uniqueInPresent } = getUniqueValuesUrl(past?.value, present?.value))
            } else if (view === "carousel_images") {
                ;({ uniqueInPast, uniqueInPresent } = getUniqueValuesCarousel(past?.value, present?.value))
            }
            switch (view) {
                case "price":
                    return {
                        build: (
                            <>
                                {/* {past && (
                                    <div className={"flex gap-[8px] items-center"}>
                                        <span className="text-[12px] font-[300] text-[#344054]">${past?.value}</span>
                                        <Arrow color={props.color.default} />
                                    </div>
                                )} */}

                                <div className={`${past && "ml-[0px] "}flex items-center pt-[6px] pb-[4px]`}>
                                    <span className="text-[12px] font-[300] text-[#344054] ">${present?.value}</span>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <CurrencyDollarIcon color={props.color?.active} />,
                    }
                case "main_image":
                    return {
                        build: (
                            <>
                                {/* {past && (
                                    <div className={"flex gap-[8px] items-center  pb-[5px] cursor-pointer"}>
                                        <img
                                            onClick={() => {
                                                dispatch(
                                                    RuntimeActionCreators.openModal({
                                                        color: props?.color,
                                                        open: true,
                                                        type: "main_image",
                                                        data: props?.product,
                                                    })
                                                )
                                            }}
                                            src={`${past?.value}`}
                                            alt={""}
                                            className={"h-[68px] w-[68px] rounded"}
                                            style={{
                                                border: `1px solid ${props.color.default}`,
                                            }}
                                        />
                                        <Arrow color={props.color.active} />
                                    </div>
                                )} */}

                                <div className="flex flex-col cursor-pointer">
                                    <img
                                        // onClick={() => {
                                        //     dispatch(
                                        //         RuntimeActionCreators.openModal({
                                        //             color: props?.color,
                                        //             open: true,
                                        //             type: "main_image",
                                        //             data: props?.product,
                                        //         })
                                        //     )
                                        // }}
                                        src={`${present.value}`}
                                        alt={""}
                                        className={`h-[68px] w-[68px] rounded object-contain bg-gray-800 ${
                                            past && " "
                                        }`}
                                        style={{
                                            border: `1px solid ${props.color.default}`,
                                        }}
                                    />
                                </div>
                            </>
                        ),
                        icon: <LogMainImageIcon color={props.color?.active} />,
                    }
                case "carousel_images":
                    return {
                        build: (
                            <>
                                {
                                    <div className="flex items-center gap-[10px]">
                                        {typeof props.product.isFirst === "boolean" && props.product.isFirst
                                            ? props.product.carousel_images.map((imageArray, index) => (
                                                  <Fragment key={index}>
                                                      {imageArray.value.map((image, imgIndex) => (
                                                          <div key={imgIndex} className="flex gap-[10px] items-center">
                                                              <div
                                                                  className="relative h-[60px] flex items-center gap-[8px] cursor-pointer"
                                                                  style={{
                                                                      width: isSquare ? "60px" : "43px", // Adjust thumbnail width dynamically
                                                                  }}
                                                              >
                                                                  <img
                                                                      src={image}
                                                                      className={`rounded ${
                                                                          isSquare
                                                                              ? "aspect-square"
                                                                              : "aspect-w-16 aspect-h-9"
                                                                      } h-full w-full bg-white overflow-hidden`}
                                                                      style={{
                                                                          border: `1px solid ${props.color.default}`,
                                                                      }}
                                                                      alt=""
                                                                  />
                                                              </div>
                                                          </div>
                                                      ))}
                                                  </Fragment>
                                              ))
                                            : [...Array(Math.max(uniqueInPast?.length, uniqueInPresent?.length))]?.map(
                                                  (_, index) => (
                                                      <Fragment key={index}>
                                                          <div className="flex gap-[10px] items-center">
                                                              {/* {uniqueInPast[index] ? (
                                                                  <div className="relative h-[76px] w-[76px] flex items-center gap-[8px] cursor-pointer">
                                                                      <img
                                                                          src={uniqueInPast[index]}
                                                                          className="rounded aspect-w-16 aspect-h-9 h-full w-full object-contain bg-white overflow-hidden"
                                                                          style={{
                                                                              border: `1px solid ${props.color.default}`,
                                                                          }}
                                                                          onClick={() => {
                                                                              dispatch(
                                                                                  RuntimeActionCreators.openModal({
                                                                                      color: props?.color,
                                                                                      open: true,
                                                                                      type: "carousal",
                                                                                      data: props?.product,
                                                                                  })
                                                                              )
                                                                          }}
                                                                          alt=""
                                                                      />
                                                                  </div>
                                                              ) : (
                                                                  <div
                                                                      className="relative h-[76px] w-[76px] flex items-center gap-[8px] rounded-[4px]"
                                                                      style={{
                                                                          border: `1px solid ${props.color.default}`,
                                                                      }}
                                                                  ></div>
                                                              )} */}

                                                              {/* <Arrow color={props.color.default} className="mx-auto" /> */}

                                                              {uniqueInPresent[index] ? (
                                                                  <div
                                                                      className="relative flex items-center gap-[8px] cursor-pointer"
                                                                      style={{
                                                                          width: isSquare ? "60px" : "43px", // Adjust thumbnail width dynamically
                                                                      }}
                                                                  >
                                                                      <img
                                                                          src={uniqueInPresent[index]}
                                                                          className={`rounded ${
                                                                              isSquare
                                                                                  ? "aspect-square"
                                                                                  : "aspect-w-16 aspect-h-9"
                                                                          } h-full w-full bg-white overflow-hidden`}
                                                                          style={{
                                                                              border: `1px solid ${props.color.default}`,
                                                                          }}
                                                                          alt=""
                                                                      />
                                                                  </div>
                                                              ) : (
                                                                  <></>
                                                                  //   <div
                                                                  //       className="relative h-[76px] w-[76px] flex items-center gap-[8px] rounded-[4px]"
                                                                  //       style={{
                                                                  //           border: `1px solid ${props.color.default}`,
                                                                  //       }}
                                                                  //   ></div>
                                                              )}
                                                          </div>
                                                      </Fragment>
                                                  )
                                              )}
                                    </div>
                                }
                            </>
                        ),
                        icon: <LogCarusolIcon color={props.color?.active} />,
                    }
                case "title":
                    return {
                        build: (
                            <div className="">
                                {/* {present && past ? (
                                    <>
                                        <StringDiffElement pastValue={past?.value} presentValue={present?.value} />
                                        <br />
                                    </>
                                ) : ( */}
                                <PrimaryText weight="light" size="xs" className="">
                                    {present?.value}
                                </PrimaryText>
                                {/* )} */}
                            </div>
                        ),
                        icon: <LogTitleIcon color={props.color?.active} />,
                    }
                case "videos":
                    return {
                        build: (
                            <div className={"flex flex-col gap-[10px]"}>
                                {typeof props.product.isFirst === "boolean" && props.product.isFirst
                                    ? props.product.videos.map((videoArray, index) => {
                                          return (
                                              <Fragment key={index}>
                                                  {videoArray.value.map((image, imgIndex) => {
                                                      return (
                                                          <div key={imgIndex} className="flex gap-[8px]">
                                                              <div className="relative h-[56px] w-[100px] flex items-center gap-[8px] cursor-pointer">
                                                                  <img
                                                                      src={image.thumbnail_url}
                                                                      className="rounded aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                      style={{
                                                                          border: `1px solid ${props.color.default}`,
                                                                      }}
                                                                      //   onClick={() => {
                                                                      //       dispatch(
                                                                      //           RuntimeActionCreators.openModal({
                                                                      //               color: props?.color,
                                                                      //               open: true,
                                                                      //               type: "video",
                                                                      //               data: props?.product,
                                                                      //           })
                                                                      //       )
                                                                      //   }}
                                                                      alt=""
                                                                  />
                                                                  <div className="absolute bottom-[1px] left-[1px] cursor-pointer">
                                                                      <SmallPlayVideo />
                                                                  </div>
                                                              </div>
                                                              <div className="flex flex-col  justify-between mt-[6px] mb-[4px]">
                                                                  <PrimaryText
                                                                      weight="medium"
                                                                      size="xs-medium"
                                                                      className="mb-[18px] text-gray-700 leading-[14px] w-[270px]  line-clamp-1 overflow-hidden text-ellipsis"
                                                                  >
                                                                      {image.title}
                                                                  </PrimaryText>
                                                                  <PrimaryText
                                                                      weight="medium"
                                                                      size="xs-extra-small"
                                                                      className={`text-[${
                                                                          props.color.default
                                                                      }] inline-block w-max rounded-[3px] leading-[12px] py-[1px] px-[4px] 
                                                                      ${
                                                                          image.type
                                                                              ? `bg-[${props.color.bgHundred}]`
                                                                              : ""
                                                                      }
                                                                      `}
                                                                  >
                                                                      {transformString(image.type)}
                                                                  </PrimaryText>
                                                              </div>
                                                          </div>
                                                      )
                                                  })}
                                              </Fragment>
                                          )
                                      })
                                    : [...Array(Math.max(uniqueInPast?.length, uniqueInPresent?.length))]?.map(
                                          (_, index) => {
                                              return (
                                                  <Fragment key={index}>
                                                      <div className="flex gap-[8px]">
                                                          {uniqueInPresent[index] ? (
                                                              <>
                                                                  <div className="relative h-[56px] w-[100px] flex items-center gap-[8px] cursor-pointer">
                                                                      <img
                                                                          src={uniqueInPresent[index]?.thumbnail_url}
                                                                          className="rounded-[3px] aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                                          style={{
                                                                              border: `0.8px solid ${props.color.default}`,
                                                                          }}
                                                                          //   onClick={() => {
                                                                          //       dispatch(
                                                                          //           RuntimeActionCreators.openModal({
                                                                          //               color: props?.color,
                                                                          //               open: true,
                                                                          //               type: "video",
                                                                          //               data: props?.product,
                                                                          //           })
                                                                          //       )
                                                                          //   }}
                                                                          alt=""
                                                                      />
                                                                      <div className="absolute bottom-[1px] left-[1px] cursor-pointer">
                                                                          <SmallPlayVideo />
                                                                      </div>
                                                                  </div>
                                                                  <div className="flex flex-col  justify-between mt-[6px] mb-[4px]">
                                                                      <PrimaryText
                                                                          weight="medium"
                                                                          size="xs-medium"
                                                                          className="mb-[18px] text-gray-700 leading-[14px] w-[270px]  line-clamp-1 overflow-hidden text-ellipsis"
                                                                      >
                                                                          {uniqueInPresent[index]?.title}
                                                                      </PrimaryText>
                                                                      <PrimaryText
                                                                          weight="medium"
                                                                          size="xs-extra-small"
                                                                          className={`text-[${
                                                                              props.color.default
                                                                          }] inline-block w-max  rounded-[3px] leading-[12px] py-[1px] px-[4px] 
                                                                           ${
                                                                               uniqueInPresent[index]?.type
                                                                                   ? `bg-[${props.color.bgHundred}]`
                                                                                   : ""
                                                                           }
                                                                          `}
                                                                      >
                                                                          {transformString(
                                                                              uniqueInPresent[index]?.type
                                                                          )}
                                                                      </PrimaryText>
                                                                  </div>
                                                              </>
                                                          ) : (
                                                              <div
                                                                  className="relative h-[56px] w-[100px] flex items-center gap-[8px] rounded-[4px] bg-gray-900 justify-center "
                                                                  style={{
                                                                      border: `0.8px solid `,
                                                                  }}
                                                              >
                                                                  <VideoUnavailable />
                                                              </div>
                                                          )}
                                                      </div>
                                                  </Fragment>
                                              )
                                          }
                                      )}
                            </div>
                        ),
                        icon: <ProductVideosIcon color={props.color?.active} />,
                    }
                case "main_video":
                    return {
                        build: (
                            <div className={"flex flex-col gap-[10px]"}>
                                {
                                    typeof props.product.isFirst === "boolean" && props.product.isFirst ? (
                                        props.product.main_video.map((mainVideo, index) => {
                                            return (
                                                <div key={index} className="flex gap-[8px]">
                                                    <div className="relative h-[56px] w-[100px] flex items-center gap-[8px] cursor-pointer">
                                                        <img
                                                            src={mainVideo.value.thumbnail_filename ?? ""}
                                                            className="rounded aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                            style={{
                                                                border: `1px solid ${props.color.default}`,
                                                            }}
                                                            // onClick={() => {
                                                            //     dispatch(
                                                            //         RuntimeActionCreators.openModal({
                                                            //             color: props?.color,
                                                            //             open: true,
                                                            //             type: "video",
                                                            //             data: props?.product,
                                                            //         })
                                                            //     )
                                                            // }}
                                                            alt=""
                                                        />
                                                        <div className="absolute bottom-[1px] left-[1px] cursor-pointer">
                                                            <SmallPlayVideo />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col  justify-between mt-[6px] mb-[4px]">
                                                        <PrimaryText
                                                            weight="medium"
                                                            size="xs-medium"
                                                            className="mb-[18px] text-gray-700 leading-[14px] w-[270px]  line-clamp-1 overflow-hidden text-ellipsis"
                                                        >
                                                            {mainVideo.value.title}
                                                        </PrimaryText>
                                                        <PrimaryText
                                                            weight="medium"
                                                            size="xs-extra-small"
                                                            className={`text-[${
                                                                props.color.default
                                                            }] inline-block w-max  rounded-[3px] leading-[12px] py-[1px] px-[4px] 
                                                            ${
                                                                mainVideo.value.type
                                                                    ? `bg-[${props.color.bgHundred}]`
                                                                    : ""
                                                            }
                                                            `}
                                                        >
                                                            {transformString(mainVideo.value.type)}
                                                        </PrimaryText>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        // [...Array(Math.max(uniqueInPresent?.length))]?.map((_, index) => {
                                        //     return (
                                        <Fragment>
                                            <div className="flex gap-[8px]">
                                                <div className="relative h-[56px] w-[100px] flex items-center gap-[8px] cursor-pointer">
                                                    <img
                                                        src={uniqueInPresent?.thumbnail_filename}
                                                        className="rounded-[3px] aspect-w-16 aspect-h-9 h-full w-full object-cover overflow-hidden"
                                                        style={{
                                                            border: `2px solid ${props.color.default}`,
                                                        }}
                                                        // onClick={() => {
                                                        //     dispatch(
                                                        //         RuntimeActionCreators.openModal({
                                                        //             color: props?.color,
                                                        //             open: true,
                                                        //             type: "video",
                                                        //             data: props?.product,
                                                        //         })
                                                        //     )
                                                        // }}
                                                        alt=""
                                                    />
                                                    <div className="absolute bottom-[1px] left-[1px] cursor-pointer">
                                                        <SmallPlayVideo />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col  justify-between mt-[6px] mb-[4px]">
                                                    <PrimaryText
                                                        weight="medium"
                                                        size="xs-medium"
                                                        className="mb-[18px] text-gray-700 leading-[14px] w-[270px]  line-clamp-1 overflow-hidden text-ellipsis"
                                                    >
                                                        {uniqueInPresent?.title}
                                                    </PrimaryText>
                                                    <PrimaryText
                                                        weight="medium"
                                                        size="xs-extra-small"
                                                        className={`text-[${
                                                            props.color.default
                                                        }] inline-block w-max rounded-[3px] leading-[12px] py-[1px] px-[4px] 
                                                         ${uniqueInPresent?.type ? `bg-[${props.color.bgHundred}]` : ""}
                                                        `}
                                                    >
                                                        {transformString(uniqueInPresent?.type)}
                                                    </PrimaryText>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                    //     )
                                    // })}
                                }
                            </div>
                        ),
                        icon: <LogVideoIcon color={props.color?.active} />,
                    }
                case "bullets":
                    return {
                        build: (() => {
                            const pastLength = past?.value?.length || 0
                            const presentLength = present?.value?.length || 0
                            const itemsToMap = pastLength >= presentLength ? past?.value : present?.value

                            return (
                                <div className="mb-[4px]">
                                    <ul className="">
                                        {itemsToMap?.map((b: string, index: number) => (
                                            <li key={index} className="flex items-start mb-[2px]">
                                                <span className="mr-2 text-[16px]">&#8226;</span>
                                                {/* {past && present ? (
                                                    <StringDiffElement
                                                        pastValue={past?.value[index]}
                                                        presentValue={present?.value[index]}
                                                    />
                                                ) : ( */}
                                                <>{b}</>
                                                {/* )} */}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        })(),
                        icon: <LogBulletsIcon color={props.color?.active} />,
                    }
                case "description":
                    return {
                        build: (
                            <div className="">
                                {/* {present && past ? (
                                    <>
                                        <StringDiffElement pastValue={past?.value} presentValue={present?.value} />{" "}
                                        <br />
                                    </>
                                ) : ( */}
                                <PrimaryText weight="light" size="xs">
                                    {present?.value}
                                </PrimaryText>
                                {/* )} */}
                            </div>
                        ),
                        icon: <LogDescriptionIcon color={props.color?.active} />,
                    }
                case "actual_asin":
                    return {
                        build: (
                            <>
                                <div className={`flex items-center py-[6px]`}>
                                    <div className="flex gap-[8px] items-center  text-[12px] font-[300] text-[#344054]">
                                        ASIN provided
                                        <div
                                            onClick={(e) => handleProductRedirect(e, present?.value)}
                                            className={` border cursor-pointer px-1 py-[1.5px] rounded-[3px] justify-start items-center inline-flex`}
                                            style={{ borderColor: props.color.default }}
                                        >
                                            <div className=" items-center gap-[3px] flex">
                                                <PrimaryText
                                                    weight="medium"
                                                    size="xs-medium"
                                                    className={`text-[${props.color.default}] tracking-[0.7px] leading-[11px]`}
                                                >
                                                    {present?.value ?? "-"}
                                                </PrimaryText>
                                                {<AsinLinkExternalSvg color={props.color?.default} />}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <LogAsinChangeIcon color={props.color?.active} />,
                    }
                case "seller_info":
                    return {
                        build: (
                            <>
                                {/* {past && (
                                    <div className={"flex gap-[8px] items-center"}>
                                        <span className="text-[12px] font-[300] text-[#344054]">
                                            {past?.value?.name ?? "-"}
                                        </span>
                                        <Arrow color={props.color.default} />
                                    </div>
                                )} */}
                                <div className={`${past && "ml-[0px] "} py-[6px] flex items-center`}>
                                    <span className="text-[12px] font-[300] text-[#344054]">
                                        {present?.value?.name ?? "-"}
                                    </span>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <LogSellerInfoIcon color={props.color?.active} />,
                    }
                case "availability":
                    return {
                        build: (
                            <>
                                {/* {past && (
                                    <div className={"flex gap-[8px] items-center"}>
                                        <span className="text-[12px] font-[300] text-[#344054]">
                                            {past?.value ?? "-"}
                                        </span>
                                        <Arrow color={props.color.default} />
                                    </div>
                                )} */}
                                <div className={`${past && "ml-[0px]"} py-[6px] flex items-center`}>
                                    <span className="text-[12px] font-[300] text-[#344054]">
                                        {present?.value ?? "-"}
                                    </span>
                                    <br />
                                </div>
                            </>
                        ),
                        icon: <TooltipStockIcon color={props.color?.active} />,
                    }
            }
            return null
        }

        selectedProductView?.selectedViews.forEach((viewItem) => {
            let view: ProductViewType = viewItem
            if (viewItem === "stock") {
                view = "availability"
            }

            // @ts-ignore
            const item = pd[view] as IProductHistoryBase<any>[]

            if (item) {
                for (let index = item.length - 1; index > -1; index--) {
                    const present = item[index]
                    const past = item[index - 1]

                    if (item.length > 1 && past == null) {
                        continue
                    }

                    const createdView = getView(past, present, view)

                    if (createdView)
                        views.push({
                            build: createdView.build,
                            icon: createdView.icon,
                            type: view,
                            time: dayjs(present?.timestamp).format("HH:mm [GMT]"),
                        })
                }
            }
        })

        return views
    }, [props.product, props.color, selectedProductView?.selectedViews, dispatch])

    return (
        <>
            {noData && (
                <div
                    className={`w-full border-b border-[#EEE] bg-[#F9FAFB] justify-start items-start inline-flex py-2`}
                >
                    <div className={"min-w-[108px] pl-[36px]"}>
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            color="text-slate-700"
                            className="text-left w-[104px]"
                        >
                            {dayjs(props.product.timestamp).format("HH:mm [GMT]")}
                        </PrimaryText>
                    </div>

                    <div className="min-w-[34.5px] flex-col justify-center items-start inline-flex"></div>
                    <>
                        <div className="w-[100px] justify-start items-center inline-flex ">
                            <div
                                className={`h-[20px] w-[20px] p-[2px]  flex justify-center items-center rounded-[4px]`}
                            >
                                <AlertIcon />
                            </div>
                        </div>

                        <div className="w-[396px] mt-[2px] grow overflow-auto justify-self-star items-start inline-flex text-xs font-light text-gray-700">
                            Data unavailable – updates to follow
                        </div>
                    </>
                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="text-center text-slate-700 self-stretch"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-center items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>

                    <div className="w-[90px] flex-col justify-start items-start inline-flex">
                        <PrimaryText
                            size={"xs"}
                            weight={"light"}
                            className="self-stretch text-center text-slate-700"
                        ></PrimaryText>
                    </div>
                </div>
            )}
            {items.map((view, index) => {
                return (
                    <div
                        key={index}
                        className={`w-full border-b border-l border-r border-[#EEE] bg-[#F9FAFB] justify-start ${
                            view.type === "bullets" || view.type === "description" || view.type === "main_image"
                                ? "items-start"
                                : "items-start"
                        } inline-flex py-[12px]`}
                    >
                        <div className={"min-w-[116px] pl-[36px]"}>
                            <PrimaryText
                                size={"xs"}
                                weight={"light"}
                                color="text-slate-700"
                                className="text-left w-[104px] pt-[6px] pb-[4px]"
                            >
                                {!props.isLast && items?.length > 1 && view.time}
                            </PrimaryText>
                        </div>

                        {/* <div className="min-w-[37px] flex-col justify-center items-start inline-flex"></div> */}
                        <>
                            <div className="w-[72px] justify-start items-center inline-flex">
                                <div
                                    className={`h-[28px] p-[4px] flex justify-center items-center rounded-[7px]`}
                                    style={{
                                        backgroundColor: `${props.color.default}`,
                                    }}
                                >
                                    <div
                                        className={`h-[20px] w-[20px] p-[2px]  flex justify-center items-center rounded-[4px]`}
                                    >
                                        {view.icon}
                                    </div>
                                </div>
                            </div>

                            <div
                                className={`
                                        ${
                                            !props.isLast &&
                                            items?.length > 1 &&
                                            (view.type === "videos" ||
                                                view.type === "main_video" ||
                                                view.type === "carousel_images" ||
                                                view.type === "main_image" ||
                                                view.type === "bullets" ||
                                                view.type === "description" ||
                                                view.type === "title")
                                                ? "py-[6px]"
                                                : "py-0"
                                        }
                                    w-[396px] grow overflow-auto justify-self-star items-start inline-flex text-xs font-light text-gray-700`}
                            >
                                {view.build}
                            </div>
                        </>
                        <div className="w-[90px] flex-col justify-center items-start inline-flex">
                            <PrimaryText
                                size={"xs"}
                                weight={"light"}
                                className="text-center text-slate-700 self-stretch"
                            ></PrimaryText>
                        </div>

                        <div className="w-[90px] flex-col justify-center items-start inline-flex">
                            <PrimaryText
                                size={"xs"}
                                weight={"light"}
                                className="self-stretch text-center text-slate-700"
                            ></PrimaryText>
                        </div>

                        <div className="w-[90px] flex-col justify-center items-start inline-flex">
                            <PrimaryText
                                size={"xs"}
                                weight={"light"}
                                className="self-stretch text-center text-slate-700"
                            ></PrimaryText>
                        </div>

                        <div className="w-[90px] flex-col justify-start items-start inline-flex">
                            <PrimaryText
                                size={"xs"}
                                weight={"light"}
                                className="self-stretch text-center text-slate-700"
                            ></PrimaryText>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
