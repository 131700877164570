import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ArchiveIcon } from "../../../../assets/svgs/archiveIcon.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import useGetExtendedTrackpacks from "../../../../hooks/reactQueryHooks.hook"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { Root } from "../../../../services/product.service"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"

const ArchiveRestoreSearchpackStepOne: React.FC = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList, selectedAsinsLength, userActionData } = useSelector(AccountStateSelector)
    const { loading, archiveRestoreSearchpackModal } = useSelector(RunTimeStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const isTrackpackLoading: boolean = useMemo(() => RuntimeHelper.archiveTrackpackLoading(), [loading])
    const isAsinLoading: boolean = useMemo(() => RuntimeHelper.archiveAsinLoading(), [loading])
    const trackpackID = localStorage.getItem("selectedTrackpackID")

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeArchiveRestoreSearchpackModal())
    }

    const { refetch } = useGetExtendedTrackpacks({}, {})

    const handleOpenStepTwo = () => {
        if (selectedAsinsLength && selectedAsinsLength?.data > 0) {
            dispatch(
                ProductsActionCreator.archiveAsins(
                    {
                        trackpack_id: selectedTrackpacksList.id,
                        asins: selectedAsinsLength?.selectedRows,
                    },
                    (data: Root) => {
                        refetch()

                        localStorage.setItem(
                            "archivedTrackpackName",
                            selectedTrackpacksList && selectedTrackpacksList?.title
                        )
                        if (selectedTrackpacksList.id == trackpackID) {
                            localStorage.removeItem("selectedTrackpackID")
                        }
                        dispatch(
                            ProductsActionCreator.selectedProducts({
                                selectedProducts: [],
                            })
                        )
                        dispatch(
                            RuntimeActionCreators.openArchiveModal({
                                open: true,
                                stepNumber: "two",
                                isArchiveShow: selectedAsinsLength && selectedAsinsLength?.data > 0 ? true : false,
                            })
                        )
                    }
                )
            )
        } else {
            dispatch(
                ProductsActionCreator.archiveTrackpack(
                    {
                        trackpack_id: selectedTrackpacksList.id,
                    },
                    (data: Root) => {
                        updateDropdownList(data)
                        localStorage.setItem(
                            "archivedTrackpackName",
                            selectedTrackpacksList && selectedTrackpacksList?.title
                        )
                        if (selectedTrackpacksList.id == trackpackID) {
                            localStorage.removeItem("selectedTrackpackID")
                        }
                        dispatch(
                            ProductsActionCreator.selectedProducts({
                                selectedProducts: [],
                            })
                        )
                        dispatch(
                            RuntimeActionCreators.openArchiveModal({
                                open: true,
                                stepNumber: "two",
                                isArchiveShow: selectedAsinsLength && selectedAsinsLength?.data > 0 ? true : false,
                            })
                        )
                    }
                )
            )
        }
    }

    const updateDropdownList = (updatedData: Root) => {
        const updatedDropdownList = selectedDropdownList?.trackpacks?.map((item: TrackpackDatum) =>
            item.metadata.id === selectedTrackpacksList.id ? updatedData : item
        )
        dispatch(ProductsActionCreator.setDropdownList(updatedDropdownList))
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#FECDCA] rounded-[12px] p-[10px]">
                    <ArchiveIcon />
                </div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            {archiveRestoreSearchpackModal?.isSearchpack && archiveRestoreSearchpackModal?.isKeyword ? (
                <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[16px]">
                    Archive. Keyword
                </PrimaryText>
            ) : archiveRestoreSearchpackModal?.isSearchpack ? (
                <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[16px]">
                    Archive. Searchpack
                </PrimaryText>
            ) : archiveRestoreSearchpackModal?.isRestore && archiveRestoreSearchpackModal?.isKeyword ? (
                <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[16px]">
                    Restore Keyword
                </PrimaryText>
            ) : archiveRestoreSearchpackModal?.isRestore ? (
                <PrimaryText weight="medium" className="text-[24px] text-gray-700 mt-[24px] mb-[16px]">
                    Restore Searchpack
                </PrimaryText>
            ) : (
                ""
            )}
            {archiveRestoreSearchpackModal?.isSearchpack && archiveRestoreSearchpackModal?.isKeyword ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                    Looks like you want to Archive your Search Term called&nbsp;
                    <PrimaryText size="sm-medium" weight="medium" className="inline text-gray-700">
                        {selectedTrackpacksList && selectedTrackpacksList?.title}.
                    </PrimaryText>
                    &nbsp;Is this correct?
                </PrimaryText>
            ) : archiveRestoreSearchpackModal?.isSearchpack ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                    Looks like you want to Archive your entire Searchpack called&nbsp;
                    <PrimaryText size="sm-medium" weight="medium" className="inline text-gray-700">
                        {selectedTrackpacksList && selectedTrackpacksList?.title}.
                    </PrimaryText>
                    <span className="font-normal text-gray-700 block mt-[15px]"> Are you sure?</span>
                </PrimaryText>
            ) : archiveRestoreSearchpackModal?.isRestore && archiveRestoreSearchpackModal?.isKeyword ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                    Looks like you want to Restore your Search Term called
                    <PrimaryText size="sm-medium" weight="medium" className="inline text-gray-700">
                        {selectedTrackpacksList && selectedTrackpacksList?.title}.
                    </PrimaryText>
                    &nbsp;Is this right?
                </PrimaryText>
            ) : archiveRestoreSearchpackModal?.isRestore ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                    Looks like you want to Restore your Searchpack called
                    <br />
                    <PrimaryText size="sm-medium" weight="medium" className="inline text-gray-700">
                        {selectedTrackpacksList && selectedTrackpacksList?.title}.
                    </PrimaryText>
                    <span className="font-normal text-gray-700"> Is this correct?</span>
                </PrimaryText>
            ) : (
                ""
            )}

            <>
                <div className="flex gap-2 items-center justify-center mt-[24px]">
                    <div className="flex justify-center items-center ">
                        <ButtonElement
                            onClick={handleOpenStepTwo}
                            size="large"
                            viewType="hover-state"
                            className={"w-[89px] bg-modal-btn-gradient  rounded-[12px] h-[44px] text-white text-[14px]"}
                            disabled={isAsinLoading || isTrackpackLoading}
                        >
                            Yes
                        </ButtonElement>
                    </div>
                    <div className="flex justify-center items-center">
                        <ButtonElement
                            onClick={handleCloseDialog}
                            size="large"
                            viewType="stay-same"
                            className={
                                "w-[84px] border  border-[#D0D5DD] bg-[#F9FAFB] rounded-[12px] h-[44px] text-[#667085] text-[14px] flex items-center justify-center "
                            }
                            disabled={isAsinLoading || isTrackpackLoading}
                        >
                            No
                        </ButtonElement>
                    </div>
                </div>
            </>
        </>
    )
}

export default ArchiveRestoreSearchpackStepOne
