import { UtilHelper } from "../../helpers/util.helper"
import { PrimaryText } from "../elements/primaryText.element"

import { EyeSvg } from "../../assets/svgs/eye.svg"
import { EyeOffSvg } from "../../assets/svgs/eyeOff.svg"

export interface Root {
    warnings: string
}

export const KeywordPerformanceSubCards = (props: IProductCard) => {
    const checkProductSelected = () => {
        return props.selectedProduct && props.selectedProduct.some((e) => e?.actual_asin === props.id)
    }

    return (
        <>
            <div
                data-tooltip-content="Hello world!"
                className={`relative w-[280px]  flex bg-white rounded-[10px] px-[8px] py-[8px] -outline-offset-1`}
                style={{ outline: `1px solid ${props.color && props.color.default}` }}
            >
                <div className={` w-full flex  sm:md:h-full cursor-pointer ${!checkProductSelected() ? "" : ""}`}>
                    <div
                        className={`w-[4px] h-[20px] rounded-[4px] bg-[${props.color && props.color.default}] mr-[8px]
                        ${!checkProductSelected() ? "opacity-20" : ""}
                        `}
                    ></div>
                    <div className="w-full flex flex-col justify-between">
                        <div className={`flex justify-between`}>
                            {props?.title && (
                                <PrimaryText
                                    weight={"medium"}
                                    className={`text-[12px] pt-[1px]  text-ellipsis overflow-hidden whitespace-nowrap line-clamp-1 min-h-[13px] text-gray-600 leading-[18px] ${
                                        !checkProductSelected() ? "opacity-20" : ""
                                    } `}
                                >
                                    {UtilHelper.truncateTitle(props?.title, 25)}
                                </PrimaryText>
                            )}
                            <div className={`flex ${props.currentIssue ? "gap-[4px]" : ""}`}>
                                {!checkProductSelected() ? (
                                    <div className="w-[16px] h-[16px]">
                                        <EyeOffSvg />
                                    </div>
                                ) : (
                                    <div className="w-[16px] h-[16px]">
                                        <EyeSvg />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
