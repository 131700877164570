import { all, call, fork, put, takeLatest } from "typed-redux-saga"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { SearchpackAction, SearchpackActionCreator } from "../actions/searchpack.action"
import { ASIN_PRODUCTS_COLOR } from "../config/colors.config"
import { mergeColorsWithData } from "../helpers/util.helper"
import { SearchpackService } from "../services/searchpack.service"

function* createSearchpack() {
    yield takeLatest(SearchpackAction.CREATE_SEARCHPACK_ACCOUNT, createSearchpackHandler)
}

function* createSearchpackHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createSearchpackHandler", !action.ignorePreloader))

        const result = yield* call(SearchpackService.createSearchpack, action.payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createSearchpackHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getSearchpacksList() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACKS_LIST, getSearchpacksListHandler)
}

function* getSearchpacksListHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpacksListHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getSearchpacksList, pathParams, queryParams)

        if (result) {
            yield put(SearchpackActionCreator.setSearchpacksList(result as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpacksListHandler"))
        action.onFinally && action.onFinally()
    }
}

function* addKeywordsToSearchpack() {
    yield takeLatest(SearchpackAction.ADD_KEYWORDS_TO_SEARCHPACK, addKeywordToSearchpackHandler)
}

function* addKeywordToSearchpackHandler<T extends ISagaAction & { payload: Partial<ISelectedTrackpackPayload> }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("addKeywordToSearchpackHandler", !action.ignorePreloader))

        const { id, keywords } = action.payload
        const payload = {
            search_terms: keywords,
        }
        const result = yield* call(SearchpackService.addKeywordToSearchpack, id, payload)
        if (result) {
            action.onSuccess && action.onSuccess(result)
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError()
    } finally {
        yield put(RuntimeActionCreators.stopLoading("addKeywordToSearchpackHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getSearchpackWithLastDataTime() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_WITH_LAST_DATA_TIME, getSearchpackWithLastDataTimeHandler)
}

function* getSearchpackWithLastDataTimeHandler<T extends ISagaAction & { payload: { searchpackId: number } }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getSearchpackWithLastDataTimeHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(
            SearchpackService.getSearchpackWithLastDataTime,
            action.payload.searchpackId,
            pathParams,
            queryParams
        )
        if (result) {
            yield put(SearchpackActionCreator.setSearchpackLastDataTime(result as ISearchpackLastDataTime))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackWithLastDataTimeHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getRanksnapshotData() {
    yield takeLatest(SearchpackAction.GET_RANKSNAPSHOT_DATA, getRanksnapshotDataHandler)
}

function* getRanksnapshotDataHandler<T extends ISagaAction & { payload: { searchpackId: number; date: string } }>(
    action: T
) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("getRanksnapshotDataHandler", !action.ignorePreloader))
        const { searchpackId, date, pathParams, queryParams } = action.payload

        const result = yield* call(SearchpackService.getRanksnapshotData, searchpackId, date, pathParams, queryParams)
        if (result) {
            const enrichedData = mergeColorsWithData(result.search_card_data, ASIN_PRODUCTS_COLOR)
            const updatedResult = {
                ...result,
                search_card_data: enrichedData,
            }
            yield put(SearchpackActionCreator.setRanksnapshotData(updatedResult as any))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getRanksnapshotDataHandler"))

        action.onFinally && action.onFinally()
    }
}

function* getSearchpackWithTerm() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_WITHSEARCHTERM, getSearchpackWithTermHandler)
}
function* getSearchpackWithTermHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpackWithTermHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getSearchpacksWithTerm, pathParams, queryParams)

        if (result) {
            yield put(SearchpackActionCreator.setSelectedSearchPackTermData(result as IProductSearchpackWithTerm))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackWithTermHandler"))
        action.onFinally && action.onFinally()
    }
}

function* getSearchpackRankTracker() {
    yield takeLatest(SearchpackAction.GET_SEARCHPACK_RANK_TRACKER, getSearchpackRankTrackerHandler)
}
function* getSearchpackRankTrackerHandler<T extends ISagaAction & { payload: { packTermId: number } }>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getSearchpackRankTrackerHandler", !action.ignorePreloader))
        const { packTermId, pathParams, queryParams } = action.payload
        const result = yield* call(SearchpackService.getSearchpackTermTracker, packTermId, pathParams, queryParams)

        if (result) {
            const enrichedData = mergeColorsWithData(result?.search_card_data_with_latest_rank, ASIN_PRODUCTS_COLOR)
            const updatedResult = {
                ...result,
                search_card_data_with_latest_rank: enrichedData,
            }
            yield put(SearchpackActionCreator.setSearchpackRankTracker(updatedResult as any))
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getSearchpackRankTrackerHandler"))
        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([
        fork(createSearchpack),
        fork(getSearchpacksList),
        fork(addKeywordsToSearchpack),
        fork(getSearchpackWithLastDataTime),
        fork(getRanksnapshotData),
        fork(getSearchpackWithTerm),
        fork(getSearchpackRankTracker),
    ])
}
