import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"

import { ProductsActionCreator } from "../../actions/products.action"
import { ProductStateSelector } from "../../selectors/product.selector"
import { ProductCard } from "../common/productCard.component"
import { PrimaryText } from "../elements/primaryText.element"
import CommonTooltip from "./tooltipItems/commonTooltip.component"

import { useNavigate } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { ReactComponent as EditIcon } from "../../assets/svgs/edit-icon.svg"
import { ReactComponent as AddIcon } from "../../assets/svgs/new-add-icon.svg"
import { ReactComponent as RatingIcon } from "../../assets/svgs/rating-icon.svg"
import { img_url } from "../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { NotificationStateSelector } from "../../selectors/notificationStateSelector"
import { RunTimeStateSelector } from "../../selectors/RunTimeStateSelector"
import { ProductDaum } from "../../services/product.service"
import CardSkeletonLoader from "../common/skeletonLoader/cardSkeleton.component"
import { ButtonElement } from "../elements/button.element"
import CardCustomTooltipContent from "./tooltip-alerts/cardTooltipAlerts.component"

export const AsinContrastCard = (props: IAsinContrastCard) => {
    const navigate = useNavigate()
    const sidebarRef = useRef<HTMLDivElement>(null)
    const [showBorder, setShowBorder] = useState(false)
    const {
        selectedGroupProducts,
        selectedProducts,
        selectedOption,
        selectedDropdownList,
        selectedTrackpackData,
        selectedTrackpackValues,
    } = useSelector(ProductStateSelector)

    // const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading = useMemo(() => RuntimeHelper.productLoading(), [loading])
    const isTrackPackLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])

    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState<IGroupProductData[]>([])
    const { notifiSelection } = useSelector(NotificationStateSelector)

    const [selectedAsins, setSelectedAsins] = useState(() => {
        const item = localStorage.getItem("notifiAsin")
        return item ? JSON.parse(item) : []
    })
    // function haveSameElements(arr1: string[], arr2: string[]) {
    //     const set1 = new Set(arr1)
    //     const set2 = new Set(arr2)
    //     if (set1.size !== set2.size) {
    //         return false
    //     }
    //     return [...set1].every((element) => set2.has(element))
    // }

    const selectedTrackpackID = selectedTrackpackValues?.selectedTrackpackID

    useEffect(() => {
        if (notifiSelection && notifiSelection?.notifiAsin) {
            setSelectedAsins(notifiSelection.notifiAsin ?? [])
        } else {
            setSelectedAsins([])
        }
    }, [notifiSelection])

    useEffect(() => {
        if (isLoading) {
            dispatch(
                ProductsActionCreator.selectedProducts({
                    selectedProducts: [],
                })
            )
        }
        if (selectedGroupProducts && !isLoading) {
            setTimeout(() => {
                localStorage.removeItem("notifiAsin")
            }, 3000)
            const allTrackings = selectedTrackpackData?.trackings?.map((tracking: any) => tracking)

            const allAwaitingInitialData = allTrackings?.every((tracking: { status: string }) => {
                return tracking.status === "AWAITING_INITIAL_DATA" || tracking.status === "ARCHIVED"
            })

            if (!allAwaitingInitialData) {
                let firstNonAwaitingProduct: any =
                    selectedGroupProducts?.groupProductData?.[selectedTrackpackID!]?.find(
                        (product) => product.status !== "AWAITING_INITIAL_DATA" && product.status !== "ARCHIVED"
                    ) || selectedGroupProducts?.groupProductData?.[selectedTrackpackID!]?.[0]

                if (selectedOption === "Log") {
                    const selectedFirstProduct = selectedProducts?.selectedProducts?.[0] ?? firstNonAwaitingProduct
                    dispatch(ProductsActionCreator.selectedLogProduct(selectedFirstProduct))
                    setSelectedProduct([selectedFirstProduct])
                } else {
                    if (selectedAsins) {
                        let asinsArray: string[] = []
                        selectedGroupProducts?.groupProductData?.[selectedTrackpackID!]?.forEach((asin: any) => {
                            if (selectedAsins.includes(asin.actual_asin)) {
                                asinsArray.push(asin)
                            }
                        })
                        if (asinsArray?.length > 0) {
                            firstNonAwaitingProduct = asinsArray
                        }
                    }
                    const initialSelectedProduct =
                        selectedProducts &&
                        selectedProducts?.selectedProducts &&
                        selectedProducts?.selectedProducts?.length > 0
                            ? selectedProducts?.selectedProducts
                            : Array.isArray(firstNonAwaitingProduct)
                            ? firstNonAwaitingProduct
                            : firstNonAwaitingProduct
                            ? [firstNonAwaitingProduct]
                            : undefined
                    if (initialSelectedProduct) {
                        setSelectedProduct(initialSelectedProduct)
                        dispatch(
                            ProductsActionCreator.selectedProducts({
                                selectedProducts: initialSelectedProduct,
                            })
                        )
                    }
                }
            }
        }
    }, [selectedGroupProducts, selectedAsins, selectedTrackpackID!, isLoading, props.multipleProductSelected])

    useEffect(() => {
        if (selectedProducts?.selectedProducts && selectedProducts?.selectedProducts?.length !== 0 && selectedAsins) {
            let asinsArray: IGroupProductData[] = []
            selectedGroupProducts?.groupProductData?.[selectedTrackpackID!]?.forEach((asin: any) => {
                if (selectedAsins.includes(asin.actual_asin)) {
                    asinsArray.push(asin)
                }
            })
            if (asinsArray?.length > 0) {
                setSelectedProduct(asinsArray)
                dispatch(
                    ProductsActionCreator.selectedProducts({
                        selectedProducts: asinsArray,
                    })
                )
            }
        }
    }, [selectedAsins, selectedTrackpackID])

    const SelectedProductFunc = (item: any) => {
        const selectedTrackpackAsinsData = selectedGroupProducts?.groupProductData?.[item?.metadata?.id]
        const selectedAsinData = selectedTrackpackAsinsData?.find(
            (trackpackItem: any) => trackpackItem.actual_asin === item.asin
        )
        const isSelectedIndex = selectedProduct.findIndex((e) => e?.actual_asin === item?.asin)
        let updatedProducts: IGroupProductData[]

        if (props.multipleProductSelected) {
            updatedProducts =
                isSelectedIndex !== -1
                    ? selectedProduct.filter((e) => e?.actual_asin !== item?.asin)
                    : [...selectedProduct, selectedAsinData!]
        } else {
            updatedProducts = isSelectedIndex !== -1 ? [] : [selectedAsinData!]
        }

        setSelectedProduct(updatedProducts)
        if (selectedOption === "Log") {
            dispatch(ProductsActionCreator.selectedLogProduct(updatedProducts?.[0]))
        } else {
            dispatch(ProductsActionCreator.selectedProducts({ selectedProducts: [...updatedProducts] }))
        }
        // for close tooltip when select another
        dispatch(RuntimeActionCreators.closeAllPopup())
    }

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                setShowBorder(sidebarRef.current.scrollTop > 0)
            }
        }

        const currentSidebarRef = sidebarRef.current
        if (currentSidebarRef) {
            currentSidebarRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentSidebarRef) {
                currentSidebarRef.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])
    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAsinAddtionalModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const trackingsStatuses = selectedTrackpackData?.trackings
        .filter(
            (tracking: { status: string }) =>
                tracking.status !== "ARCHIVED" && tracking.status !== "ARCHIVED_DURING_PACK_ARCHIVAL"
        )
        .map(
            // @ts-ignore
            (tracking: {
                status: string
                asin: string
                own_product?: boolean
                latest_data: LatestData
                metadata: Metadata
                current_issue?: string
                color: TAsinProductColor
            }) => ({
                status: tracking.status,
                asin: tracking.asin,
                own_product: tracking.own_product,
                latest_data: tracking.latest_data,
                metadata: selectedTrackpackData?.metadata,
                currentIssue: tracking.current_issue,
                color: tracking.color,
            })
        )

    const trackingsStatusesMap = useMemo(() => {
        const map = new Map()
        trackingsStatuses?.forEach((trackingStatus: { asin: any }) => {
            map.set(trackingStatus.asin, trackingStatus)
        })
        return map
    }, [trackingsStatuses])

    const sortedTrackingsStatuses = useMemo(() => {
        const sorted =
            selectedGroupProducts?.groupProductData?.[1!]
                ?.filter((product) => trackingsStatusesMap.has(product.actual_asin))
                ?.map((product) => trackingsStatusesMap.get(product.actual_asin)) || []
        const remainingTrackingsStatuses =
            trackingsStatuses?.filter(
                (tracking: { asin: string }) =>
                    !selectedGroupProducts?.groupProductData?.[1!]?.some(
                        (product) => product.actual_asin === tracking.asin
                    )
            ) || []

        return [...sorted, ...remainingTrackingsStatuses]
    }, [selectedGroupProducts, trackingsStatusesMap, trackingsStatuses])

    useEffect(() => {
        if (sortedTrackingsStatuses.length > 0) {
            const asinsArray = sortedTrackingsStatuses.slice(1).map((item) => item.asin)
            dispatch(
                ProductsActionCreator.setSelectedTrackPackValues({
                    selectedTrackpackID: selectedTrackpackID,
                    defaultSelectedAsin: sortedTrackingsStatuses?.[0]?.asin,
                    sortedAsins: asinsArray,
                })
            )
        }
    }, [selectedTrackpackID, selectedAsins])

    let isStatic = true

    return (
        <div className="w-full">
            <div className="relative bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col pb-0">
                <div
                    className={`w-full flex justify-between gap-[4px] items-center pb-[12px]  ${
                        showBorder ? "border-b border-gray-200 pb-[14px]" : ""
                    }`}
                >
                    <div className=" flex gap-[6px] items-center">
                        <PrimaryText size="md" weight={"medium"} className={"text-[#088AB2] pl-[8px]"}>
                            ASINs
                        </PrimaryText>
                        {/* <InfoLabel /> */}

                        {/* <ShowInfo className={"flex items-center w-[36px] h-[36px]"} /> */}
                    </div>
                    <div className="flex gap-[8px]">
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            className="cursor-pointer outline-none"
                            data-tooltip-place="bottom"
                            data-tooltip-class-name={"!ml-[32px]"}
                            data-tooltip-offset={-23}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className=" mt-[13px]" label="Settings" />
                            )}
                        >
                            <ButtonElement
                                onClick={() => {
                                    if (selectedTrackpackData?.metadata?.id) {
                                        localStorage.setItem(
                                            "trackpackHubId",
                                            String(selectedTrackpackData.metadata.id)
                                        )
                                        navigate(ROUTES_CONFIG.trackpackCentral)
                                    }
                                }}
                                viewType="only-icon"
                                className="rounded-[10px] bg-[#ECFDFF] hover:bg-cyan-100 p-[10px] gradient-border z-10"
                            >
                                <EditIcon />
                            </ButtonElement>
                        </div>
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-class-name={"!ml-[34px]"}
                            data-tooltip-offset={-13}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip
                                    className=" w-auto  flex-col justify-start items-start inline-flex"
                                    label="Add ASINs"
                                />
                            )}
                        >
                            <ButtonElement
                                onClick={handleOpen}
                                viewType="only-icon"
                                className="
                          rounded-[10px]
                          bg-gradient-to-br from-cyan-100 to-cyan-200
                          disabled:bg-gray-100
                          hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                          active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400
                          p-[10px]

                          "
                            >
                                <AddIcon className={"cursor-pointer outline-none"} />
                            </ButtonElement>
                        </div>
                    </div>
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isTrackPackLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full" style={{ height: "calc(100vh - 358px)" }}>
                        {isTrackPackLoading ? (
                            <CardSkeletonLoader length={12} isTrackpack={false} />
                        ) : sortedTrackingsStatuses?.length ? (
                            sortedTrackingsStatuses?.map(
                                (
                                    trackingStatus: {
                                        asin: string
                                        own_product?: boolean
                                        status?: string
                                        latest_data: ProductDaum
                                        metadata: Metadata
                                        currentIssue?: string
                                        color: TAsinProductColor
                                    },
                                    index: number | null | undefined
                                ) => {
                                    // const data = selectedGroupProducts?.groupProductData?.[selectedTrackpackID!]?.find(
                                    //     (product) => product.actual_asin === trackingStatus.asin
                                    // )
                                    let status = trackingStatus.status
                                    // let status = "AWAITING_INITIAL_DATA"
                                    if (status === "ACTIVE") {
                                        const amazon_tld = trackingStatus?.metadata?.amazon_tld
                                        // const temp = data?.main_product_data
                                        // const lastProductData = data.product_data[data.product_data.length - 1]
                                        // const firstProduct = data.product_data?.[0]
                                        // const isNotTracking = lastProductData.success.every((el) => el.value === false)
                                        // const isWarnings = isNotTracking
                                        //     ? ["success"]
                                        //     : [
                                        //           ...(Array.from(new Set(firstProduct?.warnings)) ?? []),
                                        //           ...(lastProductData.warnings ?? []),
                                        //       ]

                                        return (
                                            <div
                                                key={index}
                                                onClick={() => SelectedProductFunc(trackingStatus)}
                                                className="last:pb-[12px]"
                                            >
                                                <ProductCard
                                                    amazon_tld={amazon_tld}
                                                    id={trackingStatus?.asin}
                                                    // isAsinAltered={!!data.new_actual_asin.includes(",")}
                                                    src={
                                                        trackingStatus.latest_data.main_image_filename &&
                                                        `${img_url}${trackingStatus.latest_data.main_image_filename}`
                                                    }
                                                    altText={"Asin dress"}
                                                    title={trackingStatus.latest_data.title ?? ""}
                                                    price={trackingStatus.latest_data.price ?? 0}
                                                    rating={trackingStatus.latest_data.rating ?? 0}
                                                    ratingIcon={<RatingIcon />}
                                                    ratingsCount={trackingStatus.latest_data.ratings_total ?? 0}
                                                    // reviewer={temp?.reviewer || ""}
                                                    color={trackingStatus?.color}
                                                    currency={trackingStatus.latest_data.currency ?? "USD"}
                                                    selectedProduct={selectedProduct}
                                                    // @ts-ignore
                                                    // warnings={isWarnings}
                                                    ownProduct={trackingStatus.own_product}
                                                    metadata={trackingStatus.metadata}
                                                    currentIssue={trackingStatus.currentIssue}
                                                />
                                            </div>
                                        )
                                    } else if (status === "AWAITING_INITIAL_DATA") {
                                        return (
                                            <div key={index} className="last:pb-[12px]">
                                                <CardSkeletonLoader
                                                    key={index}
                                                    trackingAsin={trackingStatus.asin}
                                                    ownProduct={trackingStatus.own_product}
                                                    isTrackpack={false}
                                                />
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                }
                            )
                        ) : (
                            <div
                                className={`last:pb-[12px] flex flex-col gap-[8px] ${
                                    isStatic ? "overflow-y-hidden" : "overflow-y-scroll"
                                } `}
                            >
                                <CardSkeletonLoader length={12} isTrackpack={false} isStatic />
                            </div>
                        )}
                    </div>

                    <Tooltip
                        id="tracking-off-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-off-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-error-card-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEE4E2",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow red"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-error-card-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-success-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#DCFAE6",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow green"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content)
                            return <CardCustomTooltipContent id="tracking-success-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="status-warning-tooltip"
                        style={{
                            padding: "8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            display: "inline-block",
                            zIndex: 10000,
                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                        }}
                        clickable
                        place="top"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            return <CardCustomTooltipContent id="status-warning-tooltip" dataWarnings={content} />
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
