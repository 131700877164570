export const RankSnapShotIcon = (props: IPropsIcon) => {
    return (
        <>
            <svg
                width={props.width ?? 32}
                height={props.height ?? 32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.66663 11.1697C2.66663 10.7026 2.66663 10.4691 2.68612 10.2724C2.87409 8.37511 4.37498 6.87422 6.27224 6.68624C6.46895 6.66675 6.7151 6.66675 7.2074 6.66675C7.3971 6.66675 7.49194 6.66675 7.57247 6.66187C8.60077 6.59959 9.50123 5.95058 9.88549 4.99475C9.91558 4.91989 9.94371 4.83551 9.99996 4.66675C10.0562 4.49799 10.0843 4.4136 10.1144 4.33875C10.4987 3.38291 11.3991 2.7339 12.4274 2.67163C12.508 2.66675 12.5969 2.66675 12.7748 2.66675H19.2251C19.403 2.66675 19.4919 2.66675 19.5725 2.67163C20.6008 2.7339 21.5012 3.38291 21.8855 4.33875C21.9156 4.4136 21.9437 4.49799 22 4.66675C22.0562 4.83551 22.0843 4.91989 22.1144 4.99475C22.4987 5.95058 23.3991 6.59959 24.4274 6.66187C24.508 6.66675 24.6028 6.66675 24.7925 6.66675C25.2848 6.66675 25.531 6.66675 25.7277 6.68624C27.6249 6.87422 29.1258 8.37511 29.3138 10.2724C29.3333 10.4691 29.3333 10.7026 29.3333 11.1697V21.6001C29.3333 23.8403 29.3333 24.9604 28.8973 25.816C28.5138 26.5687 27.9019 27.1806 27.1493 27.5641C26.2936 28.0001 25.1735 28.0001 22.9333 28.0001H9.06663C6.82642 28.0001 5.70631 28.0001 4.85066 27.5641C4.09802 27.1806 3.48609 26.5687 3.1026 25.816C2.66663 24.9604 2.66663 23.8403 2.66663 21.6001V11.1697Z"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16 22.0001C18.9455 22.0001 21.3333 19.6123 21.3333 16.6667C21.3333 13.7212 18.9455 11.3334 16 11.3334C13.0544 11.3334 10.6666 13.7212 10.6666 16.6667C10.6666 19.6123 13.0544 22.0001 16 22.0001Z"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
