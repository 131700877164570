import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ReactComponent as LeftScrollArrow } from "../../../assets/svgs/leftScrollArrow.svg"
import { ReactComponent as RightScrollArrow } from "../../../assets/svgs/rightScrollArrow.svg"
import DatePickerComponent from "../../common/datePicker.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

import { ASIN_AMAZON_LINK_BASE } from "../../../config/routes.config"

import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { NewPlusIcon } from "../../../assets/svgs/plusIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import RankSnapshotSkeletonComponent from "../../common/skeletonLoader/rankSnapShotSkeleton.component"
import SearchpackTrackerOnboarding from "../../common/skeletonLoader/searchpackTrackerOnboarding.component"

const RankSnapshot = (props: ITableData) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const trackRef = useRef<HTMLDivElement>(null)
    const isDragging = useRef(false)
    const startDragX = useRef(0)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)
    const [thumbPosition, setThumbPosition] = useState(0)
    const [thumbWidth, setThumbWidth] = useState(0)
    const [disabledFilter, setDisabledFilter] = useState<"Paid" | "Organic" | null>(null)

    const dispatch = useDispatch()
    const { control } = useForm()
    const { selectedSearchpackLastDataTime, selectedSearchpackValues, ranksnapshotData, selectedSearchpackProducts } =
        useSelector(SearchpackStateSelector)
    const [selectedDate, setSelectedDate] = useState<string | null>(null)
    const { loading } = useSelector(RunTimeStateSelector)
    const isGetRankSnapshotDataLoading: boolean = useMemo(() => RuntimeHelper.getRanksnapshotDataLoading(), [loading])

    const amazonTld = selectedSearchpackLastDataTime?.amazon_tld ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld)

    const isDisabled =
        selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.search_data_last_available_at === null

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth, scrollLeft } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
            }
        }

        const parent = parentRef.current
        if (parent) {
            updateScrollState()
            parent.addEventListener("scroll", updateScrollState)
        }

        window.addEventListener("resize", updateScrollState)

        return () => {
            if (parent) parent.removeEventListener("scroll", updateScrollState)
            window.removeEventListener("resize", updateScrollState)
        }
    }, [selectedSearchpackProducts?.selectedProducts])

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((parent.scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(parent.scrollLeft > 0)
                setCanScrollRight(parent.scrollLeft + clientWidth < scrollWidth)
            }
        }

        const observer = new MutationObserver(updateScrollState)
        if (parentRef.current) {
            observer.observe(parentRef.current, { childList: true, subtree: true })
        }

        updateScrollState() // Initial calculation

        return () => observer.disconnect()
    }, [selectedSearchpackProducts?.selectedProducts])

    const handleScroll = useCallback(
        (direction: "left" | "right") => {
            const parent = parentRef.current
            if (parent) {
                const { clientWidth } = parent
                const scrollAmount = clientWidth / 2
                const newScrollLeft =
                    direction === "left"
                        ? Math.max(parent.scrollLeft - scrollAmount, 0)
                        : Math.min(parent.scrollLeft + scrollAmount, parent.scrollWidth - clientWidth)

                parent.scrollTo({ left: newScrollLeft, behavior: "smooth" })
            }
        },
        [selectedSearchpackProducts?.selectedProducts]
    )

    const handleThumbDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        isDragging.current = true
        startDragX.current = "touches" in e ? e.touches[0].clientX : e.clientX
    }

    const handleThumbDragMove = (e: MouseEvent | TouchEvent) => {
        if (!isDragging.current) return

        const parent = parentRef.current
        const track = trackRef.current

        if (parent && track) {
            const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
            const deltaX = currentX - startDragX.current
            startDragX.current = currentX

            const trackWidth = track.clientWidth
            const { scrollWidth, clientWidth } = parent

            const newThumbPosition = Math.min(Math.max(thumbPosition + deltaX, 0), trackWidth - thumbWidth)

            setThumbPosition(newThumbPosition)

            const newScrollLeft = (newThumbPosition / trackWidth) * scrollWidth
            parent.scrollTo({ left: newScrollLeft })
        }
    }

    const handleThumbDragEnd = () => {
        isDragging.current = false
    }

    useEffect(() => {
        if (isDragging.current) {
            document.addEventListener("mousemove", handleThumbDragMove)
            document.addEventListener("touchmove", handleThumbDragMove)
            document.addEventListener("mouseup", handleThumbDragEnd)
            document.addEventListener("touchend", handleThumbDragEnd)
        }

        return () => {
            document.removeEventListener("mousemove", handleThumbDragMove)
            document.removeEventListener("touchmove", handleThumbDragMove)
            document.removeEventListener("mouseup", handleThumbDragEnd)
            document.removeEventListener("touchend", handleThumbDragEnd)
        }
    }, [thumbPosition, selectedSearchpackProducts?.selectedProducts])

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    useEffect(() => {
        if (selectedSearchpackValues && selectedSearchpackValues.selectedSearchpackID) {
            const dateToUse = selectedDate || dayjs().format("YYYY-MM-DD")
            dispatch(
                SearchpackActionCreator.getRanksnapshotData(selectedSearchpackValues.selectedSearchpackID, dateToUse)
            )
        }
    }, [selectedSearchpackValues, selectedDate])

    const handleDateChange = (date: Date | null) => {
        if (date) {
            const formattedDate = dayjs(date).format("YYYY-MM-DD")
            setSelectedDate(formattedDate)
        } else {
            console.warn("No date selected")
        }
    }
    const filteredRankData = useMemo(() => {
        if (ranksnapshotData) {
            const selectedAsins =
                selectedSearchpackProducts?.selectedProducts?.map((product: any) => product.asin) || []
            return ranksnapshotData?.search_rank_data_by_term?.filter((term: any) =>
                term.rank_data.some((rank: any) => selectedAsins.includes(rank.asin))
            )
        }
        return []
    }, [selectedSearchpackProducts, ranksnapshotData])

    const selectedSearchpackKeywordsData = useMemo(() => {
        if (filteredRankData) {
            return filteredRankData.map(({ term, rank_data }: any) => {
                const selectedAsins =
                    selectedSearchpackProducts?.selectedProducts?.map((product: any) => product.asin) || []

                const asinRanks = selectedAsins.map((asin: any) => {
                    const paidRank =
                        disabledFilter !== "Paid"
                            ? rank_data.find((rank: any) => rank.asin === asin && rank.is_sponsored)?.rank || "-"
                            : null

                    const organicRank =
                        disabledFilter !== "Organic"
                            ? rank_data.find((rank: any) => rank.asin === asin && !rank.is_sponsored)?.rank || "-"
                            : null

                    return {
                        asin,
                        paid: paidRank,
                        organic: organicRank,
                    }
                })

                return {
                    label: term,
                    asinRanks,
                }
            })
        }
        return []
    }, [filteredRankData, disabledFilter, selectedSearchpackProducts])

    const handleFilterClick = (filter: "Paid" | "Organic") => {
        setDisabledFilter((currentFilter) => (currentFilter === filter ? null : filter))
    }

    return (
        <div>
            {selectedSearchpackLastDataTime &&
                selectedSearchpackLastDataTime?.search_data_last_available_at === null && (
                    <SearchpackTrackerOnboarding />
                )}
            <div className={"flex justify-between flex-wrap mb-[12px] w-full"}>
                <div className={"flex gap-[12px] flex-col xl:flex-row lg:flex-row md:flex-col"}>
                    <div
                        className="cursor-pointer outline-none"
                        // data-tooltip-id="dashboard-tooltip"
                        // data-tooltip-place="bottom"
                        // data-tooltip-offset={-10}
                        // data-tooltip-class-name={"!ml-[70x]"}
                        // data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                        //     <CommonTooltip className="" label="Add Keyword" />
                        // )}
                    >
                        <ButtonElement
                            viewType={
                                isDisabled || isGetRankSnapshotDataLoading ? "is-loading-button" : "gradient-button"
                            }
                            onClick={handleOpen}
                            disabled={isDisabled || isGetRankSnapshotDataLoading}
                            size="medium"
                            className="px-[12px] py-[8px] rounded-[10px] inline-flex h-[36px] items-center"
                            parentClass="justify-start items-center gap-2 inline-flex  "
                            textClass={`  text-[${
                                isDisabled || isGetRankSnapshotDataLoading ? "#D0D5DD" : "#088AB2"
                            }] flex item-center gap-[8px]`}
                        >
                            <NewPlusIcon color={isDisabled || isGetRankSnapshotDataLoading ? "#D0D5DD" : "#088AB2"} />
                            Keywords
                        </ButtonElement>
                    </div>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-11}
                        data-tooltip-class-name={"!ml-[75px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter Keywords" />
                        )}
                        className={`cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px] ${
                            isDisabled || isGetRankSnapshotDataLoading
                                ? "opacity-[0.4] cursor-not-allowed pointer-events-none"
                                : ""
                        } `}
                    >
                        <PrimaryText
                            onClick={() => handleFilterClick("Paid")}
                            className={`text-gray-700 leading-[18px] cursor-pointer ${
                                disabledFilter === "Paid" ? "text-gray-200" : ""
                            }`}
                            size="small"
                            weight="light"
                        >
                            Paid
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => handleFilterClick("Organic")}
                            className={`text-gray-700 leading-[18px] cursor-pointer ${
                                disabledFilter === "Organic" ? "text-gray-200" : ""
                            }`}
                            size="small"
                            weight="light"
                        >
                            Organic
                        </PrimaryText>
                    </div>
                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[88px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Select Date" />
                        )}
                    >
                        <DatePickerComponent
                            className={` ${
                                isDisabled || isGetRankSnapshotDataLoading
                                    ? "cursor-not-allowed pointer-events-none opacity-[0.3] !text-gray-200"
                                    : "cursor-pointer"
                            } `}
                            name="date"
                            disabled={false}
                            control={control}
                            selectedSearchpackLastDataTime={selectedSearchpackLastDataTime}
                            onDateChange={handleDateChange}
                        />
                    </div>
                </div>
            </div>

            <div className=" rounded-lg">
                <div
                    className={"overflow-auto top-scroll -mr-[28px]"}
                    onScroll={() => {
                        dispatch(RuntimeActionCreators.closeAllPopup())
                    }}
                >
                    <div
                        className=""
                        style={{
                            maxHeight: "calc(100vh - 295px)",
                            width: "calc(100% - 28px)",
                        }}
                    >
                        {ranksnapshotData && ranksnapshotData.search_rank_data_by_term.length === 0 ? (
                            <RankSnapshotSkeletonComponent />
                        ) : (
                            selectedSearchpackProducts?.selectedProducts &&
                            selectedSearchpackProducts?.selectedProducts.length > 0 && (
                                <>
                                    <div className="rounded-[10px] h-full  border border-gray-200 bg-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden ">
                                        <div className="flex items-center pt-[12px] gap-x-[32px] px-[24px] ">
                                            <div className="min-w-[256px]"></div>

                                            <div className="text-black flex items-center flex-1 gap-[10px]">
                                                {/* Left Arrow */}
                                                <button
                                                    onClick={() => handleScroll("left")}
                                                    disabled={!canScrollLeft}
                                                    className={`p-2 ${
                                                        !canScrollLeft
                                                            ? "opacity-50 cursor-not-allowed"
                                                            : "cursor-pointer"
                                                    }`}
                                                >
                                                    <LeftScrollArrow />
                                                </button>

                                                <div
                                                    ref={trackRef}
                                                    className="relative w-full bg-gray-200 h-[4px] my-[3px]"
                                                >
                                                    <div
                                                        className="bg-gray-300 h-[4px] absolute rounded-[8px] cursor-pointer"
                                                        style={{ width: `${thumbWidth}px`, left: `${thumbPosition}px` }}
                                                        onMouseDown={handleThumbDragStart}
                                                        onTouchStart={handleThumbDragStart}
                                                    ></div>
                                                </div>

                                                {/* Right Arrow */}
                                                <button
                                                    onClick={() => handleScroll("right")}
                                                    disabled={!canScrollRight}
                                                    className={`p-2 ${
                                                        !canScrollRight
                                                            ? "opacity-50 cursor-not-allowed"
                                                            : "cursor-pointer"
                                                    }`}
                                                >
                                                    <RightScrollArrow />
                                                </button>
                                            </div>
                                        </div>
                                        <div ref={parentRef} className={` scroll-hidden overflow-x-auto `}>
                                            <div className="bg-gray-200">
                                                <div
                                                    className={
                                                        "flex py-[16px] min-w-fit  rounded-tr-[8px] rounded-tl-[8px] border-b border-[#EEE] z-20 relative"
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            "py-[8px] pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[256px] mr-[32px]"
                                                        }
                                                    >
                                                        <PrimaryText
                                                            size={"small"}
                                                            weight={"medium"}
                                                            className={"w-[256px] text-gray-700 leading-[20px]"}
                                                        >
                                                            ASINs
                                                        </PrimaryText>
                                                    </div>
                                                    {selectedSearchpackProducts?.selectedProducts.map(
                                                        (data: any, index: number) => (
                                                            <Link to={url + data?.asin} target="_blank" key={index}>
                                                                <div className="min-w-[108px] py-[8px] flex flex-col items-center text-center">
                                                                    <img
                                                                        className="w-[68px] h-[68px] rounded-[6px] object-contain bg-white"
                                                                        style={{
                                                                            border: `1px solid ${data?.color?.default}`,
                                                                        }}
                                                                        src={
                                                                            data?.image_filename
                                                                                ? `${img_url}${data?.image_filename}`
                                                                                : ""
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </Link>
                                                        )
                                                    )}
                                                </div>

                                                {/* Sub-header for Keywords and PAID | ORG. */}
                                                <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                                    <div
                                                        className={
                                                            "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[254px] mr-[32px] justify-start"
                                                        }
                                                    >
                                                        <PrimaryText
                                                            size={"xs"}
                                                            weight={"medium"}
                                                            className={"text-gray-700 w-[254px]"}
                                                        >
                                                            Keywords
                                                        </PrimaryText>
                                                    </div>
                                                    {selectedSearchpackProducts?.selectedProducts.map(
                                                        (_: any, index: number) => (
                                                            <div
                                                                key={`paid-org-header-${index}`}
                                                                className="min-w-[108px] text-center py-[16px] flex items-center justify-center"
                                                            >
                                                                {disabledFilter === "Paid" ? (
                                                                    <div className=" inline-flex gap-[8px]">
                                                                        <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                            ORG.
                                                                        </span>
                                                                    </div>
                                                                ) : disabledFilter === "Organic" ? (
                                                                    <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                        PAID
                                                                    </span>
                                                                ) : (
                                                                    <div className=" inline-flex gap-[8px]">
                                                                        <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                            PAID
                                                                        </span>
                                                                        <span className="text-[8px] leading-[10px] font-400 text-gray-300">
                                                                            |
                                                                        </span>
                                                                        <span className="text-[8px] leading-[10px] font-400 min-w-[32px] text-gray-600">
                                                                            ORG.
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>

                                                {selectedSearchpackKeywordsData.map((item: any, rowIndex: any) => {
                                                    const { label, asinRanks } = item
                                                    return (
                                                        <div
                                                            key={`keyword-row-${rowIndex}`}
                                                            className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0 last:rounded-br-[8px] last:rounded-bl-[8px]"
                                                        >
                                                            <div
                                                                className={
                                                                    "py-[16px] pl-[24px] flex items-center bg-white sticky left-0 min-w-[254px] mr-[32px] justify-start"
                                                                }
                                                            >
                                                                <PrimaryText
                                                                    size={"xs"}
                                                                    weight={"light"}
                                                                    className={"text-gray-700 w-[254px]"}
                                                                >
                                                                    {label}
                                                                </PrimaryText>
                                                            </div>

                                                            {/* Render columns dynamically */}
                                                            {asinRanks.map((rank: any, index: number) => (
                                                                <div
                                                                    key={`paid-org-${rowIndex}-${index}`}
                                                                    className="min-w-[108px] flex flex-col items-center text-center py-[16px]"
                                                                >
                                                                    <div className="flex items-center gap-[8px]">
                                                                        {disabledFilter === "Paid" ? (
                                                                            <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                {rank.organic}
                                                                            </span>
                                                                        ) : disabledFilter === "Organic" ? (
                                                                            <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                {rank.paid}
                                                                            </span>
                                                                        ) : (
                                                                            <>
                                                                                <span className="text-xs leading-[16px] font-[300] text-gray-700 min-w-[32px]">
                                                                                    {rank.paid}
                                                                                </span>
                                                                                <span className="text-gray-200 text-xs font-300 leading-[16px]">
                                                                                    |
                                                                                </span>
                                                                                <span className="text-xs font-[300] text-gray-700 leading-[16px] min-w-[32px]">
                                                                                    {rank.organic}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                        <div style={{ height: "12px" }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RankSnapshot
