import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NotificationsActionsCreator } from "../actions/notifications.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { isTokenExpired } from "../helpers/util.helper"
import { AccountStateSelector } from "../selectors/accountState.selector"
import { NotificationStateSelector } from "../selectors/notificationStateSelector"

export const useNotificationServiceWorkerHook = () => {
    const token = localStorage.getItem("token")
    const { notificationsData } = useSelector(NotificationStateSelector)
    const { userActionData } = useSelector(AccountStateSelector)

    const lastNotifiID = localStorage.getItem("lastNotificationId")
    const trackpackID = localStorage.getItem("selectedTrackpackID")
    const productID = localStorage.getItem("product_id")

    const dispatch = useDispatch()

    useEffect(() => {
        const registerServiceWorker = async () => {
            if ("serviceWorker" in navigator) {
                try {
                    const registration = await navigator.serviceWorker.register("/service-worker.js")
                    if (registration.active) {
                        if (token && !isTokenExpired(token)) {
                            registration.active.postMessage({
                                type: "SET_API_URL",
                                apiUrl: process.env.REACT_APP_API_URL,
                            })
                            if (lastNotifiID) {
                                registration.active.postMessage({
                                    type: "SET_TOKEN",
                                    token: token,
                                })
                                registration.active.postMessage({
                                    type: "SET_LAST_ID",
                                    lastNotifiID: lastNotifiID,
                                })
                                if (userActionData && userActionData.id) {
                                    registration.active.postMessage({
                                        type: "SET_AS_USER",
                                        as_user: userActionData.id.toString(),
                                    })
                                } else {
                                    registration.active.postMessage({
                                        type: "SET_AS_USER",
                                        as_user: null,
                                    })
                                }
                            }
                        } else if (token === null) {
                            registration.active!.postMessage({
                                type: "LOGOUT",
                            })
                            unregisterServiceWorker()
                        }
                    }
                } catch (error) {
                    console.error("Service Worker registration failed:", error)
                }
            }
        }

        registerServiceWorker()

        const handleMessage = (event: any) => {
            if (event.data && event.data.type === "NEW_NOTIFICATIONS") {
                const mergeNotifications = (
                    existingData: any = { notifications: [] },
                    newData: any = { notifications: [] }
                ) => {
                    const newNotificationsList = newData.notifications || []
                    const existingNotifi = existingData.notifications || []
                    let data = structuredClone({
                        ...existingData,
                        notifications: [...existingNotifi, ...newNotificationsList],
                    })
                    return data
                }
                const updatedData = mergeNotifications(notificationsData, event.data.notifications)

                dispatch(NotificationsActionsCreator.setNotificationsData(updatedData))
            } else if (event.data.type === "NEW_PRODUCT_DATA") {
                if (event.data.productData?.is_new_data_available === true) {
                    dispatch(
                        RuntimeActionCreators.openNotificaitonAlert({
                            open: true,
                            notificationType: "new_Data",
                            duration: 30000,
                        })
                    )
                }
            }
            //  else if (event.data.type === "UNAUTHORIZED") {
            //     window.location.href = "/auth/welcome"
            // }
        }

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("message", handleMessage)
        }

        return () => {
            if (navigator.serviceWorker) {
                navigator.serviceWorker.removeEventListener("message", handleMessage)
            }
        }
    }, [notificationsData, token, lastNotifiID, trackpackID, productID, userActionData && userActionData.id])
}

export const unregisterServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.getRegistration()
            if (registration) {
                await registration.unregister()
                console.info("Service Worker unregistered successfully.")
            }
        } catch (error) {
            console.error("Error unregistering service worker:", error)
        }
    }
}
