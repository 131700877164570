import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { SearchTrackerIcon } from "../../../../assets/svgs/searchTracker.svg"
import { ThumbIcon } from "../../../../assets/svgs/thumbIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { SearchpackStateSelector } from "../../../../selectors/searchpack.selector"
import SearchpackStepOneView from "../addSearchpackModals/addSearchpackStepOneView.component"
import AddKeywordStepThreeView from "./addKeywordStepThreeView.component"
import AddKeywordStepTwoView from "./addKeywordStepTwoView.component"

const AddKeywordOutlet = () => {
    const { addKeywordsModal } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const [isAddKeywordError, setIsAddKeywordError] = useState(false)
    const [isAddKeywordSuccess, setIsAddKeywordSuccess] = useState(false)
    const [keywordsCount, setKeywordsCount] = useState(0)
    const { selectedSearchpackName } = useSelector(SearchpackStateSelector)

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeAddKeywordsModal())
        dispatch(
            ProductsActionCreator.selectedTrackpackPayload({
                name: "",
                amazon_tld: "",
                competitor_asins: [],
                own_asins: [],
                total_asins: [],
                selected_Asins: {},
            })
        )
        setIsAddKeywordError(false)
        setIsAddKeywordSuccess(false)
    }

    const handleErrorModal = () => {
        setIsAddKeywordError(true)
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "three",
            })
        )
    }
    const handleSuccessModal = (count: number) => {
        setIsAddKeywordSuccess(true)
        setKeywordsCount(count)
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "two",
            })
        )
    }

    const getModalBody = () => {
        if (isAddKeywordSuccess && addKeywordsModal?.stepNumber === "two") {
            return (
                <AddKeywordStepTwoView
                    icon={<ThumbIcon />}
                    handleCloseDialog={handleCloseDialog}
                    asinHeaderText="Nice work"
                    buttonText="Done"
                    searchpackName={selectedSearchpackName}
                    keywordCount={keywordsCount}
                />
            )
        }
        if (isAddKeywordError && addKeywordsModal?.stepNumber === "three") {
            return (
                <AddKeywordStepThreeView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openAddKeywordsModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Error adding new Keyword"
                    asinHeaderDescription="I couldn’t add new Keywords at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        switch (addKeywordsModal?.stepNumber) {
            case "one":
                return (
                    <SearchpackStepOneView
                        icon={<SearchTrackerIcon width={32} height={32} color={"#088AB2"} />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Add Keywords to your Searchpack"
                        buttonText="Next"
                        isAddtionalSteps={true}
                        asinHeaderDescription="description"
                        isError={handleErrorModal}
                        isSuccess={(count) => handleSuccessModal(count)}
                    />
                )
            default:
                return <></>
        }
    }
    return <>{addKeywordsModal?.open ? <>{getModalBody()}</> : null}</>
}

export default AddKeywordOutlet
