import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { keywordPerformanceData, selectedProducts } from "../../../config/dashboard.config"
import { PrimaryText } from "../../elements/primaryText.element"

import { ReactComponent as LeftScrollArrow } from "../../../assets/svgs/leftScrollArrow.svg"
import { ReactComponent as RightScrollArrow } from "../../../assets/svgs/rightScrollArrow.svg"
import { ASIN_AMAZON_LINK_BASE } from "../../../config/routes.config"
import { ProductStateSelector } from "../../../selectors/product.selector"

const KeywordPerformanceTable = () => {
    const parentRef = useRef<HTMLDivElement>(null)
    const trackRef = useRef<HTMLDivElement>(null)
    const isDragging = useRef(false)
    const startDragX = useRef(0)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)
    const [thumbPosition, setThumbPosition] = useState(0)
    const [thumbWidth, setThumbWidth] = useState(0)

    const dispatch = useDispatch()

    const { selectedProductRange, selectedDropdownList } = useSelector(ProductStateSelector)

    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == selectedTrackpackID
    )

    const amazonTld = selectedObject?.metadata?.amazon_tld ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld)

    useEffect(() => {
        const updateScrollState = () => {
            const parent = parentRef.current
            if (parent) {
                const { scrollWidth, clientWidth, scrollLeft } = parent
                const track = trackRef.current

                if (track) {
                    const thumbWidthRatio = clientWidth / scrollWidth
                    setThumbWidth(track.clientWidth * thumbWidthRatio)
                    setThumbPosition((scrollLeft / scrollWidth) * track.clientWidth)
                }

                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
            }
        }

        const parent = parentRef.current
        if (parent) {
            updateScrollState()
            parent.addEventListener("scroll", updateScrollState)
        }

        window.addEventListener("resize", updateScrollState)

        return () => {
            if (parent) parent.removeEventListener("scroll", updateScrollState)
            window.removeEventListener("resize", updateScrollState)
        }
    }, [])

    const handleScroll = (direction: "left" | "right") => {
        const parent = parentRef.current
        if (parent) {
            const { clientWidth } = parent
            const scrollAmount = clientWidth / 2
            const newScrollLeft =
                direction === "left"
                    ? Math.max(parent.scrollLeft - scrollAmount, 0)
                    : Math.min(parent.scrollLeft + scrollAmount, parent.scrollWidth - clientWidth)

            parent.scrollTo({ left: newScrollLeft, behavior: "smooth" })
        }
    }

    const handleThumbDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        isDragging.current = true
        startDragX.current = "touches" in e ? e.touches[0].clientX : e.clientX
    }

    const handleThumbDragMove = (e: MouseEvent | TouchEvent) => {
        if (!isDragging.current) return

        const parent = parentRef.current
        const track = trackRef.current

        if (parent && track) {
            const currentX = "touches" in e ? e.touches[0].clientX : e.clientX
            const deltaX = currentX - startDragX.current
            startDragX.current = currentX

            const trackWidth = track.clientWidth
            const { scrollWidth, clientWidth } = parent

            const newThumbPosition = Math.min(Math.max(thumbPosition + deltaX, 0), trackWidth - thumbWidth)

            setThumbPosition(newThumbPosition)

            const newScrollLeft = (newThumbPosition / trackWidth) * scrollWidth
            parent.scrollTo({ left: newScrollLeft })
        }
    }

    const handleThumbDragEnd = () => {
        isDragging.current = false
    }

    useEffect(() => {
        if (isDragging.current) {
            document.addEventListener("mousemove", handleThumbDragMove)
            document.addEventListener("touchmove", handleThumbDragMove)
            document.addEventListener("mouseup", handleThumbDragEnd)
            document.addEventListener("touchend", handleThumbDragEnd)
        }

        return () => {
            document.removeEventListener("mousemove", handleThumbDragMove)
            document.removeEventListener("touchmove", handleThumbDragMove)
            document.removeEventListener("mouseup", handleThumbDragEnd)
            document.removeEventListener("touchend", handleThumbDragEnd)
        }
    }, [thumbPosition])

    const [value, setValue] = useState(60) // Default progress value

    return (
        <div className=" rounded-lg">
            <div
                className={"overflow-auto top-scroll -mr-[28px]"}
                onScroll={() => {
                    dispatch(RuntimeActionCreators.closeAllPopup())
                }}
            >
                <div
                    className=""
                    style={{
                        maxHeight: "calc(100vh - 295px)",
                        width: "calc(100% - 28px)",
                    }}
                >
                    {selectedProducts?.selectedProducts && selectedProducts?.selectedProducts.length > 0 && (
                        <>
                            <div className="rounded-[10px] h-full  border border-gray-200 bg-gray-200 rounded-bl-[10px] rounded-br-[10px] overflow-hidden ">
                                <div className="flex items-center pt-[12px] gap-x-[32px] px-[24px] ">
                                    <div className="min-w-[256px]"></div>

                                    <div className="text-black flex items-center flex-1 gap-[10px]">
                                        {/* Left Arrow */}
                                        <button
                                            onClick={() => handleScroll("left")}
                                            disabled={!canScrollLeft}
                                            className={`p-2 ${
                                                !canScrollLeft ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                                            }`}
                                        >
                                            <LeftScrollArrow />
                                        </button>

                                        {/* Scrollbar Track */}
                                        <div ref={trackRef} className="relative w-full bg-gray-200 h-[4px] my-[3px]">
                                            <div
                                                className="bg-gray-300 h-[4px] absolute rounded-[8px] cursor-pointer"
                                                style={{ width: `${thumbWidth}px`, left: `${thumbPosition}px` }}
                                                onMouseDown={handleThumbDragStart}
                                                onTouchStart={handleThumbDragStart}
                                            ></div>
                                        </div>

                                        {/* Right Arrow */}
                                        <button
                                            onClick={() => handleScroll("right")}
                                            disabled={!canScrollRight}
                                            className={`p-2 ${
                                                !canScrollRight ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                                            }`}
                                        >
                                            <RightScrollArrow />
                                        </button>
                                    </div>
                                </div>
                                <div ref={parentRef} className={` scroll-hidden overflow-x-auto `}>
                                    <div className="bg-gray-200">
                                        <div
                                            className={
                                                "flex py-[16px] min-w-fit  rounded-tr-[8px] rounded-tl-[8px] border-b border-[#EEE] z-20 relative"
                                            }
                                        >
                                            <div
                                                className={
                                                    "py-[8px] pl-[24px] flex items-end sticky left-0 bg-gray-200 justify-start min-w-[352px]"
                                                }
                                            >
                                                <PrimaryText
                                                    size={"small"}
                                                    weight={"medium"}
                                                    className={"w-[352px] text-gray-700 leading-[20px]"}
                                                >
                                                    ASINs
                                                </PrimaryText>
                                            </div>
                                            {selectedProducts?.selectedProducts.map((data, index) => (
                                                <Link
                                                    to={url + data?.actual_asin}
                                                    target="_blank"
                                                    key={index}
                                                    className="block mx-[8px] last:mr-0 last:pr-[32px]"
                                                >
                                                    <div className=" py-[8px] flex flex-col items-center text-center ">
                                                        <div className="p-[10px] bg-white rounded-[6px] min-w-[64px]">
                                                            <PrimaryText
                                                                className="text-[#088AB2] uppercase mb-[2px] leading-[10px]"
                                                                size="xs-medium"
                                                                weight="book"
                                                            >
                                                                mon
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                className="text-gray-700 uppercase text-[36px] mb-[2px] leading-[36px]"
                                                                weight="light"
                                                            >
                                                                26
                                                            </PrimaryText>
                                                            <PrimaryText
                                                                className="text-gray-700 uppercase leading-[10px]"
                                                                size="xs-medium"
                                                                weight="book"
                                                            >
                                                                nov
                                                            </PrimaryText>
                                                        </div>
                                                        {/* <img
                                                            className="min-w-[64px] max-w-[64px] min-h-[80px] rounded-[6px] object-contain bg-white"
                                                            style={{
                                                                border: `1px solid ${data?.color?.default}`,
                                                            }}
                                                            src={
                                                                data?.main_product_data?.main_image
                                                                    ? data?.main_product_data.main_image
                                                                    : ""
                                                            }
                                                            alt=""
                                                        /> */}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>

                                        {/* Sub-header for Keywords and PAID | ORG. */}
                                        <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                            <div
                                                className={
                                                    "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[352px]  justify-start"
                                                }
                                            >
                                                <PrimaryText
                                                    size={"xs"}
                                                    weight={"medium"}
                                                    className={"text-gray-700 w-[352px]"}
                                                >
                                                    BSR L Cat.
                                                </PrimaryText>
                                            </div>
                                            {selectedProducts?.selectedProducts.map((_, index) => (
                                                <div
                                                    key={`paid-org-header-${index}`}
                                                    className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px]"
                                                >
                                                    <PrimaryText
                                                        weight="medium"
                                                        size="xs"
                                                        className="leading-[16px] text-gray-600 text-center "
                                                    >
                                                        47
                                                    </PrimaryText>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex border-b border-[#EEE] min-w-fit bg-gray-100 z-10 relative">
                                            <div
                                                className={
                                                    "py-[16px] bg-gray-100 pl-[24px] flex items-center sticky left-0 min-w-[352px] justify-start "
                                                }
                                            >
                                                <PrimaryText
                                                    size={"xs"}
                                                    weight={"medium"}
                                                    className={"text-gray-700 w-[352px]"}
                                                >
                                                    BSR S Cat.
                                                </PrimaryText>
                                            </div>
                                            {selectedProducts?.selectedProducts.map((_, index) => (
                                                <div
                                                    key={`paid-org-header-${index}`}
                                                    className="min-w-[80px] max-w-[80px] py-[16px] last:mr-[34px]"
                                                >
                                                    <PrimaryText
                                                        weight="medium"
                                                        size="xs"
                                                        className="leading-[16px] text-gray-600 text-center"
                                                    >
                                                        47
                                                    </PrimaryText>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Data Rows */}
                                        {keywordPerformanceData.map((item, rowIndex) => {
                                            const { label, value } = item
                                            return (
                                                <div
                                                    key={`keyword-row-${rowIndex}`}
                                                    className="flex border-b border-[#EEE] min-w-fit bg-white z-10 relative last:border-b-0 last:rounded-br-[8px] last:rounded-bl-[8px]"
                                                >
                                                    {/* Keywords Column */}
                                                    <div
                                                        className={
                                                            "py-[20px] pl-[24px] flex items-center bg-white sticky left-0 min-w-[352px]  justify-start"
                                                        }
                                                    >
                                                        <PrimaryText
                                                            size={"xs"}
                                                            weight={"light"}
                                                            className={"text-gray-700 w-[352px]"}
                                                        >
                                                            {label}
                                                        </PrimaryText>
                                                    </div>

                                                    {/* PAID | ORG. Columns */}
                                                    {selectedProducts?.selectedProducts.map((_, index) => (
                                                        <div
                                                            key={`paid-org-${rowIndex}-${index}`}
                                                            className="min-w-[64px] flex items-center h-[32px] mx-[8px]  justify-center rounded-[6px] bg-green-300 my-[12px] last:mr-[42px]"
                                                        >
                                                            <PrimaryText
                                                                weight="medium"
                                                                size="xs"
                                                                className="text-[#085D3A]"
                                                            >
                                                                {value}
                                                            </PrimaryText>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div style={{ height: "12px" }}></div>
                </div>
            </div>
            <div className="flex rounded-[10px] border border-[#EEE] bg-white relative items-center justify-between w-full">
                {/* Keywords Column */}
                <div className={"py-[16px] pl-[24px] flex items-center  min-w-[352px]  justify-start"}>
                    <PrimaryText size={"xs"} weight={"medium"} className={"text-gray-700 w-[352px]"}>
                        Keyword Strength
                    </PrimaryText>
                </div>

                <div className="py-[16px] flex-1">
                    <div className=" flex items-center ml-[16px] gap-x-[12px]">
                        {/* Gradient background */}
                        <PrimaryText size="xs" weight="medium" className="text-[#A48AFB]  leading-[16px]">
                            Very Low
                        </PrimaryText>
                        <div
                            className="h-[8px] min-w-[407px] rounded-[4px] "
                            style={{
                                background:
                                    "linear-gradient(90deg, #75E0A7 0%, #FDE272 25%, #F7B27A 50%, #FEA3B4 75%, #BDB4FE 100%)",
                            }}
                        ></div>
                        <PrimaryText size="xs" weight="medium" className="text-[#47CD89] leading-[16px]">
                            Very High
                        </PrimaryText>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeywordPerformanceTable
