import { useEffect, useMemo, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"

import { PrimaryText } from "../../elements/primaryText.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

import { SearchpackActionCreator } from "../../../actions/searchpack.action"
import { AddIcon } from "../../../assets/svgs/addIcon.svg"
import { GraphIcon } from "../../../assets/svgs/graphIcon.svg"
import { TableIcon } from "../../../assets/svgs/tableIcon.svg"
import { DashboardBoardConfig } from "../../../config/dashboard.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import { ButtonElement } from "../../elements/button.element"
import ButtonGroups from "../../elements/buttonGroup.element"
import { SelectElement } from "../../elements/select.element"
import { RankTrackerGraphView } from "./rankTrackerGraphView.component"
import RankTrackerTableView from "./rankTrackerTableView.component"

const RankTracker = (props: ITableData) => {
    const dispatch = useDispatch()
    const [disabledFilter, setDisabledFilter] = useState<"Paid" | "Organic" | null>(null)
    const [allTrackpacks, setAllTrackpacks] = useState<any>()
    const [defaultTrackpack, setDefaultTrackpack] = useState<string | undefined>()
    const { userActionData } = useSelector(AccountStateSelector)
    const [selectedIndex, setSelectedIndex] = useState<number>()

    const { control } = useForm()
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.searchpackListLoading(), [loading])
    const isGetRankTrackerDataLoading: boolean = useMemo(() => RuntimeHelper.getRanktrackerDataLoading(), [loading])
    const {
        selectedSearchpackLastDataTime,
        selectedSearchpackProducts,
        selectedSearchpackWithTerm,
        searchpackRankTracker,
    } = useSelector(SearchpackStateSelector)

    useEffect(() => {
        if (selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.id) {
            dispatch(SearchpackActionCreator.getSearchPackTermData())
        }
    }, [selectedSearchpackLastDataTime])

    const isDisabled =
        selectedSearchpackLastDataTime && selectedSearchpackLastDataTime?.search_data_last_available_at === null

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAddKeywordsModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const filteredTrackDropdownData = useMemo(() => {
        if (selectedSearchpackWithTerm) {
            const filteredSelectedSearchpackWithTerm = selectedSearchpackWithTerm?.searchpacks?.filter(
                (term: TrackpackDropdown) => term.id === selectedSearchpackLastDataTime?.id
            )
            return filteredSelectedSearchpackWithTerm
        }
        return []
    }, [selectedSearchpackProducts, selectedSearchpackWithTerm])

    const handleFilterClick = (filter: "Paid" | "Organic") => {
        setDisabledFilter((currentFilter) => (currentFilter === filter ? null : filter))
    }

    const [activeIcon, setActiveIcon] = useState<"table" | "graph">("table")

    const Options = [<TableIcon />, <GraphIcon />]

    const [selectedRange, setSelectedRange] = useState<number>(30)
    const { graphConfig } = DashboardBoardConfig

    useEffect(() => {
        if (isLoading) return
        if (filteredTrackDropdownData && filteredTrackDropdownData?.length > 0) {
            const transformedResponse = {
                groups: filteredTrackDropdownData[0].search_terms.map((group: any) => ({
                    name: group.term,
                    id: group.id,
                    amazon_tld: filteredTrackDropdownData[0].amazon_tld,
                })),
            }

            const defaultGroup = transformedResponse?.groups?.[0]
            setAllTrackpacks(transformedResponse as any)
            setDefaultTrackpack(defaultGroup?.id)
            setSelectedIndex(defaultGroup?.id)
        } else {
            setAllTrackpacks([])
            setDefaultTrackpack("")
            setSelectedIndex(undefined)
        }
    }, [selectedSearchpackWithTerm, userActionData])

    useEffect(() => {
        if (selectedIndex) {
            const options: { as_user?: string } = {}

            if (userActionData) {
                options.as_user = userActionData.id + ""
            }
            dispatch(SearchpackActionCreator.getSearchpackRankTracker(selectedIndex, {}, options))
        }
    }, [selectedIndex])

    useEffect(() => {
        dispatch(
            SearchpackActionCreator.setSelectedKeywordPayload({
                selectedSearchpackTermID: selectedIndex,
            })
        )
    }, [selectedIndex])

    const handleSelectElementChange = (selectedValue: string) => {
        const selectedGroup = allTrackpacks.groups.find((group: any) => group.id === selectedValue)

        if (selectedGroup) {
            setSelectedIndex(selectedGroup.id)
            dispatch(
                SearchpackActionCreator.setSelectedKeywordPayload({
                    selectedSearchpackTermID: selectedGroup.id,
                })
            )
        }
    }

    return (
        <div>
            {/* <SearchpackTrackerOnboarding /> */}

            <div className={"flex justify-between flex-wrap mb-[12px] w-full h-[36px]"}>
                <div className={"flex gap-x-[12px]  items-center h-[36px]"}>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[60px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Select Keyword" />
                        )}
                    >
                        <div className="w-[250px] relative">
                            <SelectElement
                                className="focus:outline-none pr-[8px] !h-[36px] !w-[99%] !border !border-gray-200 focus:outline-0 focus:shadow-none"
                                buttonTextClass="truncate whitespace-nowrap max-w-[220px] "
                                reactHookControl={control}
                                type="check"
                                name="trackpack"
                                disabled={isLoading}
                                defaultValue={defaultTrackpack}
                                rightCheckBox={true}
                                isToolitpShow={false}
                                options={
                                    allTrackpacks &&
                                    allTrackpacks?.groups?.map(
                                        (g: { id: string; name: string; amazon_tld: string }) => {
                                            return {
                                                value: g?.id,
                                                label: g?.name,
                                                amazon_tld: g?.amazon_tld,
                                            }
                                        }
                                    )
                                }
                                onChange={handleSelectElementChange}
                            />
                        </div>
                    </div>
                    <div
                        className="cursor-pointer outline-none"
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[38px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Add Keywords" />
                        )}
                    >
                        <ButtonElement
                            onClick={handleOpen}
                            viewType="only-icon"
                            className="p-[10px] bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                                        hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                                        active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                        >
                            <AddIcon strokeColor={"#088AB2"} strokWidth={"1.2"} />
                        </ButtonElement>
                    </div>
                    <div className=" h-[24px] flex items-center justify-center border-l border-gray-200" />

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[96px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Filter for Paid or Organic" />
                        )}
                        className={`cursor-pointer outline-none px-[16px] py-[8px] h-[36px] inline-flex items-center gap-[12px] bg-white border border-gray-200 rounded-[10px] ${
                            isDisabled || isGetRankTrackerDataLoading
                                ? "opacity-[0.4] cursor-not-allowed pointer-events-none"
                                : ""
                        } `}
                    >
                        <PrimaryText
                            onClick={() => handleFilterClick("Paid")}
                            className={`text-gray-700 leading-[18px] cursor-pointer mt-[2px] ${
                                disabledFilter === "Paid" ? "text-gray-200" : ""
                            }`}
                            size="small"
                            weight="light"
                        >
                            Paid
                        </PrimaryText>
                        <PrimaryText
                            onClick={() => handleFilterClick("Organic")}
                            className={`text-gray-700 leading-[18px] cursor-pointer mt-[2px] ${
                                disabledFilter === "Paid" ? "text-gray-200" : ""
                            }`}
                            size="small"
                            weight="light"
                        >
                            Organic
                        </PrimaryText>
                    </div>

                    <div
                        data-tooltip-id="dashboard-tooltip"
                        data-tooltip-place="bottom"
                        data-tooltip-offset={-10}
                        data-tooltip-class-name={"!ml-[92px]"}
                        data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                            <CommonTooltip className="" label="Set Time Period" />
                        )}
                    >
                        <ButtonGroups
                            onClickHandler={(option) => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                                return setSelectedRange(option.value as number)
                            }}
                            defaultValue={selectedRange}
                            options={graphConfig.productRangeUpdated}
                            tooltipLabel="Set Time Period"
                        />
                    </div>
                </div>
                <div className="inline-flex items-center bg-white border overflow-hidden border-gray-200 rounded-[10px]">
                    {/* Table Icon */}
                    <div
                        onClick={() => setActiveIcon("table")}
                        className={`p-[8px] border-r border-gray-200 ${
                            activeIcon === "table" ? "bg-active-buttons-bg" : "bg-white"
                        }`}
                    >
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={2}
                            data-tooltip-class-name={"!ml-[-20px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="View Table" />
                            )}
                        >
                            <TableIcon color={activeIcon === "table" ? "#0E7090" : "#667085"} />
                        </div>
                    </div>
                    {/* Graph Icon */}
                    <div
                        onClick={() => setActiveIcon("graph")}
                        className={`p-[8px] ${activeIcon === "graph" ? "bg-active-buttons-bg" : "bg-white"}`}
                    >
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            data-tooltip-place="bottom"
                            data-tooltip-offset={2}
                            data-tooltip-class-name={"!ml-[26px]"}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className="" label="View Graph" />
                            )}
                        >
                            <GraphIcon color={activeIcon === "graph" ? "#0E7090" : "#667085"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-1">
                {activeIcon === "table" && <RankTrackerTableView />}
                {activeIcon === "graph" && <RankTrackerGraphView infoVisible={true} selectedRange={30} />}
            </div>
        </div>
    )
}

export default RankTracker
