import { all, fork, put } from "redux-saga/effects"
import { call, takeLatest, takeLeading } from "typed-redux-saga"

import { OnBoardingUserActions, OnboardingUserActionCreators } from "../actions/onBoardingUser.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { OnBoardingServiceService } from "../services/onBoarding.service"

/////////////////////////// -- Create Onboarding Account -- ///////////////////////////
function* createOnBoardingUser() {
    yield takeLeading(OnBoardingUserActions.CREATE_ONBOARDING_USER, createOnBoardingUserHandler)
    // yield takeLatest(OnBoardingUserActions.VERIFY_ALPHA_EMAIL, alphaEmailVerificationHandler)
}

function* createOnBoardingUserHandler<T extends ISagaAction & { payload: IOnBoardUser }>(action: T) {
    try {
        // Dispatch a start loading action
        yield put(RuntimeActionCreators.startLoading("createOnBoardingUserHandler", !action.ignorePreloader))
        // Dispatch api call
        const result = yield* call(OnBoardingServiceService.postOnBoardingAccount, action.payload)
        if (result) {
            yield put(OnboardingUserActionCreators.createOnboardingUser(action.payload))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        console.error(error)
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("createOnBoardingUserHandler"))
        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Create Onboarding Account -- ///////////////////////////

/////////////////////////// -- Alpha email verify -- ///////////////////////////

function* alphaEmailVerificationSaga() {
    yield takeLatest(OnBoardingUserActions.VERIFY_ALPHA_EMAIL, alphaEmailVerificationHandler)
}
function* alphaEmailVerificationHandler<T extends ISagaAction & { payload: { email: string } }>(action: T) {
    try {
        // Dispatch a start loading act ion
        yield put(RuntimeActionCreators.startLoading("alphaEmailVerificationHandler", !action.ignorePreloader))

        const result = yield* call(OnBoardingServiceService.verifyAlphaEmail, {
            email: action.payload.email,
        })
        if (result.status === 204) {
            yield put(OnboardingUserActionCreators.createOnboardingUser(action.payload))
            action.onSuccess && action.onSuccess(result)
        } else {
            yield* handleErrorResponse(result, action)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("alphaEmailVerificationHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End Alpha email verify  -- ///////////////////////////

/////////////////////////// -- Alpha user register -- ///////////////////////////

function* alphaUserRegisterSaga() {
    yield takeLatest(OnBoardingUserActions.ALPHA_USER_REGISTER, alphaUserRegisterHandler)
}
function* alphaUserRegisterHandler<T extends ISagaAction & { payload: AlphaUser }>(action: T) {
    try {
        // Dispatch a start loading act ion
        yield put(RuntimeActionCreators.startLoading("alphaUserRegisterHandler", !action.ignorePreloader))
        yield put(
            OnboardingUserActionCreators.createOnboardingUser({
                searchedCompitator: {
                    competitorAsin: action.payload.competitor_asin_list,
                },
            })
        )

        const result = yield* call(OnBoardingServiceService.alphaUserRegister, action.payload)

        if (result.status === 201) {
            // const defaultObject = UtilHelper.createDefaultObject(IOnBoardUserKeys)
            // yield put(OnboardingUserActionCreators.createOnboardingUser(defaultObject))

            action.onSuccess && action.onSuccess(result)
        } else {
            yield* handleErrorResponse(result, action)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("alphaUserRegisterHandler"))

        action.onFinally && action.onFinally()
    }
}

/////////////////////////// -- End user register  -- ///////////////////////////

/////////////////////////// -- Handle exceptions start  -- ///////////////////////////
export function* handleErrorResponse(result: Response, action: ISagaAction) {
    const response = yield* call(() => result.json())
    if (result.status === 422) {
        const errorResponse = response as AlphaUserRegisterErrorResponse
        action.onError && action.onError({ message: errorResponse.detail[0].msg } as { message: string })
    } else if (result.status === 409) {
        action.onError && action.onError({ message: response.message, code: result.status } as { message: string })
    } else {
        action.onError && action.onError({ message: "An unexpected error occurred. Please try again later" })
    }
}
/////////////////////////// -- Handle exceptions end  -- ///////////////////////////

export default function* rootSaga() {
    yield all([fork(createOnBoardingUser), fork(alphaEmailVerificationSaga), fork(alphaUserRegisterSaga)])
}
