import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { NotificationIcon } from "../../../../assets/svgs/notificationIcon.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"

const FlowSelectionMain = () => {
    const dispatch = useDispatch()
    const { addTrackPackFlow } = useSelector(RunTimeStateSelector)

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeTrackPackFlowModal())
    }

    const handleManualFlow = () => {
        dispatch(
            RuntimeActionCreators.openNewTrackPackAsinModal({
                open: true,
                stepNumber: "one",
            })
        )
        handleCloseDialog()
    }

    const handleSemiAutomatedFlow = () => {
        dispatch(
            RuntimeActionCreators.openSemiAutomatedTrackpackModal({
                open: true,
                stepNumber: "one",
            })
        )
        handleCloseDialog()
    }

    return (
        <Transition appear show={addTrackPackFlow?.open ?? false} as={Fragment}>
            <Dialog as="div" className="relative !z-50" onClose={handleCloseDialog}>
                <>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className="w-[100vw] h-[100vh] absolute inset-0"
                            style={{ position: "fixed", backdropFilter: "blur(12px)" }}
                        >
                            <div
                                className="w-[100vw] h-[100vh] absolute inset-0 "
                                style={{
                                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, #101828 100%)",
                                }}
                            />
                        </div>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="min-h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className={`p-[48px] pb-[36px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] w-[600px] shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
                                >
                                    <>
                                        <div className="flex items-center justify-between">
                                            <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">
                                                <NotificationIcon />
                                            </div>
                                            <div className="cursor-pointer">
                                                <CrossNewIcon onClick={handleCloseDialog} />
                                            </div>
                                        </div>
                                        <PrimaryText
                                            weight="medium"
                                            className="text-[24px] text-gray-700 mt-[24px] mb-[4px] leading-[32px] ml-[6px]"
                                        >
                                            Add Trackpacks
                                        </PrimaryText>
                                        <PrimaryText
                                            size="sm-medium"
                                            weight="light"
                                            className="text-gray-700  ml-[6px]"
                                        >
                                            Do you want to build this Trackpack yourself or would you like me
                                            <br /> to help you find your most relevant competitors?
                                        </PrimaryText>

                                        <div className="flex gap-[12px] items-center justify-center mt-[48px]">
                                            <ButtonElement
                                                onClick={handleManualFlow}
                                                size="large"
                                                viewType="hover-state"
                                                className={
                                                    " bg-[#ECFDFF] hover:bg-[#ECFDFF] h-[44px] rounded-[12px] px-[30px] py-[8px]  hover:text-[#06AED4]  border border-[#67E3F9] font-[300] flex items-center justify-center"
                                                }
                                                textClass="font-[300] text-[18px] leading-[28px] text-[#06AED4]"
                                            >
                                                Build it myself
                                            </ButtonElement>

                                            <ButtonElement
                                                onClick={handleSemiAutomatedFlow}
                                                size="large"
                                                viewType="stay-same"
                                                textClass="font-[500] text-[18px] leading-[28px] text-[#0E7090]"
                                                className={
                                                    "px-[30px] py-[8px] border border-transparent h-[44px] bg-[#A5F0FC] rounded-[12px] flex items-center justify-center "
                                                }
                                            >
                                                Find me competitors
                                            </ButtonElement>
                                        </div>
                                    </>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </>
            </Dialog>
        </Transition>
    )
}

export default FlowSelectionMain
