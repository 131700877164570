export const KeywordPerformanceIcon = (props: IPropsIcon) => {
    return (
        <>
            <svg
                width={props.width ?? 32}
                height={props.height ?? 32}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16 21.7778V29M16 21.7778L23 29M16 21.7778L9 29M30 3H2M20.9 17.8056L18.8 15.6389M3.4 3H28.6V14.8444C28.6 17.2713 28.6 18.4848 28.1422 19.4117C27.7396 20.2271 27.097 20.89 26.3068 21.3055C25.4083 21.7778 24.2322 21.7778 21.88 21.7778H10.12C7.76778 21.7778 6.59167 21.7778 5.69324 21.3055C4.90296 20.89 4.26044 20.2271 3.85777 19.4117C3.4 18.4848 3.4 17.2713 3.4 14.8444V3ZM20.2 12.0278C20.2 14.8199 18.0062 17.0833 15.3 17.0833C12.5938 17.0833 10.4 14.8199 10.4 12.0278C10.4 9.23567 12.5938 6.97222 15.3 6.97222C18.0062 6.97222 20.2 9.23567 20.2 12.0278Z"
                    stroke={props.color ?? "#088AB2"}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
