import { useSelector } from "react-redux"
import { SearchpackStateSelector } from "../../../selectors/searchpack.selector"
import SearchpackSkeletonLoader from "../../common/skeletonLoader/searchpackSkeletonLoader.component"
import { SearchpackSelection } from "./searchpackSelection.component"
import SearchpackSelectionTracker from "./searchpackSelectionTracker.component"

export const SearchpackTracker = () => {
    const { searchpackDropdownList } = useSelector(SearchpackStateSelector)

    return (
        <>
            <div
                className={"flex gap-[20px] items-start w-full "}
                // style={{ width: "calc(100% - 60px)" }}
            >
                <SearchpackSelection />
                {searchpackDropdownList?.searchpacks && searchpackDropdownList?.searchpacks?.length > 0 ? (
                    <SearchpackSelectionTracker />
                ) : (
                    <SearchpackSkeletonLoader />
                )}
            </div>
        </>
    )
}
