export const GraphIconSidebar = (props: IArrowProps) => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.5 17.5H3.83333C3.36662 17.5 3.13327 17.5 2.95501 17.4092C2.79821 17.3293 2.67072 17.2018 2.59083 17.045C2.5 16.8667 2.5 16.6334 2.5 16.1667V2.5M16.6667 6.66667L13.4009 10.1522C13.2772 10.2843 13.2153 10.3504 13.1406 10.3845C13.0747 10.4146 13.0021 10.4271 12.93 10.4206C12.8482 10.4132 12.7679 10.3715 12.6072 10.2881L9.89277 8.8786C9.73212 8.79519 9.65179 8.75348 9.57003 8.74611C9.49787 8.73961 9.42526 8.75204 9.35936 8.78217C9.28471 8.81631 9.22282 8.88236 9.09906 9.01445L5.83333 12.5"
                    stroke={props.color}
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}
