import { useDispatch } from "react-redux"

import { RuntimeActionCreators } from "../../actions/runTime.action"

import { DescriptionIcon } from "../../assets/svgs/descriptionIcon.svg"
import { LargeBulletIcon } from "../../assets/svgs/largeBulletIcon.svg"
import { ProductVideosIcon } from "../../assets/svgs/productVideoIcon.svg"
import { RectangleIcon } from "../../assets/svgs/rectangleIcon.svg"
import { CarouselIconTooltip } from "../../assets/svgs/tooltips/popups/carousel.svg"
import { VideoIcons } from "../../assets/svgs/videoIcons.svg"
import { CrossNewIcon } from "../../assets/svgs/xIcon.svg"

const InnerModalHeader = (props: IHeaderIconName) => {
    const dispatch = useDispatch()

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeModal())
    }

    const getHeaderIcon = () => {
        switch (props.type) {
            case "bullets":
                return <LargeBulletIcon color={props.color?.default} />
            case "main_video":
                return <VideoIcons color={props.color?.default} />
            case "main_image":
                return <RectangleIcon color={props.color?.default} />
            case "carousel":
                return <CarouselIconTooltip color={props.color?.default} stroke={props.color?.default} />
            case "description":
                return <DescriptionIcon color={props.color?.default} />
            case "video":
                return <ProductVideosIcon color={props.color?.default} />
            default:
                return null
        }
    }

    return (
        <>
            <div className="flex justify-between w-full mb-[24px]">
                <div
                    className={`bg-[${props.color?.active}] w-[48px] h-[48px] rounded-[12px] flex items-center justify-center `}
                    style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
                >
                    {getHeaderIcon()}
                </div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
        </>
    )
}

export default InnerModalHeader
