import { SearchpackWrapperModal } from "./addSearchpackWrapperModal.component"
import SearchpackFlowOutlet from "./searchpackFlowOutlet.component"

const AddSearchpackMainModal = () => {
    return (
        <SearchpackWrapperModal
            // style={{
            //     maxHeight: "calc(100% - 24px)",
            //     overflowY: "auto",
            // }}
            className={`p-[48px] pb-[36px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px]
            shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all max-w-[600px] w-full`}
        >
            <SearchpackFlowOutlet />
        </SearchpackWrapperModal>
    )
}

export default AddSearchpackMainModal
