import { Dialog, Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"

export const ArchiveRestoreSearchpackModalWrapper = (props: { children?: ReactNode; className?: string }) => {
    const dispatch = useDispatch()
    const { archiveRestoreSearchpackModal } = useSelector(RunTimeStateSelector)
    const { children, className } = props
    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeArchiveRestoreSearchpackModal())
    }

    return (
        <Transition appear show={archiveRestoreSearchpackModal?.open ?? false} as={Fragment}>
            <Dialog as="div" className="relative !z-50" onClose={handleCloseDialog}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="w-[100vw] h-[100vh] absolute inset-0"
                        style={{ position: "fixed", backdropFilter: "blur(12px)" }}
                    >
                        <div
                            className="w-[100vw] h-[100vh] absolute inset-0 "
                            style={{
                                background: "linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, #101828 100%)",
                            }}
                        />
                    </div>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="min-h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={className}>{children}</Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
