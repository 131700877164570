import { useSelector } from "react-redux"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { AuthStateSelector } from "../../../../selectors/authState.selector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
const AddAsinToTrackpackStepTwoView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    icon,
    handleCloseDialog,
    handlePrev,
    buttonText,
    trackpackName,
}) => {
    const { getAccessLevel } = useSelector(AuthStateSelector)

    const ownerName =
        getAccessLevel?.first_name && getAccessLevel?.last_name
            ? getAccessLevel?.first_name
            : getAccessLevel && getAccessLevel?.username

    return (
        <>
            <div className="flex items-center justify-between">
                {icon}
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-[#344054] text-[24px] font-medium mt-[24px] ml-[6px] capitalize">
                {asinHeaderText} {ownerName}!
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] text-[#667085] mx-[6px] ">
                You’ve just added the selected ASIN to your Trackpack called&nbsp;
                <PrimaryText className="text-[16px] font-[500] inline  text-[#667085] ">{trackpackName}.</PrimaryText>
                <PrimaryText className="text-[16px] font-[300] text-[#667085] inline">
                    I’m gathering data and tracking has begin.
                </PrimaryText>
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] text-[#667085] ml-[6px] mt-[15px] ">
                I’ll alert you as soon as it’s ready to view.
            </PrimaryText>

            <div className="flex justify-between  items-center mt-[48px]">
                <div className="flex items-center"></div>

                <ButtonElement
                    type="submit"
                    size="large"
                    onClick={handleCloseDialog}
                    textClass="text-[18px] leading-[28px]"
                    className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                >
                    {buttonText}
                </ButtonElement>
            </div>
        </>
    )
}
export default AddAsinToTrackpackStepTwoView
