import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useSelector } from "react-redux"
import { LinkExternalSvg } from "../../../assets/svgs/link-external.svg"
import { handleAmazonProductRedirect } from "../../../helpers/util.helper"
import { AuthStateSelector } from "../../../selectors/authState.selector"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { PrimaryText } from "../../elements/primaryText.element"

const CardSkeletonLoader = ({
    length,
    isAsinNumber,
    trackingAsin,
    isTrackpack,
    ownProduct,
    sortedTrackingsStatuses,
    isStatic,
}: CardSkeletonLoaderProps) => {
    const skeletonBaseColor = "#D0D5DD"
    const skeletonHighlightColor = "#F0F0F0"
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const selectedObject = selectedDropdownList?.trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == selectedTrackpackID
    )

    const ownerInitials = getAccessLevel?.first_name
        ? getAccessLevel?.first_name?.charAt(0)?.toUpperCase()
        : getAccessLevel && getAccessLevel?.username?.charAt(0).toUpperCase()

    const handleProductRedirect = (asinNo: string | undefined) => {
        handleAmazonProductRedirect(selectedObject?.metadata?.amazon_tld, asinNo!)
    }
    return (
        <>
            <></>
            {isTrackpack &&
                length &&
                Array.from({ length })?.map((_, index) => (
                    <div
                        key={index}
                        className="flex gap-2 p-[10px] pr-[8px] bg-[#EAECF0] rounded-[10px] w-[278px] mb-[8px] custom-span"
                    >
                        {/* <div className="w-[68px] h-[68px] flex items-center relative">
                            <Skeleton
                                baseColor="#D0D5DD"
                                count={1}
                                width={68}
                                height={68}
                                style={{ position: "absolute", top: "0" }}
                            />
                        </div> */}
                        <div className="h-68px w-full">
                            <div className="flex justify-between w-full h-[16px]  custom-span">
                                <Skeleton baseColor="#D0D5DD" count={1} width={96} height={12} />
                                <Skeleton baseColor="#D0D5DD" count={1} width={60} height={16} borderRadius={16} />
                            </div>
                            <div className="custom-span h-[8px]">
                                <Skeleton baseColor="#D0D5DD" count={1} width={128} height={8} className="" />
                            </div>
                            <div className="flex items-center justify-between w-full mt-[10px]">
                                <div className="custom-span inline-flex gap-x-[4px]">
                                    <Skeleton baseColor="#D0D5DD" count={1} width={30} height={30} borderRadius={6} />
                                    <Skeleton baseColor="#D0D5DD" count={1} width={30} height={30} borderRadius={6} />
                                    <Skeleton baseColor="#D0D5DD" count={1} width={30} height={30} borderRadius={6} />
                                    <Skeleton baseColor="#D0D5DD" count={1} width={30} height={30} borderRadius={6} />
                                    <Skeleton baseColor="#D0D5DD" count={1} width={30} height={30} borderRadius={6} />
                                </div>
                                <Skeleton baseColor="#D0D5DD" count={1} width={30} height={30} borderRadius={"75%"} />
                            </div>
                        </div>
                    </div>
                ))}
            {!isTrackpack &&
                !trackingAsin &&
                length &&
                Array.from({ length })?.map((_, index) => (
                    <div
                        key={index}
                        className="flex gap-2 p-[8px]  bg-[#EAECF0] rounded-[10px] w-[278px] last:mb-[25px]"
                    >
                        <div className="flex items-center w-[68px] h-[68px] relative">
                            <Skeleton
                                highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                baseColor={skeletonBaseColor}
                                count={1}
                                width={68}
                                height={68}
                                style={{ position: "absolute", top: "0" }}
                            />
                        </div>

                        <div>
                            <div className="flex justify-between items-center w-full">
                                <div className="flex gap-1 items-center h-[16px]">
                                    <Skeleton
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor={skeletonBaseColor}
                                        count={1}
                                        width={100}
                                        height={16}
                                    />

                                    <div className="flex justify-center items-center w-[16px] rounded-[4px] bg-modal-btn-gradient h-[16px]">
                                        <Skeleton
                                            highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                            baseColor={skeletonBaseColor}
                                            count={1}
                                            width={16}
                                            height={16}
                                            borderRadius={"4px"}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-1 items-center">
                                    <Skeleton
                                        highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                        baseColor={skeletonBaseColor}
                                        count={1}
                                        width={16}
                                        height={16}
                                        borderRadius={"75%"}
                                    />
                                </div>
                            </div>
                            <div className="mt-2 flex items-center mb-[6px] h-[12px]">
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    width={187}
                                    height={12}
                                />
                            </div>
                            <div className="flex items-center gap-2 h-[12px]">
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    width={40}
                                    height={12}
                                />
                                <Skeleton
                                    highlightColor={isStatic ? skeletonBaseColor : skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    width={140}
                                    height={12}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            {!isTrackpack && trackingAsin && (
                <div className="flex gap-2 p-[8px]  bg-[#EAECF0] rounded-[10px] w-[278px]">
                    <div className="flex items-center w-[68px] h-[68px] relative">
                        <Skeleton
                            highlightColor={skeletonHighlightColor}
                            baseColor={skeletonBaseColor}
                            count={1}
                            width={68}
                            height={68}
                            style={{ position: "absolute", top: "0" }}
                        />
                    </div>

                    <div>
                        <div className="flex justify-between items-center w-full">
                            <div className="flex gap-1 items-center h-[16px]">
                                {/* <div
                                   className={`bg-white cursor-pointer h-[16px] py-[2.5px] rounded-[3px] w-auto pl-[4px] pr-[3.5px]  flex gap-[3px] justify-center items-center`}
                               >
                                   <p className={`text-black text-[10px] `}>{trackingAsin}</p>
                                   <div className="cursor-pointer">
                                       <LinkExternalSvg color="#000" />
                                   </div>
                               </div> */}
                                <div
                                    // onClick={() => handleProductRedirect(row?.ASINs?.asinNo, row?.amazon_tld)}
                                    onClick={() => handleProductRedirect(trackingAsin)}
                                    className="mt-[4px] cursor-pointer px-[4px] py-[1.25px] flex  gap-[3px] items-center rounded-[3px] bg-transparent border border-gray-400 w-fit"
                                >
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className=" text-gray-400 tracking-[0.7px] leading-[11px]"
                                    >
                                        {trackingAsin}
                                    </PrimaryText>
                                    <LinkExternalSvg color={"#98A2B3"} className="mb-[0.5px]" />
                                </div>
                                {/* // <Skeleton
                                   highlightColor={skeletonHighlightColor}
                                   baseColor={skeletonBaseColor}
                                   count={1}
                                   width={85}
                                   height={16}
                               /> */}

                                {ownProduct ? (
                                    <div className="flex justify-center items-center w-[16px] rounded-[4px] bg-modal-btn-gradient h-[16px] mt-[4px]">
                                        <PrimaryText
                                            weight="book"
                                            size="xs-small"
                                            className=" text-[#FFF] leading-[15px] uppercase"
                                        >
                                            {ownerInitials}
                                        </PrimaryText>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="flex gap-1 items-center">
                                <Skeleton
                                    highlightColor={skeletonHighlightColor}
                                    baseColor={skeletonBaseColor}
                                    count={1}
                                    width={16}
                                    height={16}
                                    borderRadius={"75%"}
                                />
                            </div>
                        </div>
                        <div className="mt-2 flex items-center mb-[6px] h-[12px]">
                            <Skeleton
                                highlightColor={skeletonHighlightColor}
                                baseColor={skeletonBaseColor}
                                count={1}
                                width={187}
                                height={12}
                            />
                        </div>
                        <div className="flex items-center gap-2 h-[12px]">
                            <Skeleton
                                highlightColor={skeletonHighlightColor}
                                baseColor={skeletonBaseColor}
                                count={1}
                                width={40}
                                height={12}
                            />
                            <Skeleton
                                highlightColor={skeletonHighlightColor}
                                baseColor={skeletonBaseColor}
                                count={1}
                                width={140}
                                height={12}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CardSkeletonLoader
