import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ReactComponent as InputIcon } from "../../assets/svgs/input-icon.svg"
import { ReactComponent as PasswordIcon } from "../../assets/svgs/password-icon.svg"
import { ReactComponent as FrontBubble } from "../../assets/svgs/small-front-bubble.svg"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"

import AmaizingIcon from "../../assets/gifs/amaizing-icon.gif"

export const SignUp = () => {
    const validationConfig = useInputValidations()

    const [completed, setCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation("input")
    const { control, handleSubmit, watch } = useForm<IInputsExampleProps>()
    const onSubmit: SubmitHandler<IInputsExampleProps> = (data) => {
        setIsLoading(true)
        console.info(data)
        setTimeout(() => {
            setIsLoading(false)
            console.info(watch("example"))
        }, 2000)
    }

    return (
        <>
            <div className="flex justify-center gap-[12px] mt-[96px]">
                <img src={AmaizingIcon} alt="Amaizing Icon" className="h-[26px] w-[28px]" />
                <div className="flex flex-col justify-start w-[480px] items-start">
                    <div className={"flex-1 mb-[-12px]"}>
                        <BubblesList
                            interval={1000}
                            onFinish={() => {
                                setCompleted(true)
                            }}
                            conversationItems={[
                                {
                                    text: "<p class='leading-[24px]'>Hey, nice to meet you</p><br/><p class='leading-[24px]'>I'm <span class='text-gray-700 font-bold text-lg'>Am<span class='text-cyan-600 underline'>ai</span>zing,</span> your personal AI Amazon Consultant.</p><br/>Before I start you on your 30-day free trial, I just need a little info to set up your membership.",
                                },
                            ]}
                        />
                    </div>
                    {completed && (
                        <div className="flex-1 w-[400px] mt-[32px]">
                            <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                <Container className="comeInAnimation fadeInBottom">
                                    <InputElement
                                        name="name"
                                        type="text"
                                        defaultValue=""
                                        reactHookControl={control}
                                        reactHookValidations={{
                                            required: validationConfig.required,
                                        }}
                                        placeholder="Name"
                                        postfix={<InputIcon />}
                                        className={"mb-[16px]"}
                                    />
                                    <InputElement
                                        name="name"
                                        type="email"
                                        defaultValue=""
                                        reactHookControl={control}
                                        reactHookValidations={{
                                            required: validationConfig.required,
                                        }}
                                        placeholder="Email"
                                        postfix={<InputIcon />}
                                        className={"mb-[16px]"}
                                    />
                                    <InputElement
                                        name="password"
                                        type="password"
                                        defaultValue=""
                                        reactHookControl={control}
                                        reactHookValidations={{
                                            required: validationConfig.required,
                                        }}
                                        hint={t("password.hint")}
                                        placeholder="Password"
                                        postfix={
                                            <div>
                                                <PasswordIcon />
                                            </div>
                                        }
                                        className={"mb-[16px]"}
                                    />
                                    <InputElement
                                        name="password"
                                        type="password"
                                        defaultValue=""
                                        reactHookControl={control}
                                        reactHookValidations={{
                                            required: validationConfig.required,
                                        }}
                                        placeholder="Verify Password"
                                        postfix={
                                            <div>
                                                <PasswordIcon />
                                            </div>
                                        }
                                        className={"mb-[16px]"}
                                    />
                                    {completed && (
                                        <ButtonElement
                                            type="submit"
                                            loading={isLoading}
                                            size="medium"
                                            className={"mb-[24px]"}
                                        >
                                            Confirm
                                        </ButtonElement>
                                    )}
                                </Container>
                            </form>
                        </div>
                    )}
                </div>
            </div>
            {completed && (
                <div className="flex justify-center gap-[12px] mt-[32px]">
                    <FrontBubble className="h-[26px] w-[28px]" />
                    <div className="flex justify-start w-[520px] h-[108px] items-start">
                        <BubblesList
                            interval={0}
                            onFinish={() => {
                                setCompleted(true)
                            }}
                            conversationItems={[
                                {
                                    text: "<p>That was fast. Thanks Anders.</p><br/><p class='leading-[24px]'>Now please verify your membership by clicking the link in the email I’ve just sent you.</P></p>",
                                },
                            ]}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
